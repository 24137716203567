.blog {
  &__image {
    display: block;
    position: relative;
    padding-bottom: 57.1428%;
    overflow: hidden;
    margin-bottom: .3125rem;

    img {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @supports (object-fit: cover) {
      img {
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &-list{
    margin-bottom: -$grid-gutter-width;

    &__item {
      margin-bottom: $grid-gutter-width;
    }

    &__link {
      color: $body-color;

      &:hover {
        text-decoration: none;
        color: $submenu-link-hover-color;
      }
    }

    &__title {
      display: block;
      font-weight: 700;
      line-height: 1.4;
      overflow: hidden;
      max-height: calc(4.2em + .5rem);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin: -.25rem;
      padding: .25rem;

      @include transition(color .15s ease-in-out);

      @include media-breakpoint-down(xs) {
        font-size: 1.125rem;
      }

      @include media-breakpoint-only(sm) {
        height: calc(2.8em + .5rem);
        -webkit-line-clamp: 2;
      }

      @include media-breakpoint-up(md) {
        height: calc(4.2em + .5rem);
      }
    }

    &__image:hover ~ &__title,
    &__image:hover ~ &__title &__link {
      color: $submenu-link-hover-color;
    }
  }

  &-article {
    @include clearfix;

    &__image {
      @include property-by-media(width, (lg: 450px, xl: 540px));

      @include media-breakpoint-up(lg) {
        margin-right: $grid-gutter-width;
      }
    }
  }
}
