@function strip-unit($value) {
  @if type-of($value) == 'number' and not unitless($value) {
    @return $value / ($value * 0 + 1);
  }

  @return $value;
}

@function fluid-value($min-value, $max-value, $min-viewport, $max-viewport) {
  @if $min-value == $max-value {
    @return $min-value;
  }

  @return calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-viewport}) / #{strip-unit($max-viewport - $min-viewport)}));
}
