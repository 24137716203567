%slick-button {
  @include clear-button;

  display: block;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

.slick-carousel:not(.slick-slider) {
  display: none;
}

.slick-slider_chevrons {
  .slick-prev, .slick-next {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    @include transition(color .3s ease-in-out, background-color .3s ease-in-out);

    &:hover {
      color: #fff;
      background-color: #555;
    }
  }
}

.slick-slider {
  .slick-slide {
    img {
     /* width: 0 !important; */
      height: 0 !important;
      display: none !important;
    }
  }

  .slick-current, .slick-active {
    img {
     /* width: 100% !important;  */
      height: auto !important;
      display: block !important;
    }
  }

  .slick-prev, .slick-next {
    @extend %slick-button;
    @extend .icon;

    &::before {
      line-height: 1;
      font-size: 1.1875rem;
      vertical-align: middle;
    }
  }

  .slick-prev {
    @extend .icon-angle-left;
    padding-right: 3px;
  }
  .slick-next {
    @extend .icon-angle-right;
    padding-left: 3px;
  }

  &--bottom-arrows {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .slick-list {
      width: 100%;
      order: -1;
    }

    .slick-next {
      order: 10
    }
  }
}

.slick-dots {
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  @include property-by-media(padding, (default: .5rem 20px, lg: 10px 20px));

  > li {
    margin: 0 2.5px;
  }

  button {
    @extend %slick-button;

    border-style: solid;
    border-color: transparent;
    border-width: 10px 0;

    &::before {
      content: '';
      display: block;
      background-color: #c4c4c4;
      height: 3px;

      @include transition(background-color .3s ease-in-out);
      @include property-by-media(width, (default: 1.25rem, sm: 1.875rem));
      @include property-by-media(margin, (default: 0 .3125rem, lg: 0 .5rem));
    }
  }

  .slick-active button::before {
    background-color: $black;
  }
}
