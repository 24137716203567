.lot {
  &__sale-info {
    @include property-by-media(font-size, (default: .9375rem, sm: 1rem, md: 1.125rem));

    strong {
      font-weight: 500;
    }
  }

  &-carousel {
    user-select: none;

    margin: {
      left: (-$grid-gutter-width/2);
      right: (-$grid-gutter-width/2);
    }

    @include media-breakpoint-up(lg) {
      margin: {
        left: 0;
        right: 0;
      }
    }

    &--calc {
      @include media-breakpoint-down(xs) {
        background-color: $gray-100;
        padding: 1.25rem 0 .5rem;
      }
    }

    &__item {
      vertical-align: top;

      padding: {
        left: $grid-gutter-width/2;
        right: $grid-gutter-width/2;
      }

      @include media-breakpoint-up(lg) {
        padding: {
          left: 0;
          right: 0;
        }
      }

      img {
        width: 100%;
        height: auto;
        display: block;

        @include media-breakpoint-up(sm) {
          border-radius: $border-radius;
        }
      }
    }

    &__thumbs {
      display: flex;
      flex-wrap: wrap;
      margin: .3125rem -.3125rem;
      align-items: center;
    }

    &__thumb {
      user-select: none;
      position: relative;
      width: 20%;
      padding: .3125rem;

      img {
        width: 100%;
        height: auto;
        display: block;
        border-radius: .125rem;
      }

      &-link {
        border: none;
        background: transparent;
        display: block;
        width: 100%;
        cursor: pointer;
        padding: 0;
      }

      &.active &-link {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: .0625rem solid $submenu-link-hover-color;
          border-radius: .1875rem;
        }
      }
    }

    .slick-prev, .slick-next {
      @extend .icon;

      position: absolute;
      height: 2.5rem;
      width: 1.875rem;
      line-height: 0;
      font-size: 0;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      border: none;
      background: rgba(0, 0, 0, .6);
      z-index: 1;

      color: $gray-500;
      @include transition(color .15s ease-in-out);

      &:hover, &:focus {
        color: $white;
      }

      &::before {
        font-size: 1.1875rem;
        vertical-align: middle;
      }

    }
    .slick-prev {
      @extend .icon-angle-left;

      border-radius: 0 .1875rem .1875rem 0;

      @include property-by-media(left, (default: $grid-gutter-width/2, lg: 0));

      &::before {
        margin-left: -.125em;
      }
    }
    .slick-next {
      @extend .icon-angle-right;

      border-radius: .1875rem 0 0 .1875rem;

      @include property-by-media(right, (default: $grid-gutter-width/2, lg: 0));

      &::before {
        margin-right: -.125em;
      }
    }
  }

  &__current-bet {
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid $submenu-link-hover-color;
    line-height: 1.6;

    span {
      font-weight: 700;
    }
  }

  &-table {
    background-color: $gray-100;

    padding: 1.25rem $grid-gutter-width/2;

    margin: {
      left: (-$grid-gutter-width/2);
      right: (-$grid-gutter-width/2);
    }

    @include media-breakpoint-up(sm) {
      border: .0625rem solid #d8d8d8;
      border-radius: $border-radius;

      margin: {
        left: 0;
        right: 0;
      }

      padding: {
        left: 1.25rem;
        right: 1.25rem;
      }
    }

    @include media-breakpoint-up(md) {
      padding: {
        left: 1.875rem;
        right: 1.875rem;
      }
    }

    &__row {
      line-height: 1.8;
      padding: .25rem 0;

      @include property-by-media(font-size, (default: .75rem, sm: 1rem, md: 1.125rem, lg: 1rem, xl: 1.125rem));
      @include property-by-media(line-height, (default: 1.7, sm: 1.6, xl: 1.55));

      &:not(:last-child) {
        border-bottom: .0625rem solid #d8d8d8;
      }
    }
  }
}
