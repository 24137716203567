.wholesale-profit {
  &__title {
    @include property-by-media(margin-bottom, (default: 2.25rem, sm: 3rem));
  }

  &__list {
    counter-reset: wholesale-profit;

    &-title {
      @include property-by-media(font-size, (default: 1.3125rem, sm: 1.75rem, md: 2rem));
    }

    &-item {
      counter-increment: wholesale-profit;
      position: relative;

      @include property-by-media(padding, (default: 2.25rem 0 3rem, md: 2.5rem 0 3.25rem, lg: 3.125rem 0, xl: 4rem 0));

      &:not(:last-of-type) {
        @include media-breakpoint-up(lg) {
          margin-bottom: 6.125rem;
        }
        @include media-breakpoint-up(xl) {
          margin-bottom: 8.75rem;
        }
      }

      &::before {
        content: '';
        background-color: $gray-100;
        position: absolute;

        top: 0;
        left: 0;
        right: 0;
        bottom: 54vw;
        z-index: -1;

        @include media-breakpoint-up(sm) {
          bottom: 44vw;
        }

        @include media-breakpoint-up(md) {
          bottom: 40vw;
        }

        @include media-breakpoint-up(lg) {
          bottom: 0;
          left: auto;
          width: 100vw;
        }
      }

      $container--inner-width-lg: 960px;
      $container--inner-width-xl: 1140px;

      &:nth-child(odd)::before {
        @include media-breakpoint-up(lg) {
          right: percentage(415px / $container--inner-width-lg);
        }

        @include media-breakpoint-up(xl) {
          right: percentage(585px / $container--inner-width-xl);
        }
      }

      &:nth-child(even)::before {
        @include media-breakpoint-up(lg) {
          left: percentage(415px / $container--inner-width-lg);
        }

        @include media-breakpoint-up(xl) {
          left: percentage(585px / $container--inner-width-xl);
        }
      }

      &--image-right::before {
        @include media-breakpoint-up(lg) {
          right: auto;
          left: 50%;
        }
      }

      &::after {
        content: counter(wholesale-profit);
        position: absolute;
        top: 0;
        left: 50%;
        font-family: $font-family-montserrat;
        font-weight: 700;
        color: $white;
        background-color: $submenu-link-hover-color;
        border-radius: 50%;
        text-align: center;
        transform: translate(-50%, -50%);

        @include property-by-media(font-size, (default: 1.125rem, md: 1.5625rem, lg: 1.125rem, xl: 1.5625rem));
        @include property-by-media(width, (default: 2.1875rem, md: 2.5rem, lg: 2.1875rem, xl: 2.5rem));
        @include property-by-media(height, (default: 2.1875rem, md: 2.5rem, lg: 2.1875rem, xl: 2.5rem));
        @include property-by-media(line-height, (default: 2.1875rem, md: 2.5rem, lg: 2.1875rem, xl: 2.5rem));
      }

      &:nth-child(odd)::after {
        @include media-breakpoint-up(lg) {
          left: percentage(545px / $container--inner-width-lg);
        }

        @include media-breakpoint-up(xl) {
          left: percentage(555px / $container--inner-width-xl);
        }
      }

      &:nth-child(even)::after {
        @include media-breakpoint-up(lg) {
          left: percentage(($container--inner-width-lg - 545px) / $container--inner-width-lg);
        }

        @include media-breakpoint-up(xl) {
          left: percentage(($container--inner-width-xl - 555px) / $container--inner-width-xl);
        }
      }

      &-cell {
        @include property-by-media(margin-bottom, (default: 1.25rem, sm: 1.875rem, lg: 0));
      }
    }

    &-image {
      img {
        filter: drop-shadow(0 .4375rem 1.25rem rgba(0, 0, 0, .15));
      }

      &--pad {
        @include media-breakpoint-up(xl) {
          margin-left: 4.125rem;
        }
      }
    }
    &-item--image-right &-image {
      &--pad {
        @include media-breakpoint-up(xl) {
          margin-left: 0;
          margin-right: 4.125rem;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .wholesale-images {
      &__1 {
        right: auto;
        left: 0;
      }
      &__2 {
        right: 0;
        left: auto;
      }
    }
  }
}

$wholesale-profit__list-image--height: 280px;
$wholesale-profit__list-image--height-sm: 440px;
$wholesale-profit__list-image--height-md: 690px;
$wholesale-profit__list-image--height-lg: 530px;
$wholesale-profit__list-image--height-xl: 730px;
$wholesale-profit__list-image--margin-left-xl: 65px;

.wholesale-profit__list-image.wholesale-images {
  padding-bottom: percentage(175px / $wholesale-profit__list-image--height);

  @include media-breakpoint-up(sm) {
    padding-bottom: percentage(220px / $wholesale-profit__list-image--height-sm);
  }

  @include media-breakpoint-up(md) {
    padding-bottom: percentage(360px / $wholesale-profit__list-image--height-md);
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: percentage(310px / $wholesale-profit__list-image--height-lg);
  }

  @include media-breakpoint-up(xl) {
    right: auto;
    padding-bottom: percentage(375px / $wholesale-profit__list-image--height-xl);
    margin-left: $wholesale-profit__list-image--margin-left-xl;
  }
}

img.wholesale-profit__img {
  width: percentage(176px / $wholesale-profit__list-image--height);

  @include media-breakpoint-up(sm) {
    width: percentage(250px / $wholesale-profit__list-image--height-sm);
  }

  @include media-breakpoint-up(md) {
    width: percentage(390px / $wholesale-profit__list-image--height-md);
  }

  @include media-breakpoint-up(lg) {
    width: percentage(370px / $wholesale-profit__list-image--height-lg);
  }

  @include media-breakpoint-up(xl) {
    width: percentage(410px / ($wholesale-profit__list-image--height-xl - $wholesale-profit__list-image--margin-left-xl));
  }
}
