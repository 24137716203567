.retail-prices {
  &__title {
    //@include property-by-media(margin, (default: 0 0 1.25rem, sm: 0 9% 1.25rem, md: 0 20% 1.875rem, lg: 0 20% 1.875rem, xl: 0 20% 1.875rem));
    @include property-by-media(margin-bottom, (default: 1.25rem, md: 1.875rem));
  }

  &__items {
    margin-bottom: -$grid-gutter-width;
  }
  &__item {
    margin-bottom: $grid-gutter-width;
  }

  &__card {
    border-radius: 1.25rem;
    box-shadow: .125rem .25rem 1.875rem rgba(0, 0, 0, .1);
    border: .125rem solid transparent;

    &--active {
      @include media-breakpoint-up(lg) {
        transform: translateY(-.625rem);
      }
    }

    &--active#{&}--green {
      border-color: #a0e255;
    }
    &--active#{&}--orange {
      border-color: #ed735b;
    }
    &--active#{&}--purple {
      border-color: #8378fc;
    }

    &-title {
      @include property-by-media(margin-top, (default: 1.375rem, sm: 1.875rem));
      @include property-by-media(margin-bottom, (default: 1.375rem, sm: 1.875rem));
      left: -.125rem;
    }
  }

  &__description {
    padding: 0 8px;
    line-height: 1.4;
    @include property-by-media(font-size, (default: .8125rem, sm: 1.125rem, md: .9375rem, lg: 1rem));

    @include media-breakpoint-only(lg) {
      padding: 0 .5625rem;
    }

    @include media-breakpoint-only(sm) {
      padding-right: 18px;
      padding-left: 18px;
    }

    @include media-breakpoint-up(md) {
      margin-top: 1.25rem;
    }
  }

  &__list {
    line-height: 1.2;
    margin: 1rem 0;
    color: #5c5c5c;

    @include property-by-media(font-size, (default: .75rem, sm: .875rem, lg: .8125rem));
    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
    }

    > li {
      display: flex;
      @include property-by-media(margin, (default: .5rem 13px, sm: .5rem 60px, md: .5rem 13px, lg: .5rem 8px, xl: .5rem 35px .5rem));

      &::before {
        @extend .icon;

        content: $icon-check-round;
        flex-shrink: 0;

        @include property-by-media(font-size, (default: .9375rem, sm: 1.25rem));
        @include property-by-media(margin-right, (default: .625rem, sm: 17px, md: .75rem, lg: .625rem, xl: 1rem));
      }
    }
  }

  &__card--green &__list > li::before,
  &__card--green &__card-colored-text {
    color: #a0e255;
  }
  &__card--orange &__list > li::before,
  &__card--orange &__card-colored-text {
    color: #ed735b;
  }
  &__card--purple &__list > li::before,
  &__card--purple &__card-colored-text {
    color: #8378fc;
  }
}
