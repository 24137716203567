@mixin hidden-fields($columns...) {
  @each $column in $columns {
    > div:nth-child(#{$column}) {
      display: none;
      order: 13;
      align-items: flex-start;
      width: 100%;
      padding: {
        top: 0;
        bottom: .25rem;
      }
      border: none !important;

      &::before {
        content: attr(data-title) ':';
        margin-right: .33em;
        font-weight: 500;
      }
    }
    &.expanded > div:nth-child(#{$column}) {
      display: block;
    }
  }
}

%break-word-cell {
  word-break: break-word;
}
%fixed-cell {
  flex-grow: 0;
  flex-shrink: 0;
}

.search-table {
  font-size: .875rem;

  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid #d0d0d0;
  }

  &__row {
    display: flex;

    @include media-breakpoint-down(xl) {
      flex-wrap: wrap;
    }

    &:nth-child(even) {
      background-color: #f5f5f5;
    }

    > div {
      padding: .9375rem .625rem;

      flex-grow: 1;
      display: flex;
      align-items: center;

      &:first-child {
        @extend %fixed-cell;
        width: 8rem;
      }

      &:last-child {
        @extend %fixed-cell;
        width: 12rem;
        padding-top: .75rem;
      }

      &:nth-child(2) {
        @extend %fixed-cell;
        width: 6rem;
      }
      &:nth-child(3) {
        @extend %fixed-cell;
        width: 3.5rem;
      }
      &:nth-child(4) {
        @extend %break-word-cell;
        width: 20%;
      }
      &:nth-child(5) {
        @extend %break-word-cell;
        width: 20%;
      }
      &:nth-child(6) {
        @extend %break-word-cell;
        width: 20%;
      }
      &:nth-child(7) {
        @extend %fixed-cell;
        width: 7.5rem;
      }
      &:nth-child(8) {
        @extend %fixed-cell;
        width: 6.5rem;
      }
      &:nth-child(9) {
        @extend %break-word-cell;
        width: 20%;
      }
      &:nth-child(10) {
        @extend %break-word-cell;
        width: 20%;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    &__row {
      @include hidden-fields(8,9,10);

      > div {
        &:last-child {
          display: flex;
          width: 16%;
        }
        &:first-child {
          width: 12%;
        }
        &:nth-child(2) {
          width: 10%;
        }
        &:nth-child(3) {
          width: 6%;
        }
        &:nth-child(4) {
          width: 15%;
        }
        &:nth-child(5) {
          width: 14%;
        }
        &:nth-child(6) {
          width: 14%;
        }
        &:nth-child(7) {
          width: 13%;
        }
      }    }
  }

  @include media-breakpoint-down(md) {
    &__row {
      @include hidden-fields(6,7);

      > div {
        &:first-child {
          width: 16%;
        }
        &:last-child {
          width: 21%;
        }
        &:nth-child(2) {
          width: 12%;
        }
        &:nth-child(3) {
          width: 8%;
        }
        &:nth-child(4) {
          width: 21%;
        }
        &:nth-child(5) {
          width: 22%;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__row {
      @include hidden-fields(3,4,5);

      > div {
        &:first-child {
          width: 26%;
        }
        &:last-child {
          width: 49%;
        }
        &:nth-child(2) {
          width: 25%;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    &__row {
      @include hidden-fields(2);

      > div {
        &:first-child {
          width: 45%;
        }
        &:last-child {
          width: 55%;
        }
      }
    }
  }

  &__row:not(&__head) {
    margin-bottom: -1px;

    > div {
      border-color: #d0d0d0;
      border-style: solid;
      border-width: 1px 0;
    }
  }
  &__row.expanded > div {
    border-bottom: none;
  }

  &__head {
    @include media-breakpoint-up(xl) {
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }
  &__head > div {
    font-weight: 500;
    color: $white;
    background-color: $gray-800;
    padding: {
      top: .4375rem;
      bottom: .4375rem;
    }
    min-height: 3.125rem;

    &:not(:last-child) {
      border-right: 1px solid $gray-400;
    }
  }

  &__image-link {
    display: inline-block;

    @include transition(opacity .3s ease-in-out);

    &:hover {
      opacity: .6;
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 1rem;
  }

  &__place-bet {
    font: {
      family: $font-family-roboto;
      size: .875rem;
      weight: 500;
    }
    padding: {
      top: .625rem;
      bottom: .625rem;
    }
    margin-top: .25rem;
    line-height: 1rem;
    text-transform: none;

    @include media-breakpoint-down(xl) {
      width: min-content;
      padding: .125rem .625rem;
    }

    @include media-breakpoint-only(sm) {
      width: auto;
      padding: .625rem .75rem;
    }
  }

  &__expand-btn {
    @include clear-button;

    width: 1.5625rem;
    height: 1.5625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #9dd47a;
    color: $white;
    border: .125rem solid $white;
    border-radius: 50%;
    box-shadow: 0 0 .4375rem rgba(0, 0, 0, 0.3);
    margin-left: 1.5rem;
    margin-right: 1rem;
    position: relative;

    @include media-breakpoint-up(xl) {
      width: 1.25rem;
      height: 1.25rem;
    }

    @include media-breakpoint-up(xxl) {
      display: none;
    }

    .icon {
      font-size: .625rem;
      line-height: 1;
      vertical-align: baseline;

      @include media-breakpoint-up(xl) {
        font-size: .5rem;
      }
    }

    .icon::before {
      content: $icon-plus;
    }
  }
  &__row.expanded &__expand-btn {
    background-color: #e05f5f;

    .icon::before {
      content: $icon-minus;
    }
  }
}
