.retail-main {
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }

  @include media-breakpoint-up(lg) {
    $container-width: map-get($container-max-widths, lg);

    position: relative;

    &::after {
      content: '';
      position: absolute;
      background-color: $gray-100;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: -1;

      @include main-section-half-bg($container-width, .25, right);
    }
  }

  @include media-breakpoint-up(xl) {
    $container-width: map-get($container-max-widths, xl);

    &::after {
      @include main-section-half-bg($container-width, .25, right);
    }

    > [class*="container-"] {
      padding-top: 2rem;
    }
  }
}

$retail-carousel--gutter-width: 40px;

.retail-carousel {
  margin: {
    right: -$retail-carousel--gutter-width/2;
    left: -$retail-carousel--gutter-width/2;
    bottom: .75rem;
  }

  .carousel-inner {
    padding: {
      right: $retail-carousel--gutter-width/4;
      left: $retail-carousel--gutter-width/4;
    }

    @include media-breakpoint-up(lg) {
      overflow: visible;
      padding: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    margin: .5rem 0 0;

    .carousel-item {
      opacity: 0;
      transition-property: opacity;
      transform: none;
    }

    .carousel-item.active,
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      z-index: 1;
      opacity: 1;
    }

    .active.carousel-item-left,
    .active.carousel-item-right {
      z-index: 0;
      opacity: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    left: 2.5rem;
  }

  &::after {
    @include media-breakpoint-down(md) {
      content: '';
      position: absolute;
      background-color: $gray-100;
      top: calc(50% - 1.25rem);
      left: 0;
      right: 0;
      height: 1000%;
      z-index: -1;
    }

    @include media-breakpoint-up(sm) {
      top: calc(50% - 1.5rem);
    }
  }

  @include carousel-indicators;

  .carousel-indicators {
    @include media-breakpoint-up(lg) {
      position: absolute;
      padding: 0;
      top: 0;
      left: 0;
      right: auto;
      bottom: auto;
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      @include media-breakpoint-up(lg) {
        width: auto;
        margin: 0 0 .25rem 2.5rem;
        background-color: transparent !important;
        transition: color .3s ease-in-out;
        height: auto;
        text-indent: 0;
        border: none;
        position: relative;

        font-size: .875rem;
        color: $gray-500;

        &::before {
          content: '';
          position: absolute;
          left: -2.5rem;
          top: 50%;
          width: 1.875rem;
          height: 1px;
          transition: background-color .3s ease-in-out;
        }

        &:hover {
          color: $body-color;
          font-weight: 500;
        }
      }

      @include media-breakpoint-up(xl) {
        font-size: 1rem;
      }

      &.active {
        @include media-breakpoint-up(lg) {
          font-weight: 500;
          color: $body-color;

          &::before {
            background-color: $menu-link-hover-color;
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    background-color: #dfdfdf;
    margin: 0 $retail-carousel--gutter-width/4;

    @include media-breakpoint-up(lg) {
      margin: 0;
      background-color: transparent;
    }

    @include media-breakpoint-up(xl) {
      margin-top: -2.5rem;
    }
  }

  &__image {
    @include media-breakpoint-up(lg) {
      position: relative;
    }

    img {
      width: 100%;

      @include media-breakpoint-up(lg) {
        box-shadow: $box-shadow-images;
      }
    }

    &--before {
      @include media-breakpoint-up(lg) {
        margin-left: 210px;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 12rem;
        left: 2.5rem;
      }
    }

    &--after {
      @include media-breakpoint-up(lg) {
        left: 4rem;
        margin-right: 210px;
        margin-top: -10px;
      }

      @include media-breakpoint-up(xl) {
        margin-right: 12rem;
        left: 2.5rem;
      }
    }
  }

  &__caption {
    font-weight: 500;
    font-size: .875rem;
    padding: 5px 0;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
      padding: 8px 0;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.3125rem;
      font-weight: $font-weight-bold;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.retail-main__more {
  @include media-breakpoint-up(sm) {
    width: 210px;
  }

  @include media-breakpoint-up(xl) {
    width: 160px;
  }
}
