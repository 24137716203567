.retail-nuances {
  &__container {
    background-color: $white;
    border-radius: .9375rem;
    box-shadow: .1875rem .1875rem 1.25rem rgba(0, 0, 0, .07);

    @include property-by-media(padding, (default: 1.25rem .625rem, sm: 1.875rem 1.25rem, md: 1.875rem .9375rem, lg: 1.5rem .9375rem));

    position: relative;
    &::before {
      @extend .icon;

      position: absolute;
      top: 0;
      left: 50%;
      border-radius: 50%;
      color: $white;
      text-align: center;

      transform: translate(-50%, -50%);

      @include property-by-media(width, (default: 1.875rem, sm: 2.25rem));
      @include property-by-media(height, (default: 1.875rem, sm: 2.25rem));
      @include property-by-media(line-height, (default: 1.875rem, sm: 2.25rem));
      @include property-by-media(font-size, (default: .75rem, sm: 1rem));
    }
    &--plus::before {
      content: $icon-plus;
      background-image: linear-gradient(#afea75 0%, #9dd57a 100%);
    }
    &--minus::before {
      content: $icon-minus;
      background-image: linear-gradient(#ea7575 0%, #e05f5f 100%);
    }

    dt {
      @include property-by-media(font-size, (default: 1rem, sm: 1.3125rem, lg: 1.125rem));
    }
    dd {
      @include property-by-media(font-size, (default: .8125rem, sm: 1.125rem, lg: 0.875rem));

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    @include media-breakpoint-up(lg) {
      align-self: center;
    }

    img {
      @include responsive-image;

      @include property-by-media(margin, (default: 1.25rem 0 2.1875rem, sm: 1.875rem 0 3rem, lg: 0, xl: 0 2.5rem));

      @include media-breakpoint-up(xl) {
        max-width: 21.875rem;
      }
    }
  }

  &__title {
    margin: {
      left: auto;
      right: auto;
    }
    @include property-by-media(margin-bottom, (default: 2rem, sm: 3rem, lg: 2.75rem, xl: 2.25rem));
    //@include property-by-media(max-width, (md: 75%, lg: 50%));
  }
}
