@import "intro";
@import "privilege";
@import "retails";
@import "lots";
@import "ports";
@import "review";
@import "wholesale";
@import "services";

.shipment-preparation {
  @include media-breakpoint-between(sm,md) {
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-up(lg) {
    @include clearfix;
  }

  $shipment-preparation__video-width-lg: 545px;
  $shipment-preparation__video-width-xl: 647px;
  $container--width-lg: map-get($container-max-widths, lg) - 40px;
  $container--width-xl: map-get($container-max-widths, xl) - 30px;

  &__title, &__info {
    @include media-breakpoint-up(lg) {
      float: right;
      width: percentage(($container--width-lg - $shipment-preparation__video-width-lg) / $container--width-lg);
      padding-left: $grid-gutter-width/2;
    }

    @include media-breakpoint-up(xl) {
      width: percentage(($container--width-xl - $shipment-preparation__video-width-xl) / $container--width-xl);
    }
  }

  &__video {
    @include media-breakpoint-between(sm,md) {
      order: -1;
    }

    @include media-breakpoint-up(lg) {
      float: left;
      width: percentage($shipment-preparation__video-width-lg / $container--width-lg);
      padding-right: $grid-gutter-width/2;
    }

    @include media-breakpoint-up(xl) {
      width: percentage($shipment-preparation__video-width-xl / $container--width-xl);
    }

    &-inner {
      position: relative;
      padding-bottom: 56.25%;

      iframe {
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.instagram-main {
  &__link {
    display: block;
    margin: {
      bottom: $grid-gutter-width/2;
      top: $grid-gutter-width/2;
    }

    @include transition(transform .15s ease-in-out, box-shadow .15s ease-in-out);

    &:hover {
      box-shadow: .3125rem .5rem 1.5625rem rgba(0,0,0,.3);
      transform: scale(1.04);
    }
  }
}
