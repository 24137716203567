body {
  @include property-by-media(font-size, (default: .875rem, sm: 1.125rem, lg: 1rem, xl: 1.125rem));
}

.text-small {
  font-size: .875rem;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-800 {
  font-weight: 800;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
}

h1, .h1 {
  @include property-by-media(font-size, (default: 1.625rem, sm: 2.25rem, xl: 2.75rem));
}

h2, .h2 {
  @include property-by-media(font-size, (default: 1.625rem, sm: 1.75rem, md: 2rem, xl: 2.25rem));
}

h3, .h3 {
  @include property-by-media(font-size, (default: 1.125rem, sm: 1.3125rem, md: 1.625rem, lg: 1.3125rem, xl: 1.625rem));
}

h4, .h4 {
  font-weight: 600;
  @include property-by-media(font-size, (default: 1.125rem, sm: 1.3125rem, lg: 1.125rem, xl: 1.5rem));
}

h5, .h5 {
  font-weight: 600;
  @include property-by-media(font-size, (default: 1rem, sm: 1.125rem, md: 1.3125rem, lg: 1.125rem, xl: 1.3125rem));
}

h6, .h6 {
  font-weight: 600;
  @include property-by-media(font-size, (default: .875rem, sm: 1rem, md: 1.125rem, lg: 1rem, xl: 1.125rem));
}

p, .p {
  @include media-breakpoint-down(xs) {
    margin-bottom: .5rem;
  }
}

ul.p, ol.p {
  padding-left: 1.25em;
}
