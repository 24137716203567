.track-page {
  .alert {
    font-size: .875rem;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
  }

  .btn-download {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: {
      top: 0;
      bottom: 0;
    }
    height: 3.125rem;
    line-height: 1.3;
  }

  &__title {
    @include property-by-media(font-size, (default: 1.125rem, sm: 1.5rem));
    margin-bottom: 1rem;
  }
}

.report-carousel {
  @extend .lot-carousel;

  @include media-breakpoint-up(xl) {
    margin-bottom: .625rem;
  }

  &__item {
    @extend .lot-carousel__item;

    img {
      border-radius: 0;
    }
  }

  &__thumb {
    @extend .lot-carousel__thumb;

    @include media-breakpoint-up(sm) {
      width: 16.666%;
    }
    @include media-breakpoint-only(md) {
      width: 11.111%;
    }

    &s {
      @extend .lot-carousel__thumbs;
    }

    &-link {
      @extend .lot-carousel__thumb-link;
    }
  }
}

.track-table {
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
  @include media-breakpoint-down(xs) {
    font-size: .8125rem;
  }

  tr:nth-of-type(odd) {
    background-color: #f5f5f5;
  }

  td, th {
    border-color: #d0d0d0;

    padding: {
      left: $grid-gutter-width/2 !important;
      right: $grid-gutter-width/2 !important;
    }

    @include property-by-media(line-height, (default: 1.5, sm: 1.45, md: 1.5, lg: 1.45, xl: 1.5));
    @include property-by-media(padding, (default: .9375rem, sm: .8125rem, md: .6875rem, lg: .8125rem, xl: .6875rem));
  }

  .icon {
    line-height: 1;

    @include property-by-media(font-size, (default: .75rem, sm: .9375rem));
  }

  &-container {
    @include media-breakpoint-down(xs) {
      margin: 0 (-$grid-gutter-width/2);
    }
  }
}

.track-data {
  @include media-breakpoint-down(md) {
    margin: 0 (-$grid-gutter-width/2);
    overflow-x: auto;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ebebeb;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 10px;
  }

  .track-table {
    min-width: 44rem;
  }
}

.report-tabs {
  @include property-by-media(font-size, (default: .75rem, sm: .9375rem));
  @include property-by-media(line-height, (default: 1.45, sm: 1.15));

  @include media-breakpoint-up(lg) {
    margin-top: -2.125rem;
  }
}
