.menu-toggle {
  @include clear-button;
  @include gutter-padding;

  margin-left: -$grid-gutter-width / 3;

  @include media-breakpoint-up(xl) {
    display: none;
  }

  color: $white;

  &--back {
    color: $submenu-link-color;
  }

  &__hamburger {
    @include menu-toggle-icon;
  }

  &__bar {
    &,
    &::before,
    &::after {
      @include menu-toggle-bar;
    }

    top: 50%;
    margin-top: -1px;

    &::before {
      top: -.5rem;
    }

    &::after {
      bottom: -.5rem;
    }
  }

  &.opened &__bar {
    background-color: transparent;

    &::before,
    &::after {
      left: -2px;
      right: -2px;
    }

    &::before {
      transform: rotate(45deg);
      top: 0;
    }

    &::after {
      transform: rotate(-45deg);
      bottom: 0;
    }
  }
}

.top-menu {
  $top-menu: &;

  @include media-breakpoint-up(xl) {
    align-self: stretch;
    display: flex;
    flex-grow: 1;
  }

  /*&__back {
    @include header-size;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }*/

  &__container {
    @include media-breakpoint-up(xl) {
      padding: 0;
      display: flex;
    }
  }

  &__list {
    $top-menu__list: &;

    font-size: 1.125rem;
    font-weight: $font-weight-bold;
    margin: 0;
    text-transform: uppercase;
    list-style: none;
    padding: 0;

    @include media-breakpoint-up(xl) {
      font-size: .9375rem;
      text-transform: none;
      letter-spacing: .03em;
      display: flex;
      margin: 0 auto;
    }

    &-item {
      @include media-breakpoint-up(xl) {
        display: inline-flex;
        align-items: center;
        padding: 0 .75rem;
        position: relative;

        &-wrapper {
          &:hover {
            align-self: stretch;
            display: flex;
            align-items: center;
            margin: 0 -.75rem;
            padding: 0 .75rem;
          }
        }

        #{$top-menu__list} #{$top-menu__list} & {
          display: block;
          padding: 0 1rem;
        }
      }
    }

    & & {
      margin: 0;
      letter-spacing: 0;
      backface-visibility: hidden;

      @include media-breakpoint-up(xl) {
        font-weight: $font-weight-base;
        position: absolute;
        top: 100%;
        left: -.25rem;
        background: $white;
        z-index: -1;
        display: block;
        box-shadow: 0 .125rem .625rem rgba(0, 0, 0, 0.1);
        padding: .75rem 0;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        transition: transform .5s .3s ease-in-out, opacity .5s .3s ease-in-out, visibility .8s 0s;
      }
    }
    &-item-wrapper:hover &,
    &-item-wrapper:focus-within & {
      @include media-breakpoint-up(xl) {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: transform .3s ease-in-out, opacity .3s ease-in-out, visibility 0s;
      }
    }
  }

  &__link {
    text-decoration: none;
    display: inline-block;

    color: $submenu-link-color;

    &:hover {
      text-decoration: none;
      color: $submenu-link-hover-color;
    }

    @include media-breakpoint-down(lg) {
      padding: .25rem .5rem;
      margin: .25rem -.5rem;

      &:not(:last-child) {
        display: none;
      }
    }

    @include media-breakpoint-up(xl) {
      color: $menu-link-color;

      &:hover {
        color: $menu-link-hover-color;
      }

      &:not(:last-child) {
        &::after {
          content: '';
          display: inline-block;
          position: relative;
          margin-left: .5rem;
          top: -.125rem;
          width: .5625rem;
          height: .5625rem;
          border: solid $menu-link-color;
          border-width: 0 0 .125rem .125rem;
          transform: rotate(-45deg);
          transition: border-color .15s ease-in-out;
        }

        &:hover::after {
          border-color: $menu-link-hover-color;
        }
      }

      #{$top-menu}__list #{$top-menu}__list & {
        color: $submenu-link-color;
        margin: .125rem 0;
        white-space: nowrap;

        &:hover {
          text-decoration: none;
          color: $submenu-link-hover-color;
        }
      }
    }
  }

  .social-menu {
    a {
      color: $submenu-link-color;
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &__phone {
    display: none;

    @include media-breakpoint-up(xl) {
      display: block;
      align-self: center;
      font-size: 1.0625rem;

      &:not(:last-child) {
        margin-right: auto;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .btn-login {
    @include media-breakpoint-down(lg) {
      display: block;
      width: 100%;
      @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
    }
    @include media-breakpoint-up(xl) {
      align-self: center;
      line-height: 1.3 !important;
    }
  }
}
