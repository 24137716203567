.how-it {
  &__title {
    //@include property-by-media(margin, (default: 0 0 1.25rem, sm: 0 calc(15% - 2.5rem) 1.25rem, md: 0 20% 1.875rem, lg: 0 20% 1.875rem, xl: 0 20% 1.875rem));
    @include property-by-media(margin-bottom, (default: 1.25rem, md: 1.875rem));
  }

  &__list {
    counter-reset: how-it;

    dt:not(:first-child) {
      @include property-by-media(margin-top, (default: .9375rem, sm: 1.25rem));
    }

    dd {
      @include property-by-media(padding, (default: 0, sm: 0 0 0 5px, md: 0 1.875rem 0 3.75rem, lg: 0 5rem 0 4.375rem));
      @include property-by-media(font-size, (default: .8125rem, sm: 1.125rem, lg: 1rem, xl: 1.125rem));

      p {
        margin-top: 1.25rem;
        margin-bottom: 0;

        &:last-of-type {
          @include media-breakpoint-down(xs) {
            padding-bottom: .3125rem;
          }
        }
      }
    }
  }
  &__btn {
    //border-radius: 20px/50%;
    @include button-variant($submenu-link-hover-color, $submenu-link-hover-color, $link-hover-color, $link-hover-color, $link-hover-color, $link-hover-color);

    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 600;
    text-transform: none;
    line-height: 1.2;
    padding: .3125rem;
    border: none;
    color: $body-color;
    background-color: #d0cfcf;
    text-align: left;

    @include property-by-media(font-size, (default: .8125rem, sm: 1.3125rem, lg: 1.125rem, xl: 1.3125rem));
    @include property-by-media(border-radius, (default: calc(1.875rem + .625rem), sm: calc(3.125rem + .625rem), md: calc(2.5rem + .625rem)));

    &::before {
      counter-increment: how-it;
      content: counter(how-it);
      color: $submenu-link-hover-color;
      background-color: $white;
      display: inline-block;
      text-align: center;
      border-radius: 50%;
      flex-shrink: 0;

      @include property-by-media(font-size, (default: 1rem, sm: 1.3125rem, lg: 1.125rem, xl: 1.3125rem));
      @include property-by-media(width, (default: 1.875rem, sm: 3.125rem, md: 2.5rem));
      @include property-by-media(height, (default: 1.875rem, sm: 3.125rem, md: 2.5rem));
      @include property-by-media(line-height, (default: 1.875rem, sm: 3.125rem, md: 2.5rem));
      @include property-by-media(margin-right, (default: .625rem, md: .9375rem, lg: 1.5625rem));
    }

    &::after {
      @extend .icon;
      content: $icon-angle-down;

      display: inline-block;
      color: $submenu-link-hover-color;
      margin-left: auto;
      flex-shrink: 0;

      @include property-by-media(font-size, (default: .625rem, sm: .9375rem));
      @include property-by-media(padding-right, (default: .625rem, sm: .9375rem, md: 1.5625rem, xl: 1.25rem));
      @include property-by-media(padding-left, (default: .625rem, md: .9375rem, lg: 1.5625rem));
      @include property-by-media(transform-origin, (default: 15px 4px, sm: 17.5px 6.5px, md: 22.5px 6.5px, lg: 32.5px 6.5px));
      @include transition(color .15s ease-in-out, transform .3s ease-in-out);
    }

    &:hover, &:focus, &:active {
      color: $white !important;

      &::after {
        color: $white;
      }
    }

    &:not(.collapsed) {
      background-color: $submenu-link-hover-color;
      color: $white;

      &::after {
        color: $white;
        transform: rotate(-180deg);
      }
    }
  }
}
