.btn {
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  .icon {
    &:first-child {
      margin-right: .25rem;
    }

    &:last-child {
      margin-left: .25rem;
    }

    &:only-child {
      margin-left: 0;
      margin-right: 0;
      vertical-align: text-bottom;
    }
  }
}

.btn-orange {
  @include button-variant($menu-link-hover-color, $menu-link-hover-color, $link-hover-color, $link-hover-color, $link-hover-color, $link-hover-color);

  color: $white !important;
}

.btn-outline-orange {
  @include button-outline-variant($menu-link-hover-color, $white);
}

.btn-black {
  @include button-variant($body-color, $body-color, #4d4d4d, #4d4d4d, #4d4d4d, #4d4d4d);

  color: $white !important;
}

.btn-login {
  font-family: $font-family-base;
  font-size: .9375rem !important;
  line-height: 1.5 !important;
  letter-spacing: .03em;
  text-transform: none;
}

.btn-youtube {
  font-size: .75rem;
  font-weight: 600;
  color: #3a3a3a;
  text-transform: none;
  box-shadow: .125rem .1875rem .625rem rgba(0,0,0,.1);
  border-radius: .4375rem;
  display: inline-flex;
  align-items: center;
  padding: .5625rem .8125rem;

  @include media-breakpoint-up(sm) {
    font-size: .8125rem;
    padding: .5625rem .8125rem;
  }

  @include media-breakpoint-up(md) {
    font-size: .875rem;
  }

  @include media-breakpoint-up(lg) {
    padding: {
      top: .4375rem;
      bottom: .4375rem;
    }
  }

  .icon {
    font-size: 1.375rem;
    color: #fd1919;
    margin-right: .5rem !important;

    @include transition($btn-transition);

    @include media-breakpoint-up(sm) {
      font-size: 1.75rem;
    }
  }

  span {
    text-align: left;
    line-height: 1.2;

    @include media-breakpoint-between(sm,md) {
      br {
        display: none;
      }
    }
  }

  &:hover {
    color: #535353;

    .icon {
      color: #fd5353;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .btn#{$infix}-block {
      display: block;
      width: 100%;

      + .btn#{$infix}-block {
        margin-top: $btn-block-spacing-y;
      }
    }

    .btn#{$infix}-inline {
      display: inline-block;
      width: auto;
    }

    .btn#{$infix}-long {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}

.question-btn {
  @include clear-button;

  font-size: 1.375rem;
  color: $submenu-link-hover-color;
}
