$icomoon-font-path: '../fonts';

$grid-breakpoints: (
        xs: 0,
        sm: 480px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px
);

$container-max-widths: (
        sm: 460px,
        md: 720px,
        lg: 970px,
        xl: 1140px
);

$grid-gutter-width: 30px;
$form-grid-gutter-width: 20px;

$header-height: 4.375rem;
$header-mobile-height: 3.125rem;
$header-search-height: 3.75rem;

$font-family-roboto: "Roboto", Arial, Helvetica, sans-serif;
$font-family-montserrat: "Montserrat", Arial, Helvetica, sans-serif;

$font-family-base: $font-family-roboto;
$font-size-base: 1rem;

$headings-font-family: $font-family-montserrat;
$headings-font-weight: 700;

$enable-shadows: true;

$body-color: #1a1a1a;
$gray-100: #f9f9f9;
$gray-200: #e5e5e5;
$gray-400: #c5c5c5;
$gray-500: #9a9a9a;
$gray-600: #858585;
$gray-700: #525252;
$gray-800: #3e3e3e;

$box-shadow-default: 0 .125rem .625rem rgba(0, 0, 0, .1);
$box-shadow-images: 0 .9375rem 1.875rem rgba(0, 0, 0, .2);
$border-radius: .3125rem;

$link-color: #e94f2c;
$link-hover-color: #ec8672;
$link-decoration: underline;

$menu-link-color: #fff;
$menu-link-hover-color: #e55e43;
$submenu-link-color: $body-color;
$submenu-link-hover-color: $menu-link-hover-color;

$input-border-width: 0;
$input-font-size: .875rem;
$input-box-shadow: inset 0 .0625rem .3125rem rgba(0, 0, 0, 0.2);
$input-padding-y: .5rem;
$input-line-height: 1.715;
$input-placeholder-color: #9a9a9a;

$input-font-size-lg: 1rem;
$input-padding-y-lg: .8125rem;

$btn-border-width: 2px;
$btn-font-family: $font-family-montserrat;
$btn-box-shadow: none;

$btn-font-size-lg: .875rem;
$btn-padding-y-lg: .75rem;
$btn-line-height-lg: 1.6;

$custom-control-indicator-size: 1.0625rem;
$custom-control-indicator-bg-size: .8125rem;
$custom-control-indicator-box-shadow: none;
$custom-control-indicator-border-width: 1px;
$custom-control-indicator-checked-color: $menu-link-hover-color;
$custom-control-indicator-checked-bg: #fff;
$custom-control-indicator-checked-border-color: $menu-link-hover-color;

$custom-checkbox-indicator-border-radius: 0;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M16 1.80892L14.12 0L5.33333 8.39491L1.89333 5.12102L0 6.9172L5.33333 12L16 1.80892Z' fill='#fff'/></svg>");

$custom-select-indicator-color: $body-color;
$custom-select-bg-size: 13px 8px;
$custom-select-indicator: url("data:image/svg+xml,<svg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.2681 1.08232L5.61496 7.55584C6.12217 8.14805 6.90412 8.14805 7.3902 7.55584L12.7371 1.08232C13.2443 0.469687 12.9907 0 12.2087 0H0.796445C-0.00663948 0 -0.239112 0.469687 0.2681 1.08232Z' fill='#{$custom-select-indicator-color}'/></svg>");

// Pagination
$pagination-color: $gray-500;
$pagination-bg: #f5f5f5;
$pagination-border-width: 0;
$pagination-hover-color: $submenu-link-hover-color;
$pagination-hover-bg: $pagination-bg;
$pagination-active-color: #fff;
$pagination-active-bg: $pagination-hover-color;

// Modals
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-content-box-shadow-xs: none;
$modal-content-box-shadow-sm-up: none;
$modal-backdrop-opacity: .4;
$modal-dialog-margin: $grid-gutter-width/2;

// Tabs
$nav-tabs-border-color: $gray-400;
$nav-tabs-link-active-color: #505050;
$nav-tabs-link-active-border-color: $gray-400 $gray-400 transparent;
