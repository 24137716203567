.g-map {
  @include property-by-media(width, (
          default: 100%,
          lg: 43.125rem,
          xl: 51.5625rem,
  ));

  @include property-by-media(height, (
          default: calc((100vw - #{$grid-gutter-width}) * .7142),
          sm: calc((100vw - #{$grid-gutter-width}) * .6818),
          md: calc((66.66vw - #{$grid-gutter-width}) * .5555),
          lg: 20.9375rem,
          xl: 25rem,
  ));
}
.contacts {
  address {
    @include property-by-media(font-size, (default: 1rem, sm: 1.125rem, xl: 1.3125rem));

    a {
      color: $body-color;
      text-decoration: none !important;

      &:hover {
        color: $submenu-link-hover-color;
      }
    }
  }

  &-list {
    text-align: center;
    margin-bottom: -$grid-gutter-width;

    &__item {
      margin: 0 auto $grid-gutter-width;
      display: inline-block;
    }

    &__image {
      border-radius: 50%;
      background-color: $gray-400;
      margin-bottom: .625rem;

      @include property-by-media(width, (default: 11.25rem, md: 12.5rem, xl: 13.75rem));
      @include property-by-media(height, (default: 11.25rem, md: 12.5rem, xl: 13.75rem));
    }

    &__description {
      font-weight: 500;
      font-family: $font-family-montserrat;

      @include property-by-media(font-size, (default: 1rem, sm: .875rem, md: 1rem));
    }

    &__title {
      font-weight: 600;
      margin: 0;

      @include property-by-media(font-size, (default: 1.125rem, xl: 1.3125rem));
    }
  }
}
