@charset "UTF-8";
@import "https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Roboto:400,400i,500,700,900&display=swap&subset=cyrillic";
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #858585;
  --gray-dark: #3e3e3e;
  --primary: #007bff;
  --secondary: #858585;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f9f9f9;
  --dark: #3e3e3e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 480px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1a1a;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #e94f2c;
  text-decoration: underline;
  background-color: transparent; }
  a:hover {
    color: #ec8672;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #858585;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #858585; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.3125rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #858585; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 480px) {
    .container {
      max-width: 460px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 480px) {
  .container, .container-sm {
    max-width: 460px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 970px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 480px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #1a1a1a; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #1a1a1a;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddddd; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: silver; }

.table-hover .table-secondary:hover {
  background-color: #d0d0d0; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d0d0d0; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfc; }

.table-hover .table-light:hover {
  background-color: #f0f0f0; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f0f0f0; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c9c9c9; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9b9b9b; }

.table-hover .table-dark:hover {
  background-color: #bcbcbc; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bcbcbc; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #3e3e3e;
  border-color: #515151; }

.table .thead-light th {
  color: #525252;
  background-color: #e5e5e5;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #3e3e3e; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #515151; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 479.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.715em + 1rem);
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.715;
  color: #525252;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #c5c5c5;
  border-radius: 0.3125rem;
  box-shadow: inset 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.2);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #525252; }
  .form-control:focus {
    color: #525252;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #9a9a9a;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e5e5e5;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #525252;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.715; }

.col-form-label-lg {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  font-size: 1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.715;
  color: #1a1a1a;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1.625rem);
  padding: 0.8125rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #858585; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.3125rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.715em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.42875em + 0.25rem) center;
  background-size: calc(0.8575em + 0.5rem) calc(0.8575em + 0.5rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.715em + 1rem);
  background-position: top calc(0.42875em + 0.25rem) right calc(0.42875em + 0.25rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.5rem);
  background: url("data:image/svg+xml,%3csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.2681 1.08232L5.61496 7.55584C6.12217 8.14805 6.90412 8.14805 7.3902 7.55584L12.7371 1.08232C13.2443 0.469687 12.9907 0 12.2087 0H0.796445C-0.00663948 0 -0.239112 0.469687 0.2681 1.08232Z' fill='%231a1a1a'/%3e%3c/svg%3e") no-repeat right 0.75rem center/13px 8px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8575em + 0.5rem) calc(0.8575em + 0.5rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.3125rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.715em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.42875em + 0.25rem) center;
  background-size: calc(0.8575em + 0.5rem) calc(0.8575em + 0.5rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.715em + 1rem);
  background-position: top calc(0.42875em + 0.25rem) right calc(0.42875em + 0.25rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.5rem);
  background: url("data:image/svg+xml,%3csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.2681 1.08232L5.61496 7.55584C6.12217 8.14805 6.90412 8.14805 7.3902 7.55584L12.7371 1.08232C13.2443 0.469687 12.9907 0 12.2087 0H0.796445C-0.00663948 0 -0.239112 0.469687 0.2681 1.08232Z' fill='%231a1a1a'/%3e%3c/svg%3e") no-repeat right 0.75rem center/13px 8px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8575em + 0.5rem) calc(0.8575em + 0.5rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 480px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #1a1a1a;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #1a1a1a;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: none; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
  box-shadow: none; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727272;
    border-color: #6c6c6c; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #727272;
    border-color: #6c6c6c;
    box-shadow: 0 0 0 0.2rem rgba(151, 151, 151, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c6c6c;
    border-color: #656565; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(151, 151, 151, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: none; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: none; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: none; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: none; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  box-shadow: none; }
  .btn-light:hover {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #e0e0e0; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #e0e0e0;
    box-shadow: 0 0 0 0.2rem rgba(217, 217, 218, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #d9d9d9; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(217, 217, 218, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  box-shadow: none; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2b2b2b;
    border-color: #252525; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #2b2b2b;
    border-color: #252525;
    box-shadow: 0 0 0 0.2rem rgba(91, 91, 91, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #3e3e3e;
    border-color: #3e3e3e; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #252525;
    border-color: #1e1e1e; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(91, 91, 91, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #858585;
  border-color: #858585; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 133, 133, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #858585;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(133, 133, 133, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f9f9f9;
  border-color: #f9f9f9; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f9f9f9;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }

.btn-outline-dark {
  color: #3e3e3e;
  border-color: #3e3e3e; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3e3e3e;
    border-color: #3e3e3e; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 62, 62, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3e3e3e;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3e3e3e;
    border-color: #3e3e3e; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(62, 62, 62, 0.5); }

.btn-link {
  font-weight: 400;
  color: #e94f2c;
  text-decoration: underline; }
  .btn-link:hover {
    color: #ec8672;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #858585;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  line-height: 1.6;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #1a1a1a;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 480px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e5e5e5; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f9f9f9; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #858585;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #858585;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -2px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: 0; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: 0; }

.input-group-prepend {
  margin-right: 0; }

.input-group-append {
  margin-left: 0; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.715;
  color: #525252;
  text-align: center;
  white-space: nowrap;
  background-color: #e5e5e5;
  border: 0 solid #c5c5c5;
  border-radius: 0.3125rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.625rem); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.8125rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5625rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.0625rem;
  height: 1.28125rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #e55e43;
    border-color: #e55e43;
    background-color: #fff;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: inset 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.2), 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
    box-shadow: none; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #858585; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e5e5e5; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.21875rem;
    left: -1.5625rem;
    display: block;
    width: 1.0625rem;
    height: 1.0625rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #9a9a9a solid 1px;
    box-shadow: none; }
  .custom-control-label::after {
    position: absolute;
    top: 0.21875rem;
    left: -1.5625rem;
    display: block;
    width: 1.0625rem;
    height: 1.0625rem;
    content: "";
    background: no-repeat 50% / 0.8125rem; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16 1.80892L14.12 0L5.33333 8.39491L1.89333 5.12102L0 6.9172L5.33333 12L16 1.80892Z' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23e55e43' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e55e43'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.35938rem; }
  .custom-switch .custom-control-label::before {
    left: -2.35938rem;
    width: 1.85938rem;
    pointer-events: all;
    border-radius: 0.53125rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.21875rem + 2px);
    left: calc(-2.35938rem + 2px);
    width: calc(1.0625rem - 4px);
    height: calc(1.0625rem - 4px);
    background-color: #9a9a9a;
    border-radius: 0.53125rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.79688rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.715em + 1rem);
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.715;
  color: #525252;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.2681 1.08232L5.61496 7.55584C6.12217 8.14805 6.90412 8.14805 7.3902 7.55584L12.7371 1.08232C13.2443 0.469687 12.9907 0 12.2087 0H0.796445C-0.00663948 0 -0.239112 0.469687 0.2681 1.08232Z' fill='%231a1a1a'/%3e%3c/svg%3e") no-repeat right 0.75rem center/13px 8px;
  border: 0 solid #c5c5c5;
  border-radius: 0.3125rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #525252;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #858585;
    background-color: #e5e5e5; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #525252; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1.625rem);
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  padding-left: 1rem;
  font-size: 1rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.715em + 1rem);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.715em + 1rem);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e5e5e5; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.715em + 1rem);
  padding: 0.5rem 0.75rem;
  font-weight: 400;
  line-height: 1.715;
  color: #525252;
  background-color: #fff;
  border: 0 solid #c5c5c5;
  border-radius: 0.3125rem;
  box-shadow: inset 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.2); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.715em + 1rem);
    padding: 0.5rem 0.75rem;
    line-height: 1.715;
    color: #525252;
    content: "Browse";
    background-color: #e5e5e5;
    border-left: inherit;
    border-radius: 0 0.3125rem 0.3125rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #9a9a9a; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #9a9a9a; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #9a9a9a; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #858585;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #c5c5c5; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e5e5e5 #e5e5e5 #c5c5c5; }
    .nav-tabs .nav-link.disabled {
      color: #858585;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #505050;
    background-color: #fff;
    border-color: #c5c5c5 #c5c5c5 transparent; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.3125rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.3125rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 479.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 480px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3125rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.3125rem - 1px);
      border-top-right-radius: calc(0.3125rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.3125rem - 1px);
      border-bottom-left-radius: calc(0.3125rem - 1px); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.3125rem - 1px) calc(0.3125rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.3125rem - 1px) calc(0.3125rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.3125rem - 1px);
  border-bottom-left-radius: calc(0.3125rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 480px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 480px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 480px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e5e5e5;
  border-radius: 0.3125rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #858585;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #858585; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3125rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #9a9a9a;
  text-decoration: none;
  background-color: #f5f5f5;
  border: 0 solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #e55e43;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #e55e43;
  border-color: #e55e43; }

.page-item.disabled .page-link {
  color: #858585;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #858585; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #6c6c6c; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(133, 133, 133, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f9f9f9; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #e0e0e0; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #3e3e3e; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #252525; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(62, 62, 62, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e5e5e5;
  border-radius: 0.3rem; }
  @media (min-width: 480px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.3125rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #454545;
  background-color: #e7e7e7;
  border-color: #dddddd; }
  .alert-secondary hr {
    border-top-color: #d0d0d0; }
  .alert-secondary .alert-link {
    color: #2c2c2c; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818181;
  background-color: #fefefe;
  border-color: #fdfdfd; }
  .alert-light hr {
    border-top-color: #f0f0f0; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #202020;
  background-color: #d8d8d8;
  border-color: #c9c9c9; }
  .alert-dark hr {
    border-top-color: #bcbcbc; }
  .alert-dark .alert-link {
    color: #070707; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e5e5e5;
  border-radius: 0.3125rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.3125rem; }

.list-group-item-action {
  width: 100%;
  color: #525252;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #525252;
    text-decoration: none;
    background-color: #f9f9f9; }
  .list-group-item-action:active {
    color: #1a1a1a;
    background-color: #e5e5e5; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #858585;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 480px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.3125rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #454545;
  background-color: #dddddd; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #454545;
    background-color: #d0d0d0; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #454545;
    border-color: #454545; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818181;
  background-color: #fdfdfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818181;
    background-color: #f0f0f0; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818181;
    border-color: #818181; }

.list-group-item-dark {
  color: #202020;
  background-color: #c9c9c9; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #202020;
    background-color: #bcbcbc; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #202020;
    border-color: #202020; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #858585;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 15px;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 30px); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 30px);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 30px); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 30px);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  box-shadow: none;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.4; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 480px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-content {
    box-shadow: none; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.3125rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #1a1a1a; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #858585 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #6c6c6c !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f9f9f9 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e0e0e0 !important; }

.bg-dark {
  background-color: #3e3e3e !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #252525 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #858585 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f9f9f9 !important; }

.border-dark {
  border-color: #3e3e3e !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.3125rem !important; }

.rounded-top {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important; }

.rounded-right {
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important; }

.rounded-left {
  border-top-left-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 480px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 480px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #858585 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #5f5f5f !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f9f9f9 !important; }

a.text-light:hover, a.text-light:focus {
  color: lightgray !important; }

.text-dark {
  color: #3e3e3e !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #181818 !important; }

.text-body {
  color: #1a1a1a !important; }

.text-muted {
  color: #858585 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #9a9a9a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?hguecg");
  src: url("../fonts/icomoon.eot?hguecg#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?hguecg") format("truetype"), url("../fonts/icomoon.woff?hguecg") format("woff"), url("../fonts/icomoon.svg?hguecg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.icon, .slick-slider .slick-prev, .slick-slider .slick-next, .retail-nuances__container::before, .how-it__btn::after, .retail-prices__list > li::before, .lot-carousel .slick-prev, .report-carousel .slick-prev, .lot-carousel .slick-next, .report-carousel .slick-next {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-boat:before {
  content: ""; }

.icon-bus:before {
  content: ""; }

.icon-car:before {
  content: ""; }

.icon-caravan:before {
  content: ""; }

.icon-electric-car:before {
  content: ""; }

.icon-lorry:before {
  content: ""; }

.icon-motorcycle:before {
  content: ""; }

.icon-trailer:before {
  content: ""; }

.icon-odometer:before {
  content: ""; }

.icon-odometer-bold:before {
  content: ""; }

.icon-engine:before {
  content: ""; }

.icon-sensors:before {
  content: ""; }

.icon-gas:before {
  content: ""; }

.icon-arrow-long-right:before, .top-order__carousel.slick-slider .slick-next:before {
  content: ""; }

.icon-arrow-long-left:before, .top-order__carousel.slick-slider .slick-prev:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-chevron-left:before {
  content: ""; }

.icon-chevron-right:before {
  content: ""; }

.icon-chevron-up:before {
  content: ""; }

.icon-chevron-down:before {
  content: ""; }

.icon-angle-left:before, .slick-slider .slick-prev:before, .lot-carousel .slick-prev:before, .report-carousel .slick-prev:before {
  content: ""; }

.icon-angle-right:before, .slick-slider .slick-next:before, .lot-carousel .slick-next:before, .report-carousel .slick-next:before {
  content: ""; }

.icon-angle-up:before {
  content: ""; }

.icon-angle-down:before {
  content: ""; }

.icon-close-circle:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-telegram:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

.icon-track-car:before {
  content: ""; }

.icon-track-container:before {
  content: ""; }

.icon-calc-shipping:before {
  content: ""; }

.icon-calc-custom:before {
  content: ""; }

.icon-support:before {
  content: ""; }

.icon-rocket:before {
  content: ""; }

.icon-quality:before {
  content: ""; }

.icon-insurance:before {
  content: ""; }

.icon-contract:before {
  content: ""; }

.icon-no-worries:before {
  content: ""; }

.icon-dealer:before {
  content: ""; }

.icon-no-fines:before {
  content: ""; }

.icon-shipping:before {
  content: ""; }

.icon-report:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-question-circle:before {
  content: ""; }

.icon-check-round:before {
  content: ""; }

.icon-filter:before {
  content: ""; }

.icon-location:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-user:before {
  content: ""; }

@media (max-width: 1199.98px) {
  body.slide-panel-opened {
    overflow-y: hidden; } }

body.modal-opened {
  overflow-y: hidden; }

a {
  text-decoration: none;
  transition: color .15s ease-in-out; }

a:focus,
button:focus {
  outline: 2px solid rgba(233, 79, 44, 0.5); }

.nav-tabs .nav-link {
  outline: none; }

.text-orange {
  color: #e55e43; }

@media (max-width: 991.98px) {
  .page-main {
    padding-top: 3.125rem; } }

.main-section {
  padding: 3rem 0;
  overflow-x: hidden; }
  @media (min-width: 992px) {
    .main-section {
      padding: 5rem 0; } }

.minor-section {
  padding: 3.125rem 0; }
  @media (min-width: 480px) {
    .minor-section {
      padding: 3.75rem 0; } }
  @media (min-width: 768px) {
    .minor-section {
      padding: 4.375rem 0; } }
  @media (min-width: 992px) {
    .minor-section {
      padding: 5rem 0; } }
  @media (min-width: 1200px) {
    .minor-section {
      padding: 7.5rem 0; } }
  .minor-section__title {
    margin-bottom: 1.875rem; }

.minor-section_profit {
  overflow-x: hidden; }

.blog-section {
  padding: 3.125rem 0; }
  @media (min-width: 480px) {
    .blog-section {
      padding: 3.75rem 0; } }
  @media (min-width: 992px) {
    .blog-section {
      padding: 4.375rem 0; } }
  @media (min-width: 1200px) {
    .blog-section {
      padding: 5rem 0; } }

.section-secondary {
  background-color: #f9f9f9; }

.link-directed {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-decoration: none !important; }
  .link-directed > span {
    text-decoration: underline; }
  .link-directed .icon:not(:only-child), .link-directed .slick-slider .slick-prev:not(:only-child), .slick-slider .link-directed .slick-prev:not(:only-child), .link-directed .slick-slider .slick-next:not(:only-child), .slick-slider .link-directed .slick-next:not(:only-child), .link-directed .retail-nuances__container:not(:only-child)::before, .link-directed .how-it__btn:not(:only-child)::after, .link-directed .retail-prices__list > li:not(:only-child)::before, .link-directed .lot-carousel .slick-prev:not(:only-child), .lot-carousel .link-directed .slick-prev:not(:only-child), .link-directed .report-carousel .slick-prev:not(:only-child), .report-carousel .link-directed .slick-prev:not(:only-child), .link-directed .lot-carousel .slick-next:not(:only-child), .lot-carousel .link-directed .slick-next:not(:only-child), .link-directed .report-carousel .slick-next:not(:only-child), .report-carousel .link-directed .slick-next:not(:only-child) {
    margin: 0 .5em; }
  .link-directed .icon:first-child, .link-directed .slick-slider .slick-prev:first-child, .slick-slider .link-directed .slick-prev:first-child, .link-directed .slick-slider .slick-next:first-child, .slick-slider .link-directed .slick-next:first-child, .link-directed .retail-nuances__container:first-child::before, .link-directed .how-it__btn:first-child::after, .link-directed .retail-prices__list > li:first-child::before, .link-directed .lot-carousel .slick-prev:first-child, .lot-carousel .link-directed .slick-prev:first-child, .link-directed .report-carousel .slick-prev:first-child, .report-carousel .link-directed .slick-prev:first-child, .link-directed .lot-carousel .slick-next:first-child, .lot-carousel .link-directed .slick-next:first-child, .link-directed .report-carousel .slick-next:first-child, .report-carousel .link-directed .slick-next:first-child {
    margin-left: 0; }
  .link-directed .icon:last-child, .link-directed .slick-slider .slick-prev:last-child, .slick-slider .link-directed .slick-prev:last-child, .link-directed .slick-slider .slick-next:last-child, .slick-slider .link-directed .slick-next:last-child, .link-directed .retail-nuances__container:last-child::before, .link-directed .how-it__btn:last-child::after, .link-directed .retail-prices__list > li:last-child::before, .link-directed .lot-carousel .slick-prev:last-child, .lot-carousel .link-directed .slick-prev:last-child, .link-directed .report-carousel .slick-prev:last-child, .report-carousel .link-directed .slick-prev:last-child, .link-directed .lot-carousel .slick-next:last-child, .lot-carousel .link-directed .slick-next:last-child, .link-directed .report-carousel .slick-next:last-child, .report-carousel .link-directed .slick-next:last-child {
    margin-right: 0; }
  p .link-directed .icon, p .link-directed .slick-slider .slick-prev, .slick-slider p .link-directed .slick-prev, p .link-directed .slick-slider .slick-next, .slick-slider p .link-directed .slick-next, p .link-directed .retail-nuances__container::before, p .link-directed .how-it__btn::after, p .link-directed .retail-prices__list > li::before, p .link-directed .lot-carousel .slick-prev, .lot-carousel p .link-directed .slick-prev, p .link-directed .report-carousel .slick-prev, .report-carousel p .link-directed .slick-prev, p .link-directed .lot-carousel .slick-next, .lot-carousel p .link-directed .slick-next, p .link-directed .report-carousel .slick-next, .report-carousel p .link-directed .slick-next {
    font-size: 0.625rem; }
    @media (min-width: 480px) {
      p .link-directed .icon, p .link-directed .slick-slider .slick-prev, .slick-slider p .link-directed .slick-prev, p .link-directed .slick-slider .slick-next, .slick-slider p .link-directed .slick-next, p .link-directed .retail-nuances__container::before, p .link-directed .how-it__btn::after, p .link-directed .retail-prices__list > li::before, p .link-directed .lot-carousel .slick-prev, .lot-carousel p .link-directed .slick-prev, p .link-directed .report-carousel .slick-prev, .report-carousel p .link-directed .slick-prev, p .link-directed .lot-carousel .slick-next, .lot-carousel p .link-directed .slick-next, p .link-directed .report-carousel .slick-next, .report-carousel p .link-directed .slick-next {
        font-size: 0.9375rem; } }
    @media (min-width: 768px) {
      p .link-directed .icon, p .link-directed .slick-slider .slick-prev, .slick-slider p .link-directed .slick-prev, p .link-directed .slick-slider .slick-next, .slick-slider p .link-directed .slick-next, p .link-directed .retail-nuances__container::before, p .link-directed .how-it__btn::after, p .link-directed .retail-prices__list > li::before, p .link-directed .lot-carousel .slick-prev, .lot-carousel p .link-directed .slick-prev, p .link-directed .report-carousel .slick-prev, .report-carousel p .link-directed .slick-prev, p .link-directed .lot-carousel .slick-next, .lot-carousel p .link-directed .slick-next, p .link-directed .report-carousel .slick-next, .report-carousel p .link-directed .slick-next {
        font-size: 0.875rem; } }

.responsive-image {
  width: 100%;
  height: auto;
  display: block; }

@media (max-width: 767.98px) {
  .container_p20 {
    padding-right: 20px;
    padding-left: 20px; }
    .container_p20 .row {
      margin-right: -20px;
      margin-left: -20px; }
      .container_p20 .row .col,
      .container_p20 .row [class*="col-"] {
        padding-right: 20px;
        padding-left: 20px; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .container_p20 {
    padding-right: 39px;
    padding-left: 39px; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .container_p20 {
    padding-right: 20px;
    padding-left: 20px; } }

a svg path {
  fill: currentColor; }

body {
  font-size: 0.875rem; }
  @media (min-width: 480px) {
    body {
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    body {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    body {
      font-size: 1.125rem; } }

.text-small {
  font-size: .875rem; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-800 {
  font-weight: 800; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem; }

h1, .h1 {
  font-size: 1.625rem; }
  @media (min-width: 480px) {
    h1, .h1 {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.75rem; } }

h2, .h2 {
  font-size: 1.625rem; }
  @media (min-width: 480px) {
    h2, .h2 {
      font-size: 1.75rem; } }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2.25rem; } }

h3, .h3 {
  font-size: 1.125rem; }
  @media (min-width: 480px) {
    h3, .h3 {
      font-size: 1.3125rem; } }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 1.625rem; } }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 1.3125rem; } }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.625rem; } }

h4, .h4 {
  font-weight: 600;
  font-size: 1.125rem; }
  @media (min-width: 480px) {
    h4, .h4 {
      font-size: 1.3125rem; } }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-weight: 600;
  font-size: 1rem; }
  @media (min-width: 480px) {
    h5, .h5 {
      font-size: 1.125rem; } }
  @media (min-width: 768px) {
    h5, .h5 {
      font-size: 1.3125rem; } }
  @media (min-width: 992px) {
    h5, .h5 {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    h5, .h5 {
      font-size: 1.3125rem; } }

h6, .h6 {
  font-weight: 600;
  font-size: 0.875rem; }
  @media (min-width: 480px) {
    h6, .h6 {
      font-size: 1rem; } }
  @media (min-width: 768px) {
    h6, .h6 {
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    h6, .h6 {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    h6, .h6 {
      font-size: 1.125rem; } }

@media (max-width: 479.98px) {
  p, .p {
    margin-bottom: .5rem; } }

ul.p, ol.p {
  padding-left: 1.25em; }

.icon, .slick-slider .slick-prev, .slick-slider .slick-next, .retail-nuances__container::before, .how-it__btn::after, .retail-prices__list > li::before, .lot-carousel .slick-prev, .report-carousel .slick-prev, .lot-carousel .slick-next, .report-carousel .slick-next {
  vertical-align: middle; }
  .icon-search {
    font-size: 1.25rem; }
  .icon-user {
    font-size: .8125rem; }

.btn {
  font-weight: 700;
  text-transform: uppercase; }
  .btn .icon:first-child, .btn .slick-slider .slick-prev:first-child, .slick-slider .btn .slick-prev:first-child, .btn .slick-slider .slick-next:first-child, .slick-slider .btn .slick-next:first-child, .btn .retail-nuances__container:first-child::before, .btn .how-it__btn:first-child::after, .btn .retail-prices__list > li:first-child::before, .btn .lot-carousel .slick-prev:first-child, .lot-carousel .btn .slick-prev:first-child, .btn .report-carousel .slick-prev:first-child, .report-carousel .btn .slick-prev:first-child, .btn .lot-carousel .slick-next:first-child, .lot-carousel .btn .slick-next:first-child, .btn .report-carousel .slick-next:first-child, .report-carousel .btn .slick-next:first-child {
    margin-right: .25rem; }
  .btn .icon:last-child, .btn .slick-slider .slick-prev:last-child, .slick-slider .btn .slick-prev:last-child, .btn .slick-slider .slick-next:last-child, .slick-slider .btn .slick-next:last-child, .btn .retail-nuances__container:last-child::before, .btn .how-it__btn:last-child::after, .btn .retail-prices__list > li:last-child::before, .btn .lot-carousel .slick-prev:last-child, .lot-carousel .btn .slick-prev:last-child, .btn .report-carousel .slick-prev:last-child, .report-carousel .btn .slick-prev:last-child, .btn .lot-carousel .slick-next:last-child, .lot-carousel .btn .slick-next:last-child, .btn .report-carousel .slick-next:last-child, .report-carousel .btn .slick-next:last-child {
    margin-left: .25rem; }
  .btn .icon:only-child, .btn .slick-slider .slick-prev:only-child, .slick-slider .btn .slick-prev:only-child, .btn .slick-slider .slick-next:only-child, .slick-slider .btn .slick-next:only-child, .btn .retail-nuances__container:only-child::before, .btn .how-it__btn:only-child::after, .btn .retail-prices__list > li:only-child::before, .btn .lot-carousel .slick-prev:only-child, .lot-carousel .btn .slick-prev:only-child, .btn .report-carousel .slick-prev:only-child, .report-carousel .btn .slick-prev:only-child, .btn .lot-carousel .slick-next:only-child, .lot-carousel .btn .slick-next:only-child, .btn .report-carousel .slick-next:only-child, .report-carousel .btn .slick-next:only-child {
    margin-left: 0;
    margin-right: 0;
    vertical-align: text-bottom; }

.btn-orange {
  color: #fff;
  background-color: #e55e43;
  border-color: #e55e43;
  box-shadow: none;
  color: #fff !important; }
  .btn-orange:hover {
    color: #212529;
    background-color: #ec8672;
    border-color: #ec8672; }
  .btn-orange:focus, .btn-orange.focus {
    color: #212529;
    background-color: #ec8672;
    border-color: #ec8672;
    box-shadow: 0 0 0 0.2rem rgba(233, 118, 95, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #fff;
    background-color: #e55e43;
    border-color: #e55e43; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #212529;
    background-color: #ec8672;
    border-color: #ec8672; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 118, 95, 0.5); }

.btn-outline-orange {
  color: #e55e43;
  border-color: #e55e43; }
  .btn-outline-orange:hover {
    color: #fff;
    background-color: #e55e43;
    border-color: #e55e43; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 94, 67, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #e55e43;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #fff;
    background-color: #e55e43;
    border-color: #e55e43; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(229, 94, 67, 0.5); }

.btn-black {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #1a1a1a;
  box-shadow: none;
  color: #fff !important; }
  .btn-black:hover {
    color: #fff;
    background-color: #4d4d4d;
    border-color: #4d4d4d; }
  .btn-black:focus, .btn-black.focus {
    color: #fff;
    background-color: #4d4d4d;
    border-color: #4d4d4d;
    box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #fff;
    background-color: #1a1a1a;
    border-color: #1a1a1a; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: #4d4d4d;
    border-color: #4d4d4d; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(60, 60, 60, 0.5); }

.btn-login {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: .9375rem !important;
  line-height: 1.5 !important;
  letter-spacing: .03em;
  text-transform: none; }

.btn-youtube {
  font-size: .75rem;
  font-weight: 600;
  color: #3a3a3a;
  text-transform: none;
  box-shadow: 0.125rem 0.1875rem 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: .4375rem;
  display: inline-flex;
  align-items: center;
  padding: .5625rem .8125rem; }
  @media (min-width: 480px) {
    .btn-youtube {
      font-size: .8125rem;
      padding: .5625rem .8125rem; } }
  @media (min-width: 768px) {
    .btn-youtube {
      font-size: .875rem; } }
  @media (min-width: 992px) {
    .btn-youtube {
      padding-top: .4375rem;
      padding-bottom: .4375rem; } }
  .btn-youtube .icon, .btn-youtube .slick-slider .slick-prev, .slick-slider .btn-youtube .slick-prev, .btn-youtube .slick-slider .slick-next, .slick-slider .btn-youtube .slick-next, .btn-youtube .retail-nuances__container::before, .btn-youtube .how-it__btn::after, .btn-youtube .retail-prices__list > li::before, .btn-youtube .lot-carousel .slick-prev, .lot-carousel .btn-youtube .slick-prev, .btn-youtube .report-carousel .slick-prev, .report-carousel .btn-youtube .slick-prev, .btn-youtube .lot-carousel .slick-next, .lot-carousel .btn-youtube .slick-next, .btn-youtube .report-carousel .slick-next, .report-carousel .btn-youtube .slick-next {
    font-size: 1.375rem;
    color: #fd1919;
    margin-right: .5rem !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .btn-youtube .icon, .btn-youtube .slick-slider .slick-prev, .slick-slider .btn-youtube .slick-prev, .btn-youtube .slick-slider .slick-next, .slick-slider .btn-youtube .slick-next, .btn-youtube .retail-nuances__container::before, .btn-youtube .how-it__btn::after, .btn-youtube .retail-prices__list > li::before, .btn-youtube .lot-carousel .slick-prev, .lot-carousel .btn-youtube .slick-prev, .btn-youtube .report-carousel .slick-prev, .report-carousel .btn-youtube .slick-prev, .btn-youtube .lot-carousel .slick-next, .lot-carousel .btn-youtube .slick-next, .btn-youtube .report-carousel .slick-next, .report-carousel .btn-youtube .slick-next {
        transition: none; } }
    @media (min-width: 480px) {
      .btn-youtube .icon, .btn-youtube .slick-slider .slick-prev, .slick-slider .btn-youtube .slick-prev, .btn-youtube .slick-slider .slick-next, .slick-slider .btn-youtube .slick-next, .btn-youtube .retail-nuances__container::before, .btn-youtube .how-it__btn::after, .btn-youtube .retail-prices__list > li::before, .btn-youtube .lot-carousel .slick-prev, .lot-carousel .btn-youtube .slick-prev, .btn-youtube .report-carousel .slick-prev, .report-carousel .btn-youtube .slick-prev, .btn-youtube .lot-carousel .slick-next, .lot-carousel .btn-youtube .slick-next, .btn-youtube .report-carousel .slick-next, .report-carousel .btn-youtube .slick-next {
        font-size: 1.75rem; } }
  .btn-youtube span {
    text-align: left;
    line-height: 1.2; }
    @media (min-width: 480px) and (max-width: 991.98px) {
      .btn-youtube span br {
        display: none; } }
  .btn-youtube:hover {
    color: #535353; }
    .btn-youtube:hover .icon, .btn-youtube:hover .slick-slider .slick-prev, .slick-slider .btn-youtube:hover .slick-prev, .btn-youtube:hover .slick-slider .slick-next, .slick-slider .btn-youtube:hover .slick-next, .btn-youtube:hover .retail-nuances__container::before, .btn-youtube:hover .how-it__btn::after, .btn-youtube:hover .retail-prices__list > li::before, .btn-youtube:hover .lot-carousel .slick-prev, .lot-carousel .btn-youtube:hover .slick-prev, .btn-youtube:hover .report-carousel .slick-prev, .report-carousel .btn-youtube:hover .slick-prev, .btn-youtube:hover .lot-carousel .slick-next, .lot-carousel .btn-youtube:hover .slick-next, .btn-youtube:hover .report-carousel .slick-next, .report-carousel .btn-youtube:hover .slick-next {
      color: #fd5353; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

.btn-inline {
  display: inline-block;
  width: auto; }

.btn-long {
  padding-left: 3rem;
  padding-right: 3rem; }

@media (min-width: 480px) {
  .btn-sm-block {
    display: block;
    width: 100%; }
    .btn-sm-block + .btn-sm-block {
      margin-top: 0.5rem; }
  .btn-sm-inline {
    display: inline-block;
    width: auto; }
  .btn-sm-long {
    padding-left: 3rem;
    padding-right: 3rem; } }

@media (min-width: 768px) {
  .btn-md-block {
    display: block;
    width: 100%; }
    .btn-md-block + .btn-md-block {
      margin-top: 0.5rem; }
  .btn-md-inline {
    display: inline-block;
    width: auto; }
  .btn-md-long {
    padding-left: 3rem;
    padding-right: 3rem; } }

@media (min-width: 992px) {
  .btn-lg-block {
    display: block;
    width: 100%; }
    .btn-lg-block + .btn-lg-block {
      margin-top: 0.5rem; }
  .btn-lg-inline {
    display: inline-block;
    width: auto; }
  .btn-lg-long {
    padding-left: 3rem;
    padding-right: 3rem; } }

@media (min-width: 1200px) {
  .btn-xl-block {
    display: block;
    width: 100%; }
    .btn-xl-block + .btn-xl-block {
      margin-top: 0.5rem; }
  .btn-xl-inline {
    display: inline-block;
    width: auto; }
  .btn-xl-long {
    padding-left: 3rem;
    padding-right: 3rem; } }

@media (min-width: 1440px) {
  .btn-xxl-block {
    display: block;
    width: 100%; }
    .btn-xxl-block + .btn-xxl-block {
      margin-top: 0.5rem; }
  .btn-xxl-inline {
    display: inline-block;
    width: auto; }
  .btn-xxl-long {
    padding-left: 3rem;
    padding-right: 3rem; } }

.question-btn {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 1.375rem;
  color: #e55e43; }

.form-control:focus::placeholder {
  color: transparent; }

.form-control-gray {
  background-color: #f9f9f9; }

.custom-control-label {
  cursor: pointer; }
  .custom-control-label:hover::before {
    border-color: #ec8672; }

.custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after {
  width: 1.25rem;
  height: 1.25rem;
  top: 0; }

.custom-checkbox .custom-control-label::after {
  background-size: 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e55e43; }

@media (max-width: 479.98px) {
  .custom-radio .custom-control-label::before, .custom-radio .custom-control-label::after {
    width: 1.25rem;
    height: 1.25rem;
    top: .109375rem; }
  .custom-radio .custom-control-label::after {
    background-size: 1rem; } }

textarea.form-control {
  line-height: 1.2; }

.pagination .page-item {
  background-color: #f5f5f5;
  padding: .25rem;
  font-weight: 500; }
  .pagination .page-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem; }
  .pagination .page-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem; }
  .pagination .page-item.active {
    font-weight: 400; }
  .pagination .page-item .page-link {
    line-height: 1.4;
    border-radius: .1875rem;
    padding: .125rem .5rem;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .pagination .page-item .page-link {
        transition: none; } }
  .pagination .page-item .icon, .pagination .page-item .slick-slider .slick-prev, .slick-slider .pagination .page-item .slick-prev, .pagination .page-item .slick-slider .slick-next, .slick-slider .pagination .page-item .slick-next, .pagination .page-item .retail-nuances__container::before, .pagination .page-item .how-it__btn::after, .pagination .page-item .retail-prices__list > li::before, .pagination .page-item .lot-carousel .slick-prev, .lot-carousel .pagination .page-item .slick-prev, .pagination .page-item .report-carousel .slick-prev, .report-carousel .pagination .page-item .slick-prev, .pagination .page-item .lot-carousel .slick-next, .lot-carousel .pagination .page-item .slick-next, .pagination .page-item .report-carousel .slick-next, .report-carousel .pagination .page-item .slick-next {
    font-size: .75rem; }

.pagination .page-next .page-link, .pagination .page-prev .page-link {
  padding-left: .375rem;
  padding-right: .375rem; }
  .pagination .page-next .page-link:hover, .pagination .page-prev .page-link:hover {
    background-color: #f47e66;
    color: #fff; }

.slick-slider .slick-prev, .slick-slider .slick-next, .slick-dots button {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  display: block;
  font-size: 0;
  line-height: 0;
  cursor: pointer; }

.slick-carousel:not(.slick-slider) {
  display: none; }

.slick-slider_chevrons .slick-prev, .slick-slider_chevrons .slick-next {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .slick-slider_chevrons .slick-prev, .slick-slider_chevrons .slick-next {
      transition: none; } }
  .slick-slider_chevrons .slick-prev:hover, .slick-slider_chevrons .slick-next:hover {
    color: #fff;
    background-color: #555; }

.slick-slider .slick-slide img {
  /* width: 0 !important; */
  height: 0 !important;
  display: none !important; }

.slick-slider .slick-current img, .slick-slider .slick-active img {
  /* width: 100% !important;  */
  height: auto !important;
  display: block !important; }

.slick-slider .slick-prev::before, .slick-slider .slick-next::before {
  line-height: 1;
  font-size: 1.1875rem;
  vertical-align: middle; }

.slick-slider .slick-prev {
  padding-right: 3px; }

.slick-slider .slick-next {
  padding-left: 3px; }

.slick-slider--bottom-arrows {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .slick-slider--bottom-arrows .slick-list {
    width: 100%;
    order: -1; }
  .slick-slider--bottom-arrows .slick-next {
    order: 10; }

.slick-dots {
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding: 0.5rem 20px; }
  @media (min-width: 992px) {
    .slick-dots {
      padding: 10px 20px; } }
  .slick-dots > li {
    margin: 0 2.5px; }
  .slick-dots button {
    border-style: solid;
    border-color: transparent;
    border-width: 10px 0; }
    .slick-dots button::before {
      content: '';
      display: block;
      background-color: #c4c4c4;
      height: 3px;
      transition: background-color 0.3s ease-in-out;
      width: 1.25rem;
      margin: 0 0.3125rem; }
      @media (prefers-reduced-motion: reduce) {
        .slick-dots button::before {
          transition: none; } }
      @media (min-width: 480px) {
        .slick-dots button::before {
          width: 1.875rem; } }
      @media (min-width: 992px) {
        .slick-dots button::before {
          margin: 0 0.5rem; } }
  .slick-dots .slick-active button::before {
    background-color: #000; }

.pretty-title {
  display: inline-flex;
  margin-left: -.625rem;
  color: #fff;
  box-shadow: 0.125rem 0.125rem 0.625rem rgba(0, 0, 0, 0.2);
  border-radius: .3125rem .3125rem .3125rem 0;
  position: relative;
  font-size: 1rem;
  line-height: 1.3;
  padding: 0.3125rem 1.875rem 0.3125rem 0.625rem; }
  @media (min-width: 480px) {
    .pretty-title {
      font-size: 1.125rem; } }
  @media (min-width: 480px) {
    .pretty-title {
      line-height: 1.35; } }
  @media (min-width: 480px) {
    .pretty-title {
      padding: 0.5rem 1.875rem 0.5rem 0.625rem; } }
  .pretty-title::before {
    content: '';
    display: block;
    width: .625rem; }
  .pretty-title::after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 0;
    height: 0;
    border-bottom: .3125rem solid transparent;
    border-right: .625rem solid transparent; }
  .pretty-title--green {
    background-image: linear-gradient(90deg, #a4e353 15.1%, #7dd65d 100%); }
    .pretty-title--green::after {
      border-right-color: #80b143; }
  .pretty-title--orange {
    background-image: linear-gradient(90deg, #fc7b52 0, #e55e43 100%); }
    .pretty-title--orange::after {
      border-right-color: #d56846; }
  .pretty-title--purple {
    background-image: linear-gradient(90deg, #7a87fe 0, #9160f9 100%); }
    .pretty-title--purple::after {
      border-right-color: #5659a4; }

@media (min-width: 480px) {
  .modal-dialog {
    max-width: 400px; } }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 580px; } }

.modal__close {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  padding: .625rem;
  font-size: .9375rem;
  line-height: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; }
  @media (max-width: 767.98px) {
    .modal__close {
      color: #9a9a9a;
      transition: color 0.15s ease-in-out; } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .modal__close {
      transition: none; } }
  @media (max-width: 767.98px) {
      .modal__close:hover {
        color: #1a1a1a; } }
  @media (min-width: 768px) {
    .modal__close {
      color: #fff;
      background-color: #9a9a9a;
      transition: background-color 0.15s ease-in-out; } }
  @media (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .modal__close {
      transition: none; } }
  @media (min-width: 768px) {
      .modal__close:hover {
        background-color: #ff6565; } }

.modal__body {
  padding: 2.25rem 15px; }
  @media (min-width: 480px) {
    .modal__body {
      padding: 3.125rem 1.25rem; } }
  @media (min-width: 768px) {
    .modal__body {
      padding: 2.875rem 3.75rem; } }
  @media (min-width: 992px) {
    .modal__body {
      padding: 3.75rem; } }

.modal__title {
  line-height: 1.4;
  font-size: 1.125rem; }
  @media (min-width: 768px) {
    .modal__title {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .modal__title {
      font-size: 2.25rem; } }

.callback-success__icon {
  color: #abe576;
  margin-bottom: 1rem;
  font-size: 3.75rem; }
  @media (min-width: 768px) {
    .callback-success__icon {
      font-size: 6.25rem; } }

.callback-success__message {
  color: #9a9a9a;
  font-size: 1.125rem; }
  @media (min-width: 480px) {
    .callback-success__message {
      font-size: 1rem; } }
  @media (min-width: 768px) {
    .callback-success__message {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    .callback-success__message {
      font-size: 1.3125rem; } }

.selection-success {
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .selection-success {
      padding-left: 30px; } }
  @media (min-width: 768px) {
    .selection-success {
      padding-right: 30px; } }
  .selection-success__title {
    font-size: 1.125rem; }
    @media (min-width: 768px) {
      .selection-success__title {
        font-size: 1.5rem; } }
  .selection-success__message {
    color: #9a9a9a;
    font-size: 1.125rem;
    margin-bottom: 1.25rem; }
  .selection-success__image {
    margin: 0 -15px; }
    @media (min-width: 768px) {
      .selection-success__image {
        margin: 0 -10px; } }
    .selection-success__image img {
      width: 100%;
      height: auto;
      display: block; }

.header {
  box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5; }
  @media (min-width: 1200px) {
    .header {
      position: sticky;
      top: -8.125rem;
      z-index: 100;
      transition: top 0.4s ease-in-out; } }
  @media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
    .header {
      transition: none; } }
  @media (min-width: 1200px) {
      .header--shown {
        top: 0; } }
  @media (min-width: 1200px) {
    .header__top {
      position: relative;
      z-index: 1; } }
  .header__top-inner {
    height: 3.125rem;
    background-color: #1a1a1a;
    color: #fff; }
    @media (min-width: 1200px) {
      .header__top-inner {
        height: 4.375rem; } }
  .header__logo {
    font-size: 1.125rem;
    color: #fff !important;
    padding: 10px; }
    @media (min-width: 1200px) {
      .header__logo {
        padding: 15px; } }
    .header__logo svg {
      width: 80px;
      height: 24px; }
      @media (min-width: 1200px) {
        .header__logo svg {
          width: 100px;
          height: 30px; } }

@media (max-width: 1199.98px) {
  .slide-panel {
    position: absolute;
    top: 3.125rem;
    left: 0;
    width: 0;
    height: calc(100vh - 3.125rem);
    overflow-x: hidden;
    overflow-y: auto;
    transition: width 0s .3s; }
    .slide-panel::-webkit-scrollbar {
      width: 0 !important; }
    .slide-panel {
      overflow: -moz-scrollbars-none; }
    .slide-panel {
      -ms-overflow-style: none; }
    .slide-panel--right {
      left: auto;
      right: 0; }
    .slide-panel::before {
      content: '';
      position: fixed;
      top: 3.125rem;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s .3s, opacity .3s ease-in-out; }
    .slide-panel.opened, .slide-panel:focus-within {
      width: 100%;
      transition: width 0s; }
      .slide-panel.opened::before, .slide-panel:focus-within::before {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s, opacity .3s ease-in-out; }
    .slide-panel .slide-panel__inner {
      background-color: #fff;
      min-height: 100%;
      position: relative;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin-left: 0;
      width: 100vw;
      max-width: 20rem;
      transform: translateX(-100%);
      transition: transform .3s ease-in-out; }
      .slide-panel.opened .slide-panel__inner, .slide-panel .slide-panel__inner:focus-within {
        transform: translateX(0); }
    .slide-panel--right .slide-panel__inner {
      margin-right: 0;
      margin-left: auto;
      transform: translateX(100%); }
    .slide-panel--right.opened .slide-panel__inner,
    .slide-panel--right .slide-panel__inner:focus-within {
      transform: translateX(0); } }

.menu-toggle {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  padding: 10px;
  margin-left: -10px;
  color: #fff; }
  @media (min-width: 1200px) {
    .menu-toggle {
      display: none; } }
  .menu-toggle--back {
    color: #1a1a1a; }
  .menu-toggle__hamburger {
    position: relative;
    width: 1.625rem;
    height: 1.625rem;
    display: block; }
  .menu-toggle__bar {
    top: 50%;
    margin-top: -1px; }
    .menu-toggle__bar, .menu-toggle__bar::before, .menu-toggle__bar::after {
      background-color: #fff;
      height: 2px;
      position: absolute;
      left: 0;
      right: 0;
      content: '';
      transition: all .3s ease-in-out; }
    .menu-toggle__bar::before {
      top: -.5rem; }
    .menu-toggle__bar::after {
      bottom: -.5rem; }
  .menu-toggle.opened .menu-toggle__bar {
    background-color: transparent; }
    .menu-toggle.opened .menu-toggle__bar::before, .menu-toggle.opened .menu-toggle__bar::after {
      left: -2px;
      right: -2px; }
    .menu-toggle.opened .menu-toggle__bar::before {
      transform: rotate(45deg);
      top: 0; }
    .menu-toggle.opened .menu-toggle__bar::after {
      transform: rotate(-45deg);
      bottom: 0; }

.top-menu {
  /*&__back {
    @include header-size;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }*/ }
  @media (min-width: 1200px) {
    .top-menu {
      align-self: stretch;
      display: flex;
      flex-grow: 1; } }
  @media (min-width: 1200px) {
    .top-menu__container {
      padding: 0;
      display: flex; } }
  .top-menu__list {
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    list-style: none;
    padding: 0; }
    @media (min-width: 1200px) {
      .top-menu__list {
        font-size: .9375rem;
        text-transform: none;
        letter-spacing: .03em;
        display: flex;
        margin: 0 auto; } }
    @media (min-width: 1200px) {
      .top-menu__list-item {
        display: inline-flex;
        align-items: center;
        padding: 0 .75rem;
        position: relative; }
        .top-menu__list-item-wrapper:hover {
          align-self: stretch;
          display: flex;
          align-items: center;
          margin: 0 -.75rem;
          padding: 0 .75rem; }
        .top-menu__list .top-menu__list .top-menu__list-item {
          display: block;
          padding: 0 1rem; } }
    .top-menu__list .top-menu__list {
      margin: 0;
      letter-spacing: 0;
      backface-visibility: hidden; }
      @media (min-width: 1200px) {
        .top-menu__list .top-menu__list {
          font-weight: 400;
          position: absolute;
          top: 100%;
          left: -.25rem;
          background: #fff;
          z-index: -1;
          display: block;
          box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
          padding: .75rem 0;
          transform: translateY(-50%);
          opacity: 0;
          visibility: hidden;
          transition: transform .5s .3s ease-in-out, opacity .5s .3s ease-in-out, visibility .8s 0s; } }
    @media (min-width: 1200px) {
      .top-menu__list-item-wrapper:hover .top-menu__list,
      .top-menu__list-item-wrapper:focus-within .top-menu__list {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: transform .3s ease-in-out, opacity .3s ease-in-out, visibility 0s; } }
  .top-menu__link {
    text-decoration: none;
    display: inline-block;
    color: #1a1a1a; }
    .top-menu__link:hover {
      text-decoration: none;
      color: #e55e43; }
    @media (max-width: 1199.98px) {
      .top-menu__link {
        padding: .25rem .5rem;
        margin: .25rem -.5rem; }
        .top-menu__link:not(:last-child) {
          display: none; } }
    @media (min-width: 1200px) {
      .top-menu__link {
        color: #fff; }
        .top-menu__link:hover {
          color: #e55e43; }
        .top-menu__link:not(:last-child)::after {
          content: '';
          display: inline-block;
          position: relative;
          margin-left: .5rem;
          top: -.125rem;
          width: .5625rem;
          height: .5625rem;
          border: solid #fff;
          border-width: 0 0 .125rem .125rem;
          transform: rotate(-45deg);
          transition: border-color .15s ease-in-out; }
        .top-menu__link:not(:last-child):hover::after {
          border-color: #e55e43; }
        .top-menu__list .top-menu__list .top-menu__link {
          color: #1a1a1a;
          margin: .125rem 0;
          white-space: nowrap; }
          .top-menu__list .top-menu__list .top-menu__link:hover {
            text-decoration: none;
            color: #e55e43; } }
  .top-menu .social-menu a {
    color: #1a1a1a; }
  @media (min-width: 1200px) {
    .top-menu .social-menu {
      display: none; } }
  .top-menu__phone {
    display: none; }
    @media (min-width: 1200px) {
      .top-menu__phone {
        display: block;
        align-self: center;
        font-size: 1.0625rem; }
        .top-menu__phone:not(:last-child) {
          margin-right: auto; } }
    .top-menu__phone a {
      text-decoration: none; }
  @media (max-width: 1199.98px) {
    .top-menu .btn-login {
      display: block;
      width: 100%;
      padding: 0.75rem 1rem;
      font-size: 0.875rem;
      line-height: 1.6;
      border-radius: 0.3rem; } }
  @media (min-width: 1200px) {
    .top-menu .btn-login {
      align-self: center;
      line-height: 1.3 !important; } }

.search-toggle {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  padding: 10px;
  color: #fff;
  margin-right: -10px; }
  @media (min-width: 1200px) {
    .search-toggle {
      display: none; } }
  .search-toggle__icon {
    position: relative;
    width: 1.625rem;
    height: 1.625rem;
    display: block; }
  .search-toggle__bar {
    width: 75%;
    height: 75%;
    border: 2px solid #fff;
    border-radius: 50%;
    display: block;
    transition: all .3s ease-in-out, border .3s ease-in-out 0s; }
    .search-toggle__bar::before, .search-toggle__bar::after {
      background-color: #fff;
      height: 2px;
      position: absolute;
      left: 0;
      right: 0;
      content: '';
      transition: all .3s ease-in-out;
      left: auto; }
    .search-toggle__bar::before {
      width: 0;
      right: .1875rem;
      top: .1875rem;
      transform: rotate(-45deg); }
    .search-toggle__bar::after {
      right: -.0625rem;
      bottom: .25rem;
      width: .8125rem;
      transform: rotate(45deg); }
  .search-toggle.opened .search-toggle__bar {
    width: 0;
    height: 0;
    border-color: transparent;
    transition: all .3s ease-in-out, border .1s ease-in-out .2s; }
    .search-toggle.opened .search-toggle__bar::before {
      width: 1.75rem;
      right: -.0625rem;
      top: .75rem; }
    .search-toggle.opened .search-toggle__bar::after {
      width: 1.75rem;
      right: -.0625rem;
      bottom: .75rem; }

@media (min-width: 1200px) {
  .top-search {
    background-color: #f9f9f9;
    height: 3.75rem; } }

@media (max-width: 1199.98px) {
  .top-search__container {
    background-color: #f9f9f9 !important;
    max-width: none !important; } }

@media (min-width: 1200px) {
  .top-search__container {
    padding-top: .125rem;
    padding-bottom: .125rem; } }

.top-search__radio {
  flex-wrap: wrap; }
  .top-search__radio-label {
    white-space: nowrap;
    font-weight: 700;
    font-size: 1.125rem; }
    @media (min-width: 1200px) {
      .top-search__radio-label {
        font-size: .875rem;
        font-weight: 500; }
        .top-search__radio-label:after, .top-search__radio-label:before {
          top: .0625rem; } }

.top-search__radio-tooltip {
  line-height: 1.3;
  color: #9a9a9a;
  width: 100%;
  margin-left: -1.5625rem;
  padding-top: .25rem;
  font-size: 0.875rem; }
  @media (min-width: 480px) {
    .top-search__radio-tooltip {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    .top-search__radio-tooltip {
      font-size: 0.875rem; } }
  @media (min-width: 1200px) {
    .top-search__radio-tooltip {
      position: absolute;
      background-color: #fff;
      color: #9a9a9a !important;
      padding: .75rem 1rem;
      margin-left: 0;
      border-radius: 0.3125rem;
      box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
      width: min-content;
      min-width: 300px;
      max-width: 400px;
      top: 100%;
      left: 0;
      margin-top: 50px;
      pointer-events: none;
      opacity: 0;
      transition: opacity .3s ease-in-out, margin-top .3s ease-in-out; }
      .top-search__radio-tooltip::after {
        content: '';
        position: absolute;
        top: -9px;
        left: 50px;
        width: 18px;
        height: 18px;
        background-color: #fff;
        transform: perspective(10px) rotateX(-10deg) rotateZ(45deg); } }

@media (min-width: 1200px) {
  .top-search__radio-label:hover ~ .top-search__radio-tooltip,
  .top-search__radio input:focus ~ .top-search__radio-tooltip {
    opacity: 1;
    margin-top: 20px; } }

@media (min-width: 1200px) {
  .top-search__radio-label:hover ~ .top-search__radio-tooltip {
    z-index: 1; } }

.top-search__radio input:checked ~ .top-search__radio-tooltip {
  color: #1a1a1a; }

.social-menu a {
  display: inline-block;
  text-decoration: none;
  padding: 10px; }
  .social-menu a:hover {
    color: #e55e43; }

.social-menu .icon, .social-menu .slick-slider .slick-prev, .slick-slider .social-menu .slick-prev, .social-menu .slick-slider .slick-next, .slick-slider .social-menu .slick-next, .social-menu .retail-nuances__container::before, .social-menu .how-it__btn::after, .social-menu .retail-prices__list > li::before, .social-menu .lot-carousel .slick-prev, .lot-carousel .social-menu .slick-prev, .social-menu .report-carousel .slick-prev, .report-carousel .social-menu .slick-prev, .social-menu .lot-carousel .slick-next, .lot-carousel .social-menu .slick-next, .social-menu .report-carousel .slick-next, .report-carousel .social-menu .slick-next {
  font-size: 1.875rem;
  vertical-align: middle; }

.footer {
  background-color: #1a1a1a;
  color: #fff;
  line-height: 1.8;
  font-size: .75rem; }
  @media (min-width: 480px) {
    .footer {
      font-size: 1rem; } }
  @media (min-width: 992px) {
    .footer {
      font-size: .9375rem; } }
  .footer a {
    color: #fff;
    text-decoration: none !important; }
    .footer a:hover {
      color: #e55e43; }
  .footer__logo {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700; }
    @media (min-width: 480px) {
      .footer__logo {
        font-size: 1.3125rem; } }
    @media (min-width: 768px) {
      .footer__logo {
        font-size: 1.625rem; } }
    @media (min-width: 992px) {
      .footer__logo {
        font-size: 1.25rem; } }
  .footer .social-menu {
    margin-left: -5px;
    margin-right: -5px; }
    .footer .social-menu .list-inline-item:not(:last-child) {
      margin-right: 1.5rem; }
      @media (min-width: 992px) {
        .footer .social-menu .list-inline-item:not(:last-child) {
          margin-right: 13px; } }
    .footer .social-menu a {
      padding: 5px; }
    .footer .social-menu .icon, .footer .social-menu .slick-slider .slick-prev, .slick-slider .footer .social-menu .slick-prev, .footer .social-menu .slick-slider .slick-next, .slick-slider .footer .social-menu .slick-next, .footer .social-menu .retail-nuances__container::before, .footer .social-menu .how-it__btn::after, .footer .social-menu .retail-prices__list > li::before, .footer .social-menu .lot-carousel .slick-prev, .lot-carousel .footer .social-menu .slick-prev, .footer .social-menu .report-carousel .slick-prev, .report-carousel .footer .social-menu .slick-prev, .footer .social-menu .lot-carousel .slick-next, .lot-carousel .footer .social-menu .slick-next, .footer .social-menu .report-carousel .slick-next, .report-carousel .footer .social-menu .slick-next {
      font-size: 1.25rem; }
      @media (min-width: 480px) and (max-width: 991.98px) {
        .footer .social-menu .icon, .footer .social-menu .slick-slider .slick-prev, .slick-slider .footer .social-menu .slick-prev, .footer .social-menu .slick-slider .slick-next, .slick-slider .footer .social-menu .slick-next, .footer .social-menu .retail-nuances__container::before, .footer .social-menu .how-it__btn::after, .footer .social-menu .retail-prices__list > li::before, .footer .social-menu .lot-carousel .slick-prev, .lot-carousel .footer .social-menu .slick-prev, .footer .social-menu .report-carousel .slick-prev, .report-carousel .footer .social-menu .slick-prev, .footer .social-menu .lot-carousel .slick-next, .lot-carousel .footer .social-menu .slick-next, .footer .social-menu .report-carousel .slick-next, .report-carousel .footer .social-menu .slick-next {
          font-size: 1.875rem; } }
  .footer__copyright {
    color: #9a9a9a;
    font-size: .8125rem; }
    @media (min-width: 480px) and (max-width: 991.98px) {
      .footer__copyright {
        font-size: .875rem; } }

.address-block .icon, .address-block .slick-slider .slick-prev, .slick-slider .address-block .slick-prev, .address-block .slick-slider .slick-next, .slick-slider .address-block .slick-next, .address-block .retail-nuances__container::before, .address-block .how-it__btn::after, .address-block .retail-prices__list > li::before, .address-block .lot-carousel .slick-prev, .lot-carousel .address-block .slick-prev, .address-block .report-carousel .slick-prev, .report-carousel .address-block .slick-prev, .address-block .lot-carousel .slick-next, .lot-carousel .address-block .slick-next, .address-block .report-carousel .slick-next, .report-carousel .address-block .slick-next {
  font-size: 1.125rem; }
  @media (min-width: 992px) {
    .address-block .icon, .address-block .slick-slider .slick-prev, .slick-slider .address-block .slick-prev, .address-block .slick-slider .slick-next, .slick-slider .address-block .slick-next, .address-block .retail-nuances__container::before, .address-block .how-it__btn::after, .address-block .retail-prices__list > li::before, .address-block .lot-carousel .slick-prev, .lot-carousel .address-block .slick-prev, .address-block .report-carousel .slick-prev, .report-carousel .address-block .slick-prev, .address-block .lot-carousel .slick-next, .lot-carousel .address-block .slick-next, .address-block .report-carousel .slick-next, .report-carousel .address-block .slick-next {
      font-size: 1.25rem; } }

.address-block__phone {
  font-weight: bold; }
  @media (min-width: 480px) {
    .address-block__phone {
      font-size: 1.0625rem; } }
  @media (min-width: 992px) {
    .address-block__phone {
      font-size: 1.125rem;
      font-weight: 500; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .intro {
    padding-top: 50px; } }

.intro__image {
  max-width: 130%;
  height: auto; }
  @media (min-width: 768px) {
    .intro__image {
      max-width: none;
      width: 100%; } }
  @media (min-width: 992px) {
    .intro__image {
      width: auto;
      max-width: 111.11111%;
      margin-left: 20%; } }
  @media (min-width: 1200px) {
    .intro__image {
      max-width: 125.18519%; } }

.intro__description {
  font-size: .875rem; }
  @media (min-width: 480px) {
    .intro__description {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    .intro__description {
      margin-right: -30px; } }

.intro__title {
  max-width: 280px; }
  @media (min-width: 480px) {
    .intro__title {
      max-width: 400px; } }
  @media (min-width: 768px) {
    .intro__title {
      max-width: 550px; } }
  @media (min-width: 992px) {
    .intro__title {
      max-width: none;
      margin-right: -30px; } }

@media (min-width: 480px) and (max-width: 767.98px) {
  .intro .btn {
    font-size: .8125rem;
    padding: .8125rem .25rem; } }

@media (min-width: 1200px) {
  .intro__content {
    flex: 0 0 50%;
    max-width: 50%;
    padding-top: 40px; } }

@media (min-width: 1200px) {
  .intro__container {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (max-width: 767.98px) {
  .intro .intro__buttons,
  .intro .row .intro__buttons-col {
    padding-right: 10px;
    padding-left: 10px; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .intro__button {
    font-size: 13px;
    padding: 13px 10px; } }

.privilege .icon, .privilege .slick-slider .slick-prev, .slick-slider .privilege .slick-prev, .privilege .slick-slider .slick-next, .slick-slider .privilege .slick-next, .privilege .retail-nuances__container::before, .privilege .how-it__btn::after, .privilege .retail-prices__list > li::before, .privilege .lot-carousel .slick-prev, .lot-carousel .privilege .slick-prev, .privilege .report-carousel .slick-prev, .report-carousel .privilege .slick-prev, .privilege .lot-carousel .slick-next, .lot-carousel .privilege .slick-next, .privilege .report-carousel .slick-next, .report-carousel .privilege .slick-next {
  font-size: 2.5rem;
  margin-right: 1rem;
  color: #e55e43; }
  @media (min-width: 480px) {
    .privilege .icon, .privilege .slick-slider .slick-prev, .slick-slider .privilege .slick-prev, .privilege .slick-slider .slick-next, .slick-slider .privilege .slick-next, .privilege .retail-nuances__container::before, .privilege .how-it__btn::after, .privilege .retail-prices__list > li::before, .privilege .lot-carousel .slick-prev, .lot-carousel .privilege .slick-prev, .privilege .report-carousel .slick-prev, .report-carousel .privilege .slick-prev, .privilege .lot-carousel .slick-next, .lot-carousel .privilege .slick-next, .privilege .report-carousel .slick-next, .report-carousel .privilege .slick-next {
      font-size: 3.75rem; } }
  @media (min-width: 768px) {
    .privilege .icon, .privilege .slick-slider .slick-prev, .slick-slider .privilege .slick-prev, .privilege .slick-slider .slick-next, .slick-slider .privilege .slick-next, .privilege .retail-nuances__container::before, .privilege .how-it__btn::after, .privilege .retail-prices__list > li::before, .privilege .lot-carousel .slick-prev, .lot-carousel .privilege .slick-prev, .privilege .report-carousel .slick-prev, .report-carousel .privilege .slick-prev, .privilege .lot-carousel .slick-next, .lot-carousel .privilege .slick-next, .privilege .report-carousel .slick-next, .report-carousel .privilege .slick-next {
      font-size: 3.125rem; } }
  @media (min-width: 992px) {
    .privilege .icon, .privilege .slick-slider .slick-prev, .slick-slider .privilege .slick-prev, .privilege .slick-slider .slick-next, .slick-slider .privilege .slick-next, .privilege .retail-nuances__container::before, .privilege .how-it__btn::after, .privilege .retail-prices__list > li::before, .privilege .lot-carousel .slick-prev, .lot-carousel .privilege .slick-prev, .privilege .report-carousel .slick-prev, .report-carousel .privilege .slick-prev, .privilege .lot-carousel .slick-next, .lot-carousel .privilege .slick-next, .privilege .report-carousel .slick-next, .report-carousel .privilege .slick-next {
      font-size: 4.0625rem;
      margin-right: 0;
      margin-bottom: .5rem; } }

.privilege__item {
  font-size: .875rem; }
  @media (min-width: 480px) {
    .privilege__item {
      font-size: 1.3125rem; } }
  @media (min-width: 768px) {
    .privilege__item {
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    .privilege__item {
      font-size: .9375rem; } }
  @media (min-width: 1200px) {
    .privilege__item {
      font-size: 1rem; } }

.privilege__title {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  line-height: 1.3;
  display: block; }
  @media (min-width: 480px) {
    .privilege__title {
      font-size: 1.5rem;
      width: 50%; } }
  @media (min-width: 768px) {
    .privilege__title {
      font-size: 1.3125rem;
      width: 68%; } }
  @media (min-width: 992px) {
    .privilege__title {
      font-size: 1.125rem;
      width: 100%; } }
  @media (min-width: 1200px) {
    .privilege__title {
      font-size: 1.3125rem; } }

@media (max-width: 991.98px) {
  .retail-main {
    overflow: hidden; } }

@media (min-width: 992px) {
  .retail-main {
    position: relative; }
    .retail-main::after {
      content: '';
      position: absolute;
      background-color: #f9f9f9;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      right: calc( (100vw - 970px) / 2 + 970px * 0.25); } }

@media (min-width: 1200px) {
  .retail-main::after {
    right: calc( (100vw - 1140px) / 2 + 1140px * 0.25); }
  .retail-main > [class*="container-"] {
    padding-top: 2rem; } }

.retail-carousel {
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: .75rem; }
  .retail-carousel .carousel-inner {
    padding-right: 10px;
    padding-left: 10px; }
    @media (min-width: 992px) {
      .retail-carousel .carousel-inner {
        overflow: visible;
        padding: 0; } }
  @media (min-width: 992px) {
    .retail-carousel {
      margin: .5rem 0 0; }
      .retail-carousel .carousel-item {
        opacity: 0;
        transition-property: opacity;
        transform: none; }
      .retail-carousel .carousel-item.active,
      .retail-carousel .carousel-item-next.carousel-item-left,
      .retail-carousel .carousel-item-prev.carousel-item-right {
        z-index: 1;
        opacity: 1; }
      .retail-carousel .active.carousel-item-left,
      .retail-carousel .active.carousel-item-right {
        z-index: 0;
        opacity: 0; } }
  @media (min-width: 1200px) {
    .retail-carousel {
      left: 2.5rem; } }
  @media (max-width: 991.98px) {
    .retail-carousel::after {
      content: '';
      position: absolute;
      background-color: #f9f9f9;
      top: calc(50% - 1.25rem);
      left: 0;
      right: 0;
      height: 1000%;
      z-index: -1; } }
  @media (min-width: 480px) {
    .retail-carousel::after {
      top: calc(50% - 1.5rem); } }
  .retail-carousel .carousel-indicators {
    position: static;
    margin: 0;
    padding: .5rem 20px; }
    @media (min-width: 480px) {
      .retail-carousel .carousel-indicators {
        padding: 1rem 0 .75rem; } }
    .retail-carousel .carousel-indicators li {
      width: 1.25rem;
      margin: 0 7.5px;
      background-color: #c4c4c4;
      opacity: 1;
      transition: background-color .3s ease-in-out; }
      @media (min-width: 480px) {
        .retail-carousel .carousel-indicators li {
          width: 1.875rem;
          margin: 0 5px; } }
      @media (min-width: 480px) {
        .retail-carousel .carousel-indicators li {
          margin: 0 10px; } }
      @media (min-width: 992px) {
        .retail-carousel .carousel-indicators li {
          margin: 0 .5rem; } }
      .retail-carousel .carousel-indicators li.active {
        background-color: #000; }
  @media (min-width: 992px) {
    .retail-carousel .carousel-indicators {
      position: absolute;
      padding: 0;
      top: 0;
      left: 0;
      right: auto;
      bottom: auto;
      flex-direction: column;
      align-items: flex-start; } }
  @media (min-width: 992px) {
    .retail-carousel .carousel-indicators li {
      width: auto;
      margin: 0 0 .25rem 2.5rem;
      background-color: transparent !important;
      transition: color .3s ease-in-out;
      height: auto;
      text-indent: 0;
      border: none;
      position: relative;
      font-size: .875rem;
      color: #9a9a9a; }
      .retail-carousel .carousel-indicators li::before {
        content: '';
        position: absolute;
        left: -2.5rem;
        top: 50%;
        width: 1.875rem;
        height: 1px;
        transition: background-color .3s ease-in-out; }
      .retail-carousel .carousel-indicators li:hover {
        color: #1a1a1a;
        font-weight: 500; } }
  @media (min-width: 1200px) {
    .retail-carousel .carousel-indicators li {
      font-size: 1rem; } }
  @media (min-width: 992px) {
    .retail-carousel .carousel-indicators li.active {
      font-weight: 500;
      color: #1a1a1a; }
      .retail-carousel .carousel-indicators li.active::before {
        background-color: #e55e43; } }
  .retail-carousel__item {
    display: flex;
    flex-direction: column;
    background-color: #dfdfdf;
    margin: 0 10px; }
    @media (min-width: 992px) {
      .retail-carousel__item {
        margin: 0;
        background-color: transparent; } }
    @media (min-width: 1200px) {
      .retail-carousel__item {
        margin-top: -2.5rem; } }
  @media (min-width: 992px) {
    .retail-carousel__image {
      position: relative; } }
  .retail-carousel__image img {
    width: 100%; }
    @media (min-width: 992px) {
      .retail-carousel__image img {
        box-shadow: 0 0.9375rem 1.875rem rgba(0, 0, 0, 0.2); } }
  @media (min-width: 992px) {
    .retail-carousel__image--before {
      margin-left: 210px; } }
  @media (min-width: 1200px) {
    .retail-carousel__image--before {
      margin-left: 12rem;
      left: 2.5rem; } }
  @media (min-width: 992px) {
    .retail-carousel__image--after {
      left: 4rem;
      margin-right: 210px;
      margin-top: -10px; } }
  @media (min-width: 1200px) {
    .retail-carousel__image--after {
      margin-right: 12rem;
      left: 2.5rem; } }
  .retail-carousel__caption {
    font-weight: 500;
    font-size: .875rem;
    padding: 5px 0;
    text-align: center;
    text-transform: uppercase; }
    @media (min-width: 480px) {
      .retail-carousel__caption {
        font-size: 1rem;
        padding: 8px 0; } }
    @media (min-width: 768px) {
      .retail-carousel__caption {
        font-size: 1.3125rem;
        font-weight: 700; } }
    @media (min-width: 992px) {
      .retail-carousel__caption {
        display: none; } }

@media (min-width: 480px) {
  .retail-main__more {
    width: 210px; } }

@media (min-width: 1200px) {
  .retail-main__more {
    width: 160px; } }

.nearest-lots__item {
  line-height: 1.6; }
  @media (max-width: 479.98px) {
    .nearest-lots__item {
      padding-top: .75rem;
      padding-bottom: .75rem; }
      .nearest-lots__item:nth-child(odd) {
        background-color: #f9f9f9; } }
  @media (min-width: 480px) {
    .nearest-lots__item {
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex; } }
  @media (max-width: 991.98px) {
    .nearest-lots__item:not(:nth-child(-n + 6)) {
      display: none; } }

.nearest-lots__link {
  color: #1a1a1a !important;
  text-decoration: none !important;
  font-size: .875rem; }
  @media (min-width: 480px) {
    .nearest-lots__link {
      font-size: 1.125rem;
      box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.1);
      flex-grow: 1; } }
  @media (min-width: 768px) {
    .nearest-lots__link {
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    .nearest-lots__link {
      font-size: .9375rem; } }
  @media (min-width: 1200px) {
    .nearest-lots__link {
      font-size: 1.0625rem; } }

@media (max-width: 479.98px) {
  .nearest-lots__image {
    width: 50%;
    padding-right: 10px; } }

@media (min-width: 480px) {
  .nearest-lots__image {
    transition: opacity .3s ease-in-out; } }

@media (min-width: 480px) {
  .nearest-lots__link:hover .nearest-lots__image {
    opacity: .5; } }

.nearest-lots__description {
  display: flex;
  flex-direction: column; }
  @media (max-width: 479.98px) {
    .nearest-lots__description {
      width: 50%;
      padding-left: 10px; } }
  @media (min-width: 480px) {
    .nearest-lots__description {
      position: relative;
      padding: .75rem 1rem;
      flex-grow: 1; } }

.nearest-lots__title {
  margin-bottom: auto;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: .8125rem;
  text-transform: uppercase;
  transition: color .3s ease-in-out; }
  @media (min-width: 480px) {
    .nearest-lots__title {
      display: block;
      font-weight: 700;
      font-size: inherit; } }
  @media (min-width: 992px) {
    .nearest-lots__title {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    .nearest-lots__title {
      font-size: inherit; } }

@media (min-width: 480px) {
  .nearest-lots__link:hover .nearest-lots__title {
    color: #e55e43; } }

.nearest-lots__distance {
  font-size: .8125rem;
  color: #525252;
  display: inline-block;
  margin-bottom: .25rem; }
  @media (min-width: 480px) {
    .nearest-lots__distance {
      display: block;
      font-size: inherit;
      margin-top: 1rem;
      margin-bottom: .5rem; } }
  @media (min-width: 768px) {
    .nearest-lots__distance {
      font-size: 1.1875rem;
      margin-top: .5rem;
      margin-bottom: 0; } }
  @media (min-width: 992px) {
    .nearest-lots__distance {
      font-size: inherit; } }
  .nearest-lots__distance .icon, .nearest-lots__distance .slick-slider .slick-prev, .slick-slider .nearest-lots__distance .slick-prev, .nearest-lots__distance .slick-slider .slick-next, .slick-slider .nearest-lots__distance .slick-next, .nearest-lots__distance .retail-nuances__container::before, .nearest-lots__distance .how-it__btn::after, .nearest-lots__distance .retail-prices__list > li::before, .nearest-lots__distance .lot-carousel .slick-prev, .lot-carousel .nearest-lots__distance .slick-prev, .nearest-lots__distance .report-carousel .slick-prev, .report-carousel .nearest-lots__distance .slick-prev, .nearest-lots__distance .lot-carousel .slick-next, .lot-carousel .nearest-lots__distance .slick-next, .nearest-lots__distance .report-carousel .slick-next, .report-carousel .nearest-lots__distance .slick-next {
    margin-right: .25rem;
    font-size: .625rem;
    vertical-align: baseline; }
    .nearest-lots__distance .icon::before, .nearest-lots__distance .slick-slider .slick-prev::before, .slick-slider .nearest-lots__distance .slick-prev::before, .nearest-lots__distance .slick-slider .slick-next::before, .slick-slider .nearest-lots__distance .slick-next::before, .nearest-lots__distance .retail-nuances__container::before, .nearest-lots__distance .retail-prices__list > li::before, .nearest-lots__distance .lot-carousel .slick-prev::before, .lot-carousel .nearest-lots__distance .slick-prev::before, .nearest-lots__distance .report-carousel .slick-prev::before, .report-carousel .nearest-lots__distance .slick-prev::before, .nearest-lots__distance .lot-carousel .slick-next::before, .lot-carousel .nearest-lots__distance .slick-next::before, .nearest-lots__distance .report-carousel .slick-next::before, .report-carousel .nearest-lots__distance .slick-next::before {
      content: ""; }
    @media (min-width: 480px) {
      .nearest-lots__distance .icon, .nearest-lots__distance .slick-slider .slick-prev, .slick-slider .nearest-lots__distance .slick-prev, .nearest-lots__distance .slick-slider .slick-next, .slick-slider .nearest-lots__distance .slick-next, .nearest-lots__distance .retail-nuances__container::before, .nearest-lots__distance .how-it__btn::after, .nearest-lots__distance .retail-prices__list > li::before, .nearest-lots__distance .lot-carousel .slick-prev, .lot-carousel .nearest-lots__distance .slick-prev, .nearest-lots__distance .report-carousel .slick-prev, .report-carousel .nearest-lots__distance .slick-prev, .nearest-lots__distance .lot-carousel .slick-next, .lot-carousel .nearest-lots__distance .slick-next, .nearest-lots__distance .report-carousel .slick-next, .report-carousel .nearest-lots__distance .slick-next {
        font-size: 1.25rem;
        vertical-align: text-top; }
        .nearest-lots__distance .icon::before, .nearest-lots__distance .slick-slider .slick-prev::before, .slick-slider .nearest-lots__distance .slick-prev::before, .nearest-lots__distance .slick-slider .slick-next::before, .slick-slider .nearest-lots__distance .slick-next::before, .nearest-lots__distance .retail-nuances__container::before, .nearest-lots__distance .retail-prices__list > li::before, .nearest-lots__distance .lot-carousel .slick-prev::before, .lot-carousel .nearest-lots__distance .slick-prev::before, .nearest-lots__distance .report-carousel .slick-prev::before, .report-carousel .nearest-lots__distance .slick-prev::before, .nearest-lots__distance .lot-carousel .slick-next::before, .lot-carousel .nearest-lots__distance .slick-next::before, .nearest-lots__distance .report-carousel .slick-next::before, .report-carousel .nearest-lots__distance .slick-next::before {
          content: ""; } }
    @media (min-width: 992px) {
      .nearest-lots__distance .icon, .nearest-lots__distance .slick-slider .slick-prev, .slick-slider .nearest-lots__distance .slick-prev, .nearest-lots__distance .slick-slider .slick-next, .slick-slider .nearest-lots__distance .slick-next, .nearest-lots__distance .retail-nuances__container::before, .nearest-lots__distance .how-it__btn::after, .nearest-lots__distance .retail-prices__list > li::before, .nearest-lots__distance .lot-carousel .slick-prev, .lot-carousel .nearest-lots__distance .slick-prev, .nearest-lots__distance .report-carousel .slick-prev, .report-carousel .nearest-lots__distance .slick-prev, .nearest-lots__distance .lot-carousel .slick-next, .lot-carousel .nearest-lots__distance .slick-next, .nearest-lots__distance .report-carousel .slick-next, .report-carousel .nearest-lots__distance .slick-next {
        font-size: 1.0625rem; } }

.nearest-lots__price {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600; }
  @media (max-width: 479.98px) {
    .nearest-lots__price {
      display: block; } }
  @media (min-width: 480px) {
    .nearest-lots__price {
      font-family: "Roboto", Arial, Helvetica, sans-serif;
      font-size: 1.3125rem;
      margin-left: 1rem; } }
  @media (min-width: 768px) {
    .nearest-lots__price {
      font-size: 1.3125rem; } }
  @media (min-width: 992px) {
    .nearest-lots__price {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    .nearest-lots__price {
      font-size: 1.3125rem; } }

.nearest-lots__view {
  display: none; }
  @media (min-width: 480px) {
    .nearest-lots__view {
      border: 2px solid currentColor;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 1.25rem;
      right: 1rem;
      font-size: 1rem;
      color: #e55e43;
      border-color: #e55e43 !important; } }
  @media (min-width: 480px) and (prefers-reduced-motion: reduce) {
    .nearest-lots__view {
      transition: none; } }
  @media (min-width: 992px) {
    .nearest-lots__view {
      width: 1.875rem;
      height: 1.875rem;
      font-size: .875rem; } }
  @media (min-width: 1200px) {
    .nearest-lots__view {
      width: 2.1875rem;
      height: 2.1875rem; } }

.nearest-lots__link:hover .nearest-lots__view {
  color: #fff;
  background-color: #e55e43; }

@media (min-width: 992px) {
  .nearest-lots__text {
    width: 770px; } }

@media (min-width: 1200px) {
  .nearest-lots__text {
    width: 1070px; } }

.ports__subtitle {
  width: 245px;
  line-height: 27px; }
  @media (min-width: 480px) {
    .ports__subtitle {
      width: auto; } }

/*.review-carousel {
  margin: {
    right: -$review-carousel--gutter-width/2;
    left: -$review-carousel--gutter-width/2;
  }
  //padding: {
  //  right: $review-carousel--gutter-width/4;
  //  left: $review-carousel--gutter-width/4;
  //}
  overflow-x: hidden;

  --items-per-slide: 1;

  @include media-breakpoint-up(sm) {
    --items-per-slide: 2;
  }

  @include media-breakpoint-up(lg) {
    --items-per-slide: 4;
  }

  .carousel-inner {
    //@extend .row;
    padding: {
      right: $review-carousel--gutter-width/4;
      left: $review-carousel--gutter-width/4;
    }
    margin: 0 auto;
    overflow: visible;
  }

  .carousel-item {
    @extend .col-12, .col-sm-6, .col-lg-3;

    padding: 0;
  }

  @include media-breakpoint-up(sm) {
    .carousel-item {
      margin-right: 0;
    }

    .carousel-inner .active + .carousel-item {
      display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
      transition: none !important;
    }
    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
      transform: translateX(0);
      visibility: visible;
    }
  }

  @include media-breakpoint-between(sm, md) {
    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-next + .carousel-item,
    .carousel-inner .carousel-item-prev,
    .carousel-inner .carousel-item-prev + .carousel-item {
      position: absolute;
      top: 0;
      z-index: -1;
      display: block;
      visibility: hidden;
    }

    .carousel-inner .carousel-item-next {
      right: -50%;
    }
    .carousel-inner .carousel-item-next + .carousel-item {
      right: -100%;
    }
    .carousel-inner .carousel-item-prev {
      left: -100%;
    }
    .carousel-inner .carousel-item-prev + .carousel-item {
      left: -50%;
    }

    .active.carousel-item-left,
    .active.carousel-item-left + .carousel-item,
    .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item {
      transform: translateX(-200%);
      visibility: visible;
    }
    .active.carousel-item-right,
    .active.carousel-item-right + .carousel-item,
    .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item {
      transform: translateX(200%);
      visibility: visible;
    }
  }

  @include media-breakpoint-up(lg) {
    .carousel-item {
      @include transition(opacity $carousel-transition-duration ease-in-out, $carousel-transition);
    }

    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-next + .carousel-item,
    .carousel-inner .carousel-item-next + .carousel-item + .carousel-item,
    .carousel-inner .carousel-item-next + .carousel-item + .carousel-item + .carousel-item,
    .carousel-inner .carousel-item-prev,
    .carousel-inner .carousel-item-prev + .carousel-item,
    .carousel-inner .carousel-item-prev + .carousel-item + .carousel-item,
    .carousel-inner .carousel-item-prev + .carousel-item + .carousel-item + .carousel-item {
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: -1;
      display: block;
      visibility: hidden;
    }

    .carousel-inner .carousel-item-next {
      right: -25%;
    }
    .carousel-inner .carousel-item-next + .carousel-item {
      right: -50%;
    }
    .carousel-inner .carousel-item-next + .carousel-item + .carousel-item {
      right: -75%;
    }
    .carousel-inner .carousel-item-next + .carousel-item + .carousel-item + .carousel-item {
      right: -100%;
    }

    .carousel-inner .carousel-item-prev {
      left: -100%;
    }
    .carousel-inner .carousel-item-prev + .carousel-item {
      left: -75%;
    }
    .carousel-inner .carousel-item-prev + .carousel-item + .carousel-item {
      left: -50%;
    }
    .carousel-inner .carousel-item-prev + .carousel-item + .carousel-item + .carousel-item {
      left: -25%;
    }

    .carousel-inner .active + .carousel-item + .carousel-item,
    .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
      display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item,
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
      transition: none !important;
    }

    .active.carousel-item-left,
    .active.carousel-item-left + .carousel-item,
    .active.carousel-item-left + .carousel-item + .carousel-item,
    .active.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
    .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
      opacity: 1;
      transform: translateX(-400%);
      visibility: visible;
    }

    .active.carousel-item-right,
    .active.carousel-item-right + .carousel-item,
    .active.carousel-item-right + .carousel-item + .carousel-item,
    .active.carousel-item-right + .carousel-item + .carousel-item + .carousel-item,
    .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
      opacity: 1;
      transform: translateX(400%);
      visibility: visible;
    }

    .active.carousel-item-left,
    .active.carousel-item-left + .carousel-item,
    .active.carousel-item-left + .carousel-item + .carousel-item,
    .active.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
    .active.carousel-item-right,
    .active.carousel-item-right + .carousel-item,
    .active.carousel-item-right + .carousel-item + .carousel-item,
    .active.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
      opacity: 0;
    }
  }

  @include carousel-indicators;

  .carousel-indicators {
    flex-wrap: wrap;

    li {
      &:nth-child(n + 9) {
      }
      @include media-breakpoint-up(sm) {
        &:nth-child(2n) {
          display: none;
        }
      }
      @include media-breakpoint-up(lg) {
        &:nth-child(4n+3) {
          display: none;
        }
      }
    }
  }

  .carousel-prev, .carousel-next {
    display: none;

    @include media-breakpoint-up(lg) {
      display: inline-flex;
      color: $black;
      width: 2.3125rem;
      height: 2.3125rem;
      border-radius: 50%;
      margin: 0 1rem;
      align-items: center;
      justify-content: center;

      @include transition(color .3s ease-in-out, background-color .3s ease-in-out);

      &:hover {
        color: $white;
        background-color: #555;
      }

      .icon {
        font-size: 1.375rem;
        position: relative;
        right: -.125rem;
        top: .0625rem;
      }
      .icon-chevron-left {
        right: auto;
        left: -.125rem;
      }
    }
  }

  &__bottom {
    @include media-breakpoint-up(lg) {
      padding-top: 1.875rem;
    }
  }
}*/
/*
.review-carousel__item {
  padding: {
    left: $review-carousel--gutter-width/4;
    right: $review-carousel--gutter-width/4;
  }
}
*/
.review-main {
  position: relative;
  z-index: 1; }
  .review-main .container-fluid, .review-main .container-sm, .review-main .container-md, .review-main .container-lg, .review-main .container-xl {
    max-width: 1110px;
    overflow: hidden; }
    @media (min-width: 992px) {
      .review-main .container-fluid, .review-main .container-sm, .review-main .container-md, .review-main .container-lg, .review-main .container-xl {
        padding-right: 25px;
        padding-left: 25px; } }
  .review-main__item:not(.slick-slide) {
    display: none; }
  .review-main__img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: .9375rem; }

.review-main__carousel {
  padding-top: 20px;
  margin: -20px -20px 0;
  overflow: hidden; }
  .review-main__carousel .slick-list {
    padding: 0 10px;
    overflow: visible; }
    @media (min-width: 768px) {
      .review-main__carousel .slick-list {
        padding: 0 15px 10px; } }
    @media (min-width: 992px) {
      .review-main__carousel .slick-list {
        padding-bottom: 20px; } }
  .review-main__carousel .slick-slide {
    padding: 0 10px; }
    @media (min-width: 768px) {
      .review-main__carousel .slick-slide {
        padding: 0 15px; } }
  .review-main__carousel .slick-active .review-main__img {
    box-shadow: 0.125rem 0.25rem 1.25rem rgba(50, 69, 90, 0.2); }
    @media (min-width: 992px) {
      .review-main__carousel .slick-active .review-main__img {
        box-shadow: 0.25rem 0.375rem 1.5625rem rgba(50, 69, 90, 0.5); } }

@media (max-width: 991.98px) {
  .wholesale-main {
    background-color: #f9f9f9; } }

@media (min-width: 480px) and (max-width: 991.98px) {
  .wholesale-main {
    margin-bottom: 1.3125rem; } }

@media (min-width: 992px) {
  .wholesale-main {
    margin-bottom: 5rem;
    position: relative; }
    .wholesale-main::after {
      content: '';
      position: absolute;
      background-color: #f9f9f9;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      left: calc( (100vw - 970px) / 2 + 970px * 0.25); } }

@media (min-width: 1200px) {
  .wholesale-main::after {
    left: calc( (100vw - 1140px) / 2 + 1140px * 0.25); } }

@media (max-width: 767.98px) {
  .wholesale-main .btn {
    padding-left: .5rem;
    padding-right: .5rem; } }

@media (min-width: 480px) and (max-width: 767.98px) {
  .wholesale-main .btn {
    font-size: .8125rem;
    padding: .8125rem .5rem; } }

.wholesale-platforms {
  max-width: 30rem; }
  .wholesale-platforms__item:not(:last-child) {
    margin-right: 9px; }
    @media (min-width: 480px) {
      .wholesale-platforms__item:not(:last-child) {
        margin-right: 30px; } }

.wholesale-images {
  position: relative;
  padding-bottom: 56.25%;
  z-index: 1; }
  @media (min-width: 1200px) {
    .wholesale-images {
      right: calc(0px + 95 * ((100vw - 1200px) / 720));
      padding-bottom: 70.86614%; } }
  .wholesale-images img {
    position: absolute;
    width: 62%;
    height: auto; }
    @media (min-width: 992px) {
      .wholesale-images img {
        box-shadow: 0.25rem 0.25rem 1.5625rem rgba(0, 0, 0, 0.2); } }
    @media (min-width: 1200px) {
      .wholesale-images img {
        width: 70.86614%; } }
  .wholesale-images__1 {
    top: 0;
    right: 0; }
  .wholesale-images__2 {
    left: 0;
    top: 17%;
    z-index: -1; }
    @media (min-width: 1200px) {
      .wholesale-images__2 {
        top: 8.66667%; } }
  .wholesale-images__3 {
    bottom: 0;
    left: 19%; }
    @media (min-width: 1200px) {
      .wholesale-images__3 {
        left: 14.96063%; } }

@media (min-width: 480px) and (max-width: 767.98px) {
  .container_p20 .main-services {
    margin-right: -10px;
    margin-left: -10px; }
    .container_p20 .main-services .col,
    .container_p20 .main-services [class*="col-"] {
      padding-right: 10px;
      padding-left: 10px; } }

.main-services .icon, .main-services .slick-slider .slick-prev, .slick-slider .main-services .slick-prev, .main-services .slick-slider .slick-next, .slick-slider .main-services .slick-next, .main-services .retail-nuances__container::before, .main-services .how-it__btn::after, .main-services .retail-prices__list > li::before, .main-services .lot-carousel .slick-prev, .lot-carousel .main-services .slick-prev, .main-services .report-carousel .slick-prev, .report-carousel .main-services .slick-prev, .main-services .lot-carousel .slick-next, .lot-carousel .main-services .slick-next, .main-services .report-carousel .slick-next, .report-carousel .main-services .slick-next {
  font-size: 3.4375rem;
  color: #e55e43; }

.main-services .btn {
  font-family: "Roboto", Arial, Helvetica, sans-serif; }

.main-services__item {
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .main-services__item {
      padding: 0 30px; } }
  @media (min-width: 1200px) {
    .main-services__item {
      padding: 0 15px; } }

.main-services__title {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  line-height: 1.3;
  display: block; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .main-services__title {
      font-size: 1.3125rem; } }
  @media (min-width: 1200px) and (max-width: 1439.98px) {
    .main-services__title {
      font-size: 1.4375rem; } }

.main-services__description {
  padding-right: 10px;
  padding-left: 10px;
  font-size: .875rem;
  /*@include media-breakpoint-down(sm) {
      margin: {
        left: 5%;
        right: 5%;
      }
    }*/ }
  @media (min-width: 480px) {
    .main-services__description {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .main-services__description {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    .main-services__description {
      font-size: .9375rem; } }

@media (min-width: 480px) and (max-width: 991.98px) {
  .shipment-preparation {
    display: flex;
    flex-direction: column; } }

@media (min-width: 992px) {
  .shipment-preparation::after {
    display: block;
    clear: both;
    content: ""; } }

@media (min-width: 992px) {
  .shipment-preparation__title, .shipment-preparation__info {
    float: right;
    width: 41.39785%;
    padding-left: 15px; } }

@media (min-width: 1200px) {
  .shipment-preparation__title, .shipment-preparation__info {
    width: 41.71171%; } }

@media (min-width: 480px) and (max-width: 991.98px) {
  .shipment-preparation__video {
    order: -1; } }

@media (min-width: 992px) {
  .shipment-preparation__video {
    float: left;
    width: 58.60215%;
    padding-right: 15px; } }

@media (min-width: 1200px) {
  .shipment-preparation__video {
    width: 58.28829%; } }

.shipment-preparation__video-inner {
  position: relative;
  padding-bottom: 56.25%; }
  .shipment-preparation__video-inner iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.instagram-main__link {
  display: block;
  margin-bottom: 15px;
  margin-top: 15px;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .instagram-main__link {
      transition: none; } }
  .instagram-main__link:hover {
    box-shadow: 0.3125rem 0.5rem 1.5625rem rgba(0, 0, 0, 0.3);
    transform: scale(1.04); }

.search-page {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media (min-width: 992px) {
    .search-page {
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  .search-page__title {
    font-size: 1.3125rem;
    font-weight: 500; }
  .search-page__applied-filters {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.375rem; }
  .search-page__filter-tag {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    background-color: #efefef;
    line-height: 1.6;
    padding: 0 .5rem;
    margin: .25rem .375rem .5rem; }
    .search-page__filter-tag .icon, .search-page__filter-tag .slick-slider .slick-prev, .slick-slider .search-page__filter-tag .slick-prev, .search-page__filter-tag .slick-slider .slick-next, .slick-slider .search-page__filter-tag .slick-next, .search-page__filter-tag .retail-nuances__container::before, .search-page__filter-tag .how-it__btn::after, .search-page__filter-tag .retail-prices__list > li::before, .search-page__filter-tag .lot-carousel .slick-prev, .lot-carousel .search-page__filter-tag .slick-prev, .search-page__filter-tag .report-carousel .slick-prev, .report-carousel .search-page__filter-tag .slick-prev, .search-page__filter-tag .lot-carousel .slick-next, .lot-carousel .search-page__filter-tag .slick-next, .search-page__filter-tag .report-carousel .slick-next, .report-carousel .search-page__filter-tag .slick-next {
      font-size: .8125rem;
      margin-left: .25rem;
      text-shadow: 0 0 1px #000;
      vertical-align: baseline; }
  .search-page__filter-title {
    padding: .25rem 0; }
    .search-page__filter-title .icon, .search-page__filter-title .slick-slider .slick-prev, .slick-slider .search-page__filter-title .slick-prev, .search-page__filter-title .slick-slider .slick-next, .slick-slider .search-page__filter-title .slick-next, .search-page__filter-title .retail-nuances__container::before, .search-page__filter-title .how-it__btn::after, .search-page__filter-title .retail-prices__list > li::before, .search-page__filter-title .lot-carousel .slick-prev, .lot-carousel .search-page__filter-title .slick-prev, .search-page__filter-title .report-carousel .slick-prev, .report-carousel .search-page__filter-title .slick-prev, .search-page__filter-title .lot-carousel .slick-next, .lot-carousel .search-page__filter-title .slick-next, .search-page__filter-title .report-carousel .slick-next, .report-carousel .search-page__filter-title .slick-next {
      font-size: .9375rem; }
  .search-page__filter-toggle {
    margin: .75rem 0 1rem;
    font-weight: 500;
    font-size: 1rem;
    text-transform: none; }
    .search-page__filter-toggle .icon, .search-page__filter-toggle .slick-slider .slick-prev, .slick-slider .search-page__filter-toggle .slick-prev, .search-page__filter-toggle .slick-slider .slick-next, .slick-slider .search-page__filter-toggle .slick-next, .search-page__filter-toggle .retail-nuances__container::before, .search-page__filter-toggle .how-it__btn::after, .search-page__filter-toggle .retail-prices__list > li::before, .search-page__filter-toggle .lot-carousel .slick-prev, .lot-carousel .search-page__filter-toggle .slick-prev, .search-page__filter-toggle .report-carousel .slick-prev, .report-carousel .search-page__filter-toggle .slick-prev, .search-page__filter-toggle .lot-carousel .slick-next, .lot-carousel .search-page__filter-toggle .slick-next, .search-page__filter-toggle .report-carousel .slick-next, .report-carousel .search-page__filter-toggle .slick-next {
      font-size: 1.375rem; }

.search-table__row > div:nth-child(4), .search-table__row > div:nth-child(5), .search-table__row > div:nth-child(6), .search-table__row > div:nth-child(9), .search-table__row > div:nth-child(10) {
  word-break: break-word; }

.search-table__row > div:first-child, .search-table__row > div:last-child, .search-table__row > div:nth-child(2), .search-table__row > div:nth-child(3), .search-table__row > div:nth-child(7), .search-table__row > div:nth-child(8) {
  flex-grow: 0;
  flex-shrink: 0; }

.search-table {
  font-size: .875rem; }
  .search-table::after {
    content: '';
    display: block;
    border-bottom: 1px solid #d0d0d0; }
  .search-table__row {
    display: flex; }
    @media (max-width: 1439.98px) {
      .search-table__row {
        flex-wrap: wrap; } }
    .search-table__row:nth-child(even) {
      background-color: #f5f5f5; }
    .search-table__row > div {
      padding: .9375rem .625rem;
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .search-table__row > div:first-child {
        width: 8rem; }
      .search-table__row > div:last-child {
        width: 12rem;
        padding-top: .75rem; }
      .search-table__row > div:nth-child(2) {
        width: 6rem; }
      .search-table__row > div:nth-child(3) {
        width: 3.5rem; }
      .search-table__row > div:nth-child(4) {
        width: 20%; }
      .search-table__row > div:nth-child(5) {
        width: 20%; }
      .search-table__row > div:nth-child(6) {
        width: 20%; }
      .search-table__row > div:nth-child(7) {
        width: 7.5rem; }
      .search-table__row > div:nth-child(8) {
        width: 6.5rem; }
      .search-table__row > div:nth-child(9) {
        width: 20%; }
      .search-table__row > div:nth-child(10) {
        width: 20%; }
  @media (max-width: 1439.98px) {
    .search-table__row > div:nth-child(8) {
      display: none;
      order: 13;
      align-items: flex-start;
      width: 100%;
      padding-top: 0;
      padding-bottom: .25rem;
      border: none !important; }
      .search-table__row > div:nth-child(8)::before {
        content: attr(data-title) ":";
        margin-right: .33em;
        font-weight: 500; }
    .search-table__row.expanded > div:nth-child(8) {
      display: block; }
    .search-table__row > div:nth-child(9) {
      display: none;
      order: 13;
      align-items: flex-start;
      width: 100%;
      padding-top: 0;
      padding-bottom: .25rem;
      border: none !important; }
      .search-table__row > div:nth-child(9)::before {
        content: attr(data-title) ":";
        margin-right: .33em;
        font-weight: 500; }
    .search-table__row.expanded > div:nth-child(9) {
      display: block; }
    .search-table__row > div:nth-child(10) {
      display: none;
      order: 13;
      align-items: flex-start;
      width: 100%;
      padding-top: 0;
      padding-bottom: .25rem;
      border: none !important; }
      .search-table__row > div:nth-child(10)::before {
        content: attr(data-title) ":";
        margin-right: .33em;
        font-weight: 500; }
    .search-table__row.expanded > div:nth-child(10) {
      display: block; }
    .search-table__row > div:last-child {
      display: flex;
      width: 16%; }
    .search-table__row > div:first-child {
      width: 12%; }
    .search-table__row > div:nth-child(2) {
      width: 10%; }
    .search-table__row > div:nth-child(3) {
      width: 6%; }
    .search-table__row > div:nth-child(4) {
      width: 15%; }
    .search-table__row > div:nth-child(5) {
      width: 14%; }
    .search-table__row > div:nth-child(6) {
      width: 14%; }
    .search-table__row > div:nth-child(7) {
      width: 13%; } }
  @media (max-width: 991.98px) {
    .search-table__row > div:nth-child(6) {
      display: none;
      order: 13;
      align-items: flex-start;
      width: 100%;
      padding-top: 0;
      padding-bottom: .25rem;
      border: none !important; }
      .search-table__row > div:nth-child(6)::before {
        content: attr(data-title) ":";
        margin-right: .33em;
        font-weight: 500; }
    .search-table__row.expanded > div:nth-child(6) {
      display: block; }
    .search-table__row > div:nth-child(7) {
      display: none;
      order: 13;
      align-items: flex-start;
      width: 100%;
      padding-top: 0;
      padding-bottom: .25rem;
      border: none !important; }
      .search-table__row > div:nth-child(7)::before {
        content: attr(data-title) ":";
        margin-right: .33em;
        font-weight: 500; }
    .search-table__row.expanded > div:nth-child(7) {
      display: block; }
    .search-table__row > div:first-child {
      width: 16%; }
    .search-table__row > div:last-child {
      width: 21%; }
    .search-table__row > div:nth-child(2) {
      width: 12%; }
    .search-table__row > div:nth-child(3) {
      width: 8%; }
    .search-table__row > div:nth-child(4) {
      width: 21%; }
    .search-table__row > div:nth-child(5) {
      width: 22%; } }
  @media (max-width: 767.98px) {
    .search-table__row > div:nth-child(3) {
      display: none;
      order: 13;
      align-items: flex-start;
      width: 100%;
      padding-top: 0;
      padding-bottom: .25rem;
      border: none !important; }
      .search-table__row > div:nth-child(3)::before {
        content: attr(data-title) ":";
        margin-right: .33em;
        font-weight: 500; }
    .search-table__row.expanded > div:nth-child(3) {
      display: block; }
    .search-table__row > div:nth-child(4) {
      display: none;
      order: 13;
      align-items: flex-start;
      width: 100%;
      padding-top: 0;
      padding-bottom: .25rem;
      border: none !important; }
      .search-table__row > div:nth-child(4)::before {
        content: attr(data-title) ":";
        margin-right: .33em;
        font-weight: 500; }
    .search-table__row.expanded > div:nth-child(4) {
      display: block; }
    .search-table__row > div:nth-child(5) {
      display: none;
      order: 13;
      align-items: flex-start;
      width: 100%;
      padding-top: 0;
      padding-bottom: .25rem;
      border: none !important; }
      .search-table__row > div:nth-child(5)::before {
        content: attr(data-title) ":";
        margin-right: .33em;
        font-weight: 500; }
    .search-table__row.expanded > div:nth-child(5) {
      display: block; }
    .search-table__row > div:first-child {
      width: 26%; }
    .search-table__row > div:last-child {
      width: 49%; }
    .search-table__row > div:nth-child(2) {
      width: 25%; } }
  @media (max-width: 479.98px) {
    .search-table__row > div:nth-child(2) {
      display: none;
      order: 13;
      align-items: flex-start;
      width: 100%;
      padding-top: 0;
      padding-bottom: .25rem;
      border: none !important; }
      .search-table__row > div:nth-child(2)::before {
        content: attr(data-title) ":";
        margin-right: .33em;
        font-weight: 500; }
    .search-table__row.expanded > div:nth-child(2) {
      display: block; }
    .search-table__row > div:first-child {
      width: 45%; }
    .search-table__row > div:last-child {
      width: 55%; } }
  .search-table__row:not(.search-table__head) {
    margin-bottom: -1px; }
    .search-table__row:not(.search-table__head) > div {
      border-color: #d0d0d0;
      border-style: solid;
      border-width: 1px 0; }
  .search-table__row.expanded > div {
    border-bottom: none; }
  @media (min-width: 1200px) {
    .search-table__head {
      position: sticky;
      top: 0;
      z-index: 10; } }
  .search-table__head > div {
    font-weight: 500;
    color: #fff;
    background-color: #3e3e3e;
    padding-top: .4375rem;
    padding-bottom: .4375rem;
    min-height: 3.125rem; }
    .search-table__head > div:not(:last-child) {
      border-right: 1px solid #c5c5c5; }
  .search-table__image-link {
    display: inline-block;
    transition: opacity 0.3s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .search-table__image-link {
        transition: none; } }
    .search-table__image-link:hover {
      opacity: .6; }
  .search-table__price {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 1rem; }
  .search-table__place-bet {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-size: .875rem;
    font-weight: 500;
    padding-top: .625rem;
    padding-bottom: .625rem;
    margin-top: .25rem;
    line-height: 1rem;
    text-transform: none; }
    @media (max-width: 1439.98px) {
      .search-table__place-bet {
        width: min-content;
        padding: .125rem .625rem; } }
    @media (min-width: 480px) and (max-width: 767.98px) {
      .search-table__place-bet {
        width: auto;
        padding: .625rem .75rem; } }
  .search-table__expand-btn {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 1.5625rem;
    height: 1.5625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #9dd47a;
    color: #fff;
    border: 0.125rem solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 0.4375rem rgba(0, 0, 0, 0.3);
    margin-left: 1.5rem;
    margin-right: 1rem;
    position: relative; }
    @media (min-width: 1200px) {
      .search-table__expand-btn {
        width: 1.25rem;
        height: 1.25rem; } }
    @media (min-width: 1440px) {
      .search-table__expand-btn {
        display: none; } }
    .search-table__expand-btn .icon, .search-table__expand-btn .slick-slider .slick-prev, .slick-slider .search-table__expand-btn .slick-prev, .search-table__expand-btn .slick-slider .slick-next, .slick-slider .search-table__expand-btn .slick-next, .search-table__expand-btn .retail-nuances__container::before, .search-table__expand-btn .how-it__btn::after, .search-table__expand-btn .retail-prices__list > li::before, .search-table__expand-btn .lot-carousel .slick-prev, .lot-carousel .search-table__expand-btn .slick-prev, .search-table__expand-btn .report-carousel .slick-prev, .report-carousel .search-table__expand-btn .slick-prev, .search-table__expand-btn .lot-carousel .slick-next, .lot-carousel .search-table__expand-btn .slick-next, .search-table__expand-btn .report-carousel .slick-next, .report-carousel .search-table__expand-btn .slick-next {
      font-size: .625rem;
      line-height: 1;
      vertical-align: baseline; }
      @media (min-width: 1200px) {
        .search-table__expand-btn .icon, .search-table__expand-btn .slick-slider .slick-prev, .slick-slider .search-table__expand-btn .slick-prev, .search-table__expand-btn .slick-slider .slick-next, .slick-slider .search-table__expand-btn .slick-next, .search-table__expand-btn .retail-nuances__container::before, .search-table__expand-btn .how-it__btn::after, .search-table__expand-btn .retail-prices__list > li::before, .search-table__expand-btn .lot-carousel .slick-prev, .lot-carousel .search-table__expand-btn .slick-prev, .search-table__expand-btn .report-carousel .slick-prev, .report-carousel .search-table__expand-btn .slick-prev, .search-table__expand-btn .lot-carousel .slick-next, .lot-carousel .search-table__expand-btn .slick-next, .search-table__expand-btn .report-carousel .slick-next, .report-carousel .search-table__expand-btn .slick-next {
          font-size: .5rem; } }
    .search-table__expand-btn .icon::before, .search-table__expand-btn .slick-slider .slick-prev::before, .slick-slider .search-table__expand-btn .slick-prev::before, .search-table__expand-btn .slick-slider .slick-next::before, .slick-slider .search-table__expand-btn .slick-next::before, .search-table__expand-btn .retail-nuances__container::before, .search-table__expand-btn .retail-prices__list > li::before, .search-table__expand-btn .lot-carousel .slick-prev::before, .lot-carousel .search-table__expand-btn .slick-prev::before, .search-table__expand-btn .report-carousel .slick-prev::before, .report-carousel .search-table__expand-btn .slick-prev::before, .search-table__expand-btn .lot-carousel .slick-next::before, .lot-carousel .search-table__expand-btn .slick-next::before, .search-table__expand-btn .report-carousel .slick-next::before, .report-carousel .search-table__expand-btn .slick-next::before {
      content: ""; }
  .search-table__row.expanded .search-table__expand-btn {
    background-color: #e05f5f; }
    .search-table__row.expanded .search-table__expand-btn .icon::before, .search-table__row.expanded .search-table__expand-btn .slick-slider .slick-prev::before, .slick-slider .search-table__row.expanded .search-table__expand-btn .slick-prev::before, .search-table__row.expanded .search-table__expand-btn .slick-slider .slick-next::before, .slick-slider .search-table__row.expanded .search-table__expand-btn .slick-next::before, .search-table__row.expanded .search-table__expand-btn .retail-nuances__container::before, .search-table__row.expanded .search-table__expand-btn .retail-prices__list > li::before, .search-table__row.expanded .search-table__expand-btn .lot-carousel .slick-prev::before, .lot-carousel .search-table__row.expanded .search-table__expand-btn .slick-prev::before, .search-table__row.expanded .search-table__expand-btn .report-carousel .slick-prev::before, .report-carousel .search-table__row.expanded .search-table__expand-btn .slick-prev::before, .search-table__row.expanded .search-table__expand-btn .lot-carousel .slick-next::before, .lot-carousel .search-table__row.expanded .search-table__expand-btn .slick-next::before, .search-table__row.expanded .search-table__expand-btn .report-carousel .slick-next::before, .report-carousel .search-table__row.expanded .search-table__expand-btn .slick-next::before {
      content: ""; }

@media (min-width: 1200px) {
  .search-filters {
    width: 14rem;
    padding-right: 30px;
    flex-shrink: 0; } }

@media (min-width: 1440px) {
  .search-filters {
    width: 19.5rem; } }

@media (max-width: 1199.98px) {
  .search-filters.slide-panel {
    position: absolute;
    top: 3.125rem;
    left: 0;
    width: 0;
    height: calc(100vh - 3.125rem);
    overflow-x: hidden;
    overflow-y: auto;
    transition: width 0s .3s; }
    .search-filters.slide-panel::-webkit-scrollbar {
      width: 0 !important; }
    .search-filters.slide-panel {
      overflow: -moz-scrollbars-none; }
    .search-filters.slide-panel {
      -ms-overflow-style: none; }
    .search-filters.slide-panel--right {
      left: auto;
      right: 0; }
    .search-filters.slide-panel::before {
      content: '';
      position: fixed;
      top: 3.125rem;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s .3s, opacity .3s ease-in-out; }
    .search-filters.slide-panel.opened, .search-filters.slide-panel:focus-within {
      width: 100%;
      transition: width 0s; }
      .search-filters.slide-panel.opened::before, .search-filters.slide-panel:focus-within::before {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s, opacity .3s ease-in-out; }
    .search-filters.slide-panel .slide-panel__inner {
      background-color: #fff;
      min-height: 100%;
      position: relative;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin-left: 0;
      width: 100vw;
      max-width: 20rem;
      transform: translateX(-100%);
      transition: transform .3s ease-in-out; }
      .search-filters.slide-panel.opened .slide-panel__inner, .search-filters.slide-panel .slide-panel__inner:focus-within {
        transform: translateX(0); }
    .search-filters.slide-panel--right .slide-panel__inner {
      margin-right: 0;
      margin-left: auto;
      transform: translateX(100%); }
    .search-filters.slide-panel--right.opened .slide-panel__inner,
    .search-filters.slide-panel--right .slide-panel__inner:focus-within {
      transform: translateX(0); } }

@media (max-width: 1199.98px) {
  .search-filters.slide-panel {
    top: 0;
    height: 100vh;
    z-index: 5;
    position: fixed; }
    .search-filters.slide-panel::before {
      top: 0; } }

@media (max-width: 1199.98px) {
  .search-filters.slide-panel .slide-panel__inner {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0; } }

.search-filters__list {
  border-bottom: 0.0625rem solid #e5e5e5; }
  @media (min-width: 1200px) {
    .search-filters__list {
      font-size: .875rem;
      border-style: solid;
      border-color: #e5e5e5;
      border-width: .0625rem .0625rem .0625rem 0; } }

.search-filters__section {
  padding: .5rem 0;
  border-top: 1px solid #e5e5e5; }
  @media (min-width: 1200px) {
    .search-filters__section:first-child {
      border-top: none; } }

.search-filters__title {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 1rem; }
  @media (min-width: 1200px) {
    .search-filters__title {
      font-family: "Roboto", Arial, Helvetica, sans-serif;
      font-weight: 400; } }

.search-filters__collapse {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  width: 100%;
  margin: .125rem 0; }
  .search-filters__collapse .icon, .search-filters__collapse .slick-slider .slick-prev, .slick-slider .search-filters__collapse .slick-prev, .search-filters__collapse .slick-slider .slick-next, .slick-slider .search-filters__collapse .slick-next, .search-filters__collapse .retail-nuances__container::before, .search-filters__collapse .how-it__btn::after, .search-filters__collapse .retail-prices__list > li::before, .search-filters__collapse .lot-carousel .slick-prev, .lot-carousel .search-filters__collapse .slick-prev, .search-filters__collapse .report-carousel .slick-prev, .report-carousel .search-filters__collapse .slick-prev, .search-filters__collapse .lot-carousel .slick-next, .lot-carousel .search-filters__collapse .slick-next, .search-filters__collapse .report-carousel .slick-next, .report-carousel .search-filters__collapse .slick-next {
    font-size: .875rem;
    display: inline-block;
    transition: transform 0.3s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .search-filters__collapse .icon, .search-filters__collapse .slick-slider .slick-prev, .slick-slider .search-filters__collapse .slick-prev, .search-filters__collapse .slick-slider .slick-next, .slick-slider .search-filters__collapse .slick-next, .search-filters__collapse .retail-nuances__container::before, .search-filters__collapse .how-it__btn::after, .search-filters__collapse .retail-prices__list > li::before, .search-filters__collapse .lot-carousel .slick-prev, .lot-carousel .search-filters__collapse .slick-prev, .search-filters__collapse .report-carousel .slick-prev, .report-carousel .search-filters__collapse .slick-prev, .search-filters__collapse .lot-carousel .slick-next, .lot-carousel .search-filters__collapse .slick-next, .search-filters__collapse .report-carousel .slick-next, .report-carousel .search-filters__collapse .slick-next {
        transition: none; } }
  .search-filters__collapse.collapsed .icon, .search-filters__collapse.collapsed .slick-slider .slick-prev, .slick-slider .search-filters__collapse.collapsed .slick-prev, .search-filters__collapse.collapsed .slick-slider .slick-next, .slick-slider .search-filters__collapse.collapsed .slick-next, .search-filters__collapse.collapsed .retail-nuances__container::before, .search-filters__collapse.collapsed .how-it__btn::after, .search-filters__collapse.collapsed .retail-prices__list > li::before, .search-filters__collapse.collapsed .lot-carousel .slick-prev, .lot-carousel .search-filters__collapse.collapsed .slick-prev, .search-filters__collapse.collapsed .report-carousel .slick-prev, .report-carousel .search-filters__collapse.collapsed .slick-prev, .search-filters__collapse.collapsed .lot-carousel .slick-next, .lot-carousel .search-filters__collapse.collapsed .slick-next, .search-filters__collapse.collapsed .report-carousel .slick-next, .report-carousel .search-filters__collapse.collapsed .slick-next {
    transform: rotateX(180deg); }

.search-filters__collapse-icon {
  margin-left: auto;
  align-self: center;
  padding: 0 .5rem; }

.search-filters__container {
  overflow-y: auto;
  overflow-x: hidden;
  color: rgba(26, 26, 26, 0);
  max-height: 16.75rem; }
  @media (max-width: 1199.98px) {
    .search-filters__container {
      margin: 0 -.5rem;
      padding: 0 .5rem; } }
  @media (min-width: 1200px) {
    .search-filters__container {
      margin-left: -.5rem;
      padding-left: .5rem; } }
  .search-filters__container:hover {
    color: rgba(26, 26, 26, 0.5); }
  .search-filters__container::-webkit-scrollbar-track {
    background-color: transparent; }
  .search-filters__container::-webkit-scrollbar, .search-filters__container::-webkit-scrollbar-thumb {
    width: 12px;
    border-radius: 12px;
    background-clip: padding-box;
    border: 4px solid transparent; }
  .search-filters__container::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px; }

.search-filters__inner {
  margin: .25rem 0;
  color: #1a1a1a; }

.search-filters__item {
  padding: .5rem 0; }
  @media (min-width: 1200px) {
    .search-filters__item {
      padding: .125rem 0; } }

.search-filters__submit {
  padding: 1rem 0; }
  .search-filters__submit .btn {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem; }
    @media (min-width: 1200px) {
      .search-filters__submit .btn {
        border-radius: 0; } }

.search-filters__hide-panel-btn {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 500; }
  .search-filters__hide-panel-btn .icon, .search-filters__hide-panel-btn .slick-slider .slick-prev, .slick-slider .search-filters__hide-panel-btn .slick-prev, .search-filters__hide-panel-btn .slick-slider .slick-next, .slick-slider .search-filters__hide-panel-btn .slick-next, .search-filters__hide-panel-btn .retail-nuances__container::before, .search-filters__hide-panel-btn .how-it__btn::after, .search-filters__hide-panel-btn .retail-prices__list > li::before, .search-filters__hide-panel-btn .lot-carousel .slick-prev, .lot-carousel .search-filters__hide-panel-btn .slick-prev, .search-filters__hide-panel-btn .report-carousel .slick-prev, .report-carousel .search-filters__hide-panel-btn .slick-prev, .search-filters__hide-panel-btn .lot-carousel .slick-next, .lot-carousel .search-filters__hide-panel-btn .slick-next, .search-filters__hide-panel-btn .report-carousel .slick-next, .report-carousel .search-filters__hide-panel-btn .slick-next {
    font-size: .9375rem;
    margin-right: .5rem;
    vertical-align: baseline; }

.search-filters__reset-btn {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  color: #9a9a9a;
  border: 0.0625rem solid #9a9a9a;
  border-radius: 1rem;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.8;
  padding: 0 .5rem; }

.search-filters .custom-control {
  min-height: 1.25rem; }

.search-filters .custom-control-label {
  padding-left: .5rem;
  padding-top: .25rem;
  line-height: 1.3; }
  @media (min-width: 1200px) {
    .search-filters .custom-control-label {
      padding-left: .25rem;
      padding-top: .125rem; } }
  @media (max-width: 1199.98px) {
    .search-filters .custom-control-label::after, .search-filters .custom-control-label::before {
      width: 1.875rem;
      height: 1.875rem; } }

.top-order {
  margin-top: 3.125rem; }
  @media (min-width: 480px) {
    .top-order {
      margin-top: 3.75rem; } }
  @media (min-width: 768px) {
    .top-order {
      margin-top: 4.375rem; } }
  @media (min-width: 992px) {
    .top-order {
      margin-top: 5rem; } }
  @media (min-width: 1200px) {
    .top-order {
      margin-top: 7.5rem; } }
  .top-order__carousel {
    margin: 0 -20px; }
    .top-order__carousel.slick-slider .slick-list {
      padding-bottom: 1.5rem;
      margin-bottom: -1.5rem; }
    .top-order__carousel.slick-slider .slick-prev, .top-order__carousel.slick-slider .slick-next {
      color: #c4c4c4;
      transition: color 0.3s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .top-order__carousel.slick-slider .slick-prev, .top-order__carousel.slick-slider .slick-next {
          transition: none; } }
      .top-order__carousel.slick-slider .slick-prev:hover, .top-order__carousel.slick-slider .slick-next:hover {
        color: #000; }
      @media (min-width: 1200px) {
        .top-order__carousel.slick-slider .slick-prev, .top-order__carousel.slick-slider .slick-next {
          margin-top: 1px; }
          .top-order__carousel.slick-slider .slick-prev::before, .top-order__carousel.slick-slider .slick-next::before {
            font-size: 1.5625rem; } }
    .top-order__carousel.slick-slider .slick-prev {
      margin-right: 1rem; }
    .top-order__carousel.slick-slider .slick-next {
      margin-left: 1rem; }
    .top-order__carousel.slick-slider .slick-dots {
      margin: 0.125rem 0; }
      @media (min-width: 480px) {
        .top-order__carousel.slick-slider .slick-dots {
          margin: 0.75rem 0; } }
      @media (min-width: 992px) {
        .top-order__carousel.slick-slider .slick-dots {
          margin: 1rem 0; } }
      @media (max-width: 479.98px) {
        .top-order__carousel.slick-slider .slick-dots > li {
          margin-left: 0;
          margin-right: 0; } }
  .top-order__item {
    padding: 0 20px; }
    .top-order__item:not(.slick-slide) {
      display: none; }
  .top-order__image {
    margin-bottom: -15%; }
    .top-order__image img {
      width: 100%;
      height: auto;
      display: block; }
  .top-order__description {
    border-radius: 0 0 .9375rem .9375rem;
    box-shadow: 0.25rem 0.3125rem 0.9375rem rgba(0, 0, 0, 0.1);
    padding: calc(15% + 1rem) 0.625rem 1.25rem; }
    @media (min-width: 480px) {
      .top-order__description {
        padding: calc(15% + 1rem) 0.9375rem 1.25rem; } }
    @media (min-width: 768px) {
      .top-order__description {
        padding: calc(15% + 1rem) 1.875rem 1.25rem; } }
    @media (min-width: 992px) {
      .top-order__description {
        padding: calc(15% + 1rem) 0.625rem 1.25rem; } }
    @media (min-width: 1200px) {
      .top-order__description {
        padding: calc(15% + 1rem) 0.75rem 1.25rem; } }
    .top-order__description-row {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between; }
  .top-order__model {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 500;
    min-width: 0;
    padding-right: .25rem;
    font-size: 0.8125rem; }
    @media (min-width: 480px) {
      .top-order__model {
        font-size: 0.875rem; } }
    @media (min-width: 768px) {
      .top-order__model {
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .top-order__model {
        font-size: 0.875rem; } }
    .top-order__model strong {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.8125rem; }
      @media (min-width: 480px) {
        .top-order__model strong {
          font-size: 1rem; } }
      @media (min-width: 768px) {
        .top-order__model strong {
          font-size: 1.5rem; } }
      @media (min-width: 992px) {
        .top-order__model strong {
          font-size: 1rem; } }
  .top-order__link {
    flex-shrink: 0;
    border-width: .0625rem;
    font-weight: 600;
    font-size: 0.6875rem;
    line-height: 1.6;
    padding: 0.5rem; }
    @media (min-width: 480px) {
      .top-order__link {
        font-size: 0.8125rem; } }
    @media (min-width: 768px) {
      .top-order__link {
        font-size: 0.875rem; } }
    @media (min-width: 992px) {
      .top-order__link {
        font-size: 0.625rem; } }
    @media (min-width: 992px) {
      .top-order__link {
        line-height: 1.7; } }
    @media (min-width: 480px) {
      .top-order__link {
        padding: 0.5625rem 1rem; } }
    @media (min-width: 768px) {
      .top-order__link {
        padding: 0.75rem 1.5rem; } }
    @media (min-width: 992px) {
      .top-order__link {
        padding: 0.5rem 5px; } }
    @media (min-width: 480px) {
      .top-order__link {
        width: 200px; } }
    @media (min-width: 768px) {
      .top-order__link {
        width: 240px; } }
    @media (min-width: 992px) {
      .top-order__link {
        width: 130px; } }
    @media (min-width: 1200px) {
      .top-order__link {
        width: 146px; } }
    @media (max-width: 479.98px) {
      .top-order__link {
        text-transform: none; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .top-order__link {
        border-width: .125rem;
        font-weight: 700; } }
  .top-order__price {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 0.875rem; }
    @media (min-width: 480px) {
      .top-order__price {
        font-size: 1.3125rem; } }
    @media (min-width: 768px) {
      .top-order__price {
        font-size: 1.5rem; } }
    @media (min-width: 992px) {
      .top-order__price {
        font-size: 1.125rem; } }
    @media (min-width: 1200px) {
      .top-order__price {
        font-size: 1.25rem; } }
    .top-order__price-box {
      font-weight: 500;
      text-align: center;
      padding: 1.25rem .25rem 0;
      font-size: 0.875rem; }
      @media (min-width: 480px) {
        .top-order__price-box {
          font-size: 1.125rem; } }
      @media (min-width: 992px) {
        .top-order__price-box {
          font-size: 0.875rem; } }
      @media (min-width: 480px) {
        .top-order__price-box {
          padding-left: .5rem;
          padding-right: .5rem; } }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .top-order__price-box {
          width: 240px; } }
      @media (min-width: 992px) {
        .top-order__price-box {
          padding-left: 0;
          padding-right: 0; } }
      @media (min-width: 1200px) {
        .top-order__price-box {
          padding-left: 8px;
          padding-right: 8px; } }
  .top-order__title {
    margin-bottom: 1.25rem; }
    @media (max-width: 479.98px) {
      .top-order__title {
        font-size: 1.5rem; } }
    @media (min-width: 480px) {
      .top-order__title {
        margin-bottom: 1.875rem; } }
    @media (min-width: 992px) {
      .top-order__title {
        margin-bottom: 2.375rem; } }
    @media (min-width: 1200px) {
      .top-order__title {
        margin-bottom: 3rem; } }
  .top-order__annotation {
    font-size: 0.8125rem; }
    @media (min-width: 480px) {
      .top-order__annotation {
        font-size: 1rem; } }
    @media (min-width: 768px) {
      .top-order__annotation {
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .top-order__annotation {
        font-size: 1rem; } }

.retail-nuances__container {
  background-color: #fff;
  border-radius: .9375rem;
  box-shadow: 0.1875rem 0.1875rem 1.25rem rgba(0, 0, 0, 0.07);
  padding: 1.25rem 0.625rem;
  position: relative; }
  @media (min-width: 480px) {
    .retail-nuances__container {
      padding: 1.875rem 1.25rem; } }
  @media (min-width: 768px) {
    .retail-nuances__container {
      padding: 1.875rem 0.9375rem; } }
  @media (min-width: 992px) {
    .retail-nuances__container {
      padding: 1.5rem 0.9375rem; } }
  .retail-nuances__container::before {
    position: absolute;
    top: 0;
    left: 50%;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    transform: translate(-50%, -50%);
    width: 1.875rem;
    height: 1.875rem;
    line-height: 1.875rem;
    font-size: 0.75rem; }
    @media (min-width: 480px) {
      .retail-nuances__container::before {
        width: 2.25rem; } }
    @media (min-width: 480px) {
      .retail-nuances__container::before {
        height: 2.25rem; } }
    @media (min-width: 480px) {
      .retail-nuances__container::before {
        line-height: 2.25rem; } }
    @media (min-width: 480px) {
      .retail-nuances__container::before {
        font-size: 1rem; } }
  .retail-nuances__container--plus::before {
    content: "";
    background-image: linear-gradient(#afea75 0%, #9dd57a 100%); }
  .retail-nuances__container--minus::before {
    content: "";
    background-image: linear-gradient(#ea7575 0%, #e05f5f 100%); }
  .retail-nuances__container dt {
    font-size: 1rem; }
    @media (min-width: 480px) {
      .retail-nuances__container dt {
        font-size: 1.3125rem; } }
    @media (min-width: 992px) {
      .retail-nuances__container dt {
        font-size: 1.125rem; } }
  .retail-nuances__container dd {
    font-size: 0.8125rem; }
    @media (min-width: 480px) {
      .retail-nuances__container dd {
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .retail-nuances__container dd {
        font-size: 0.875rem; } }
    .retail-nuances__container dd:last-child {
      margin-bottom: 0; }

@media (min-width: 992px) {
  .retail-nuances__image {
    align-self: center; } }

.retail-nuances__image img {
  width: 100%;
  height: auto;
  display: block;
  margin: 1.25rem 0 2.1875rem; }
  @media (min-width: 480px) {
    .retail-nuances__image img {
      margin: 1.875rem 0 3rem; } }
  @media (min-width: 992px) {
    .retail-nuances__image img {
      margin: 0; } }
  @media (min-width: 1200px) {
    .retail-nuances__image img {
      margin: 0 2.5rem; } }
  @media (min-width: 1200px) {
    .retail-nuances__image img {
      max-width: 21.875rem; } }

.retail-nuances__title {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem; }
  @media (min-width: 480px) {
    .retail-nuances__title {
      margin-bottom: 3rem; } }
  @media (min-width: 992px) {
    .retail-nuances__title {
      margin-bottom: 2.75rem; } }
  @media (min-width: 1200px) {
    .retail-nuances__title {
      margin-bottom: 2.25rem; } }

.client-cars__title {
  padding: 0 20px;
  margin: 0 0 1.25rem; }
  @media (min-width: 480px) {
    .client-cars__title {
      margin: 0.75rem 0 1.875rem; } }
  @media (min-width: 768px) {
    .client-cars__title {
      margin: 1.375rem 0 2.875rem; } }
  @media (min-width: 992px) {
    .client-cars__title {
      margin: 0 0 1.875rem; } }

.client-cars__item {
  padding: 0 20px 1.625rem; }
  @media (min-width: 480px) {
    .client-cars__item {
      padding-bottom: .75rem; } }
  .client-cars__item-inner {
    background-color: #fff;
    box-shadow: 0 -0.0625rem 0.9375rem rgba(0, 0, 0, 0.1);
    position: relative; }
    @media (min-width: 768px) {
      .client-cars__item-inner {
        width: 43.125rem; } }
    @media (min-width: 992px) {
      .client-cars__item-inner {
        width: 48.125rem; } }
    @media (min-width: 1200px) {
      .client-cars__item-inner {
        width: 45.625rem; } }

@media (min-width: 768px) and (min-width: 768px) {
  .client-cars__main-image {
    float: left;
    clear: left;
    width: 350px; } }

.client-cars__main-image img {
  width: 100%;
  height: auto;
  display: block; }

.client-cars__model {
  text-align: center;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 0.825rem;
  padding: 0.625rem 0 0.3125rem; }
  @media (min-width: 480px) {
    .client-cars__model {
      font-size: 1.3125rem; } }
  @media (min-width: 768px) {
    .client-cars__model {
      font-size: 1.125rem; } }
  @media (min-width: 480px) {
    .client-cars__model {
      padding: 1.25rem 0 0.625rem; } }
  @media (min-width: 768px) {
    .client-cars__model {
      padding: 0.6875rem 0; } }
  @media (min-width: 768px) {
    .client-cars__model {
      line-height: 1.6; } }
  @media (min-width: 768px) {
    .client-cars__model {
      background-color: #dfdfdf; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .client-cars__model {
      float: left;
      clear: left;
      width: 350px; } }

.client-cars__secondary_images {
  display: none; }
  @media (min-width: 768px) {
    .client-cars__secondary_images {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .client-cars__secondary_images {
      float: left;
      clear: left;
      width: 350px; } }
  @media (min-width: 768px) {
      .client-cars__secondary_images img {
        display: block;
        width: 50%;
        height: auto; } }

.client-cars__short-info {
  text-align: center;
  color: #000;
  font-size: 0.8125rem;
  line-height: 1.6;
  padding: 0.375rem 0 0.25rem; }
  @media (min-width: 768px) {
    .client-cars__short-info {
      overflow: hidden; } }
  @media (min-width: 480px) {
    .client-cars__short-info {
      font-size: 1rem; } }
  @media (min-width: 768px) {
    .client-cars__short-info {
      font-size: 0.8125rem; } }
  @media (min-width: 480px) {
    .client-cars__short-info {
      line-height: 1.5; } }
  @media (min-width: 480px) {
    .client-cars__short-info {
      padding: 0.375rem 0 0.25rem; } }
  @media (min-width: 768px) {
    .client-cars__short-info {
      padding: 1rem 0 0.875rem; } }
  @media (max-width: 767.98px) {
    .client-cars__short-info {
      background-color: #e8e8e8; } }
  .client-cars__short-info .icon, .client-cars__short-info .slick-slider .slick-prev, .slick-slider .client-cars__short-info .slick-prev, .client-cars__short-info .slick-slider .slick-next, .slick-slider .client-cars__short-info .slick-next, .client-cars__short-info .retail-nuances__container::before, .client-cars__short-info .how-it__btn::after, .client-cars__short-info .retail-prices__list > li::before, .client-cars__short-info .lot-carousel .slick-prev, .lot-carousel .client-cars__short-info .slick-prev, .client-cars__short-info .report-carousel .slick-prev, .report-carousel .client-cars__short-info .slick-prev, .client-cars__short-info .lot-carousel .slick-next, .lot-carousel .client-cars__short-info .slick-next, .client-cars__short-info .report-carousel .slick-next, .report-carousel .client-cars__short-info .slick-next {
    display: block;
    color: #474747;
    font-size: 1.25rem; }
    @media (min-width: 480px) {
      .client-cars__short-info .icon, .client-cars__short-info .slick-slider .slick-prev, .slick-slider .client-cars__short-info .slick-prev, .client-cars__short-info .slick-slider .slick-next, .slick-slider .client-cars__short-info .slick-next, .client-cars__short-info .retail-nuances__container::before, .client-cars__short-info .how-it__btn::after, .client-cars__short-info .retail-prices__list > li::before, .client-cars__short-info .lot-carousel .slick-prev, .lot-carousel .client-cars__short-info .slick-prev, .client-cars__short-info .report-carousel .slick-prev, .report-carousel .client-cars__short-info .slick-prev, .client-cars__short-info .lot-carousel .slick-next, .lot-carousel .client-cars__short-info .slick-next, .client-cars__short-info .report-carousel .slick-next, .report-carousel .client-cars__short-info .slick-next {
        font-size: 1.625rem; } }
    @media (min-width: 768px) {
      .client-cars__short-info .icon, .client-cars__short-info .slick-slider .slick-prev, .slick-slider .client-cars__short-info .slick-prev, .client-cars__short-info .slick-slider .slick-next, .slick-slider .client-cars__short-info .slick-next, .client-cars__short-info .retail-nuances__container::before, .client-cars__short-info .how-it__btn::after, .client-cars__short-info .retail-prices__list > li::before, .client-cars__short-info .lot-carousel .slick-prev, .lot-carousel .client-cars__short-info .slick-prev, .client-cars__short-info .report-carousel .slick-prev, .report-carousel .client-cars__short-info .slick-prev, .client-cars__short-info .lot-carousel .slick-next, .lot-carousel .client-cars__short-info .slick-next, .client-cars__short-info .report-carousel .slick-next, .report-carousel .client-cars__short-info .slick-next {
        font-size: 1.375rem; } }

.client-cars__short-info .client-cars__col-inner {
  justify-content: space-evenly !important; }

@media (min-width: 768px) {
  .client-cars__info {
    overflow: hidden; } }

.client-cars__info-inner {
  padding: 0 0.9375rem; }
  @media (min-width: 480px) {
    .client-cars__info-inner {
      padding: 0 1.875rem; } }
  @media (min-width: 768px) {
    .client-cars__info-inner {
      padding: 0 1.25rem; } }
  @media (min-width: 992px) {
    .client-cars__info-inner {
      padding: 0 2.5rem; } }
  @media (min-width: 1200px) {
    .client-cars__info-inner {
      padding: 0 2.125rem; } }

.client-cars__prices {
  font-size: 0.8125rem; }
  @media (min-width: 480px) {
    .client-cars__prices {
      font-size: 1.125rem; } }
  @media (min-width: 768px) {
    .client-cars__prices {
      font-size: 0.875rem; } }
  .client-cars__prices > div {
    padding: .0625rem 0; }
    @media (min-width: 480px) and (max-width: 767.98px) {
      .client-cars__prices > div {
        padding: 0; } }

@media (min-width: 768px) {
  .client-cars__buttons {
    margin: 0 -10px; } }

.client-cars__buttons > div {
  margin: 1.125rem 0 -1.5625rem; }
  @media (min-width: 480px) {
    .client-cars__buttons > div {
      margin: 0.9375rem 0 1.875rem; } }
  @media (min-width: 768px) {
    .client-cars__buttons > div {
      margin: 1.125rem 0 1rem; } }
  @media (min-width: 768px) {
    .client-cars__buttons > div {
      padding: 0 10px; } }

@media (min-width: 768px) {
  .client-cars__buttons .btn {
    font-size: .75rem;
    font-weight: 600; }
    .client-cars__buttons .btn.btn-orange {
      line-height: 1.7;
      padding: .5rem; } }

.client-cars__buttons .btn-youtube {
  font-size: .75rem;
  width: 180px; }
  .client-cars__buttons .btn-youtube[href=""] {
    display: none !important; }
  .client-cars__buttons .btn-youtube br {
    display: inline-block; }
  @media (min-width: 768px) {
    .client-cars__buttons .btn-youtube {
      width: 140px;
      padding: .25rem; }
      .client-cars__buttons .btn-youtube .icon, .client-cars__buttons .btn-youtube .slick-slider .slick-prev, .slick-slider .client-cars__buttons .btn-youtube .slick-prev, .client-cars__buttons .btn-youtube .slick-slider .slick-next, .slick-slider .client-cars__buttons .btn-youtube .slick-next, .client-cars__buttons .btn-youtube .retail-nuances__container::before, .client-cars__buttons .btn-youtube .how-it__btn::after, .client-cars__buttons .btn-youtube .retail-prices__list > li::before, .client-cars__buttons .btn-youtube .lot-carousel .slick-prev, .lot-carousel .client-cars__buttons .btn-youtube .slick-prev, .client-cars__buttons .btn-youtube .report-carousel .slick-prev, .report-carousel .client-cars__buttons .btn-youtube .slick-prev, .client-cars__buttons .btn-youtube .lot-carousel .slick-next, .lot-carousel .client-cars__buttons .btn-youtube .slick-next, .client-cars__buttons .btn-youtube .report-carousel .slick-next, .report-carousel .client-cars__buttons .btn-youtube .slick-next {
        font-size: 1.5rem; } }
  @media (min-width: 992px) {
    .client-cars__buttons .btn-youtube {
      width: 150px; } }

.client-cars__carousel {
  --speed: 300; }
  @media (min-width: 1200px) {
    .client-cars__carousel.slick-slider {
      user-select: auto; } }

@media (min-width: 768px) {
  .client-cars .slick-slide {
    transform: scale(0.8);
    transition: transform 300ms ease; } }
  @media (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .client-cars .slick-slide {
      transition: none; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .client-cars .slick-slide .client-cars__item-inner {
      margin: 0 -2.15625rem; } }
  @media (min-width: 768px) and (min-width: 992px) {
    .client-cars .slick-slide .client-cars__item-inner {
      margin: 0 -2.40625rem; } }
  @media (min-width: 768px) and (min-width: 1200px) {
    .client-cars .slick-slide .client-cars__item-inner {
      margin: 0 -2.28125rem; } }

@media (min-width: 768px) {
    .client-cars .slick-slide .client-cars__item-inner::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -2px;
      background-color: rgba(249, 249, 249, 0.6);
      z-index: 1;
      pointer-events: none;
      transition: background-color 300ms ease; } }
    @media (min-width: 768px) and (prefers-reduced-motion: reduce) {
      .client-cars .slick-slide .client-cars__item-inner::before {
        transition: none; } }

@media (min-width: 768px) {
    .client-cars .slick-slide.slick-center {
      transform: scale(1); }
      .client-cars .slick-slide.slick-center .client-cars__item-inner::before {
        background-color: transparent; } }

@media (min-width: 768px) and (min-width: 1200px) {
  .client-cars .slick-prev, .client-cars .slick-next {
    position: absolute;
    z-index: 2;
    top: 39%; }
    .client-cars .slick-prev::before, .client-cars .slick-next::before {
      font-size: 3.125rem; }
  .client-cars .slick-prev {
    left: 50%;
    margin-left: calc(-55.625rem/2);
    transform: translateX(-50%); }
  .client-cars .slick-next {
    right: 50%;
    margin-right: calc(-55.625rem/2);
    transform: translateX(50%); } }

.client-cars__button {
  width: 200px; }
  @media (min-width: 480px) {
    .client-cars__button {
      width: 180px; } }
  @media (min-width: 768px) {
    .client-cars__button {
      width: 140px; } }
  @media (min-width: 992px) {
    .client-cars__button {
      width: 150px; } }

.how-it__title {
  margin-bottom: 1.25rem; }
  @media (min-width: 768px) {
    .how-it__title {
      margin-bottom: 1.875rem; } }

.how-it__list {
  counter-reset: how-it; }
  .how-it__list dt:not(:first-child) {
    margin-top: 0.9375rem; }
    @media (min-width: 480px) {
      .how-it__list dt:not(:first-child) {
        margin-top: 1.25rem; } }
  .how-it__list dd {
    padding: 0;
    font-size: 0.8125rem; }
    @media (min-width: 480px) {
      .how-it__list dd {
        padding: 0 0 0 5px; } }
    @media (min-width: 768px) {
      .how-it__list dd {
        padding: 0 1.875rem 0 3.75rem; } }
    @media (min-width: 992px) {
      .how-it__list dd {
        padding: 0 5rem 0 4.375rem; } }
    @media (min-width: 480px) {
      .how-it__list dd {
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .how-it__list dd {
        font-size: 1rem; } }
    @media (min-width: 1200px) {
      .how-it__list dd {
        font-size: 1.125rem; } }
    .how-it__list dd p {
      margin-top: 1.25rem;
      margin-bottom: 0; }
      @media (max-width: 479.98px) {
        .how-it__list dd p:last-of-type {
          padding-bottom: .3125rem; } }

.how-it__btn {
  color: #fff;
  background-color: #e55e43;
  border-color: #e55e43;
  box-shadow: none;
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 600;
  text-transform: none;
  line-height: 1.2;
  padding: .3125rem;
  border: none;
  color: #1a1a1a;
  background-color: #d0cfcf;
  text-align: left;
  font-size: 0.8125rem;
  border-radius: calc(1.875rem + .625rem); }
  .how-it__btn:hover {
    color: #212529;
    background-color: #ec8672;
    border-color: #ec8672; }
  .how-it__btn:focus, .how-it__btn.focus {
    color: #212529;
    background-color: #ec8672;
    border-color: #ec8672;
    box-shadow: 0 0 0 0.2rem rgba(233, 118, 95, 0.5); }
  .how-it__btn.disabled, .how-it__btn:disabled {
    color: #fff;
    background-color: #e55e43;
    border-color: #e55e43; }
  .how-it__btn:not(:disabled):not(.disabled):active, .how-it__btn:not(:disabled):not(.disabled).active,
  .show > .how-it__btn.dropdown-toggle {
    color: #212529;
    background-color: #ec8672;
    border-color: #ec8672; }
    .how-it__btn:not(:disabled):not(.disabled):active:focus, .how-it__btn:not(:disabled):not(.disabled).active:focus,
    .show > .how-it__btn.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 118, 95, 0.5); }
  @media (min-width: 480px) {
    .how-it__btn {
      font-size: 1.3125rem; } }
  @media (min-width: 992px) {
    .how-it__btn {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    .how-it__btn {
      font-size: 1.3125rem; } }
  @media (min-width: 480px) {
    .how-it__btn {
      border-radius: calc(3.125rem + .625rem); } }
  @media (min-width: 768px) {
    .how-it__btn {
      border-radius: calc(2.5rem + .625rem); } }
  .how-it__btn::before {
    counter-increment: how-it;
    content: counter(how-it);
    color: #e55e43;
    background-color: #fff;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    flex-shrink: 0;
    font-size: 1rem;
    width: 1.875rem;
    height: 1.875rem;
    line-height: 1.875rem;
    margin-right: 0.625rem; }
    @media (min-width: 480px) {
      .how-it__btn::before {
        font-size: 1.3125rem; } }
    @media (min-width: 992px) {
      .how-it__btn::before {
        font-size: 1.125rem; } }
    @media (min-width: 1200px) {
      .how-it__btn::before {
        font-size: 1.3125rem; } }
    @media (min-width: 480px) {
      .how-it__btn::before {
        width: 3.125rem; } }
    @media (min-width: 768px) {
      .how-it__btn::before {
        width: 2.5rem; } }
    @media (min-width: 480px) {
      .how-it__btn::before {
        height: 3.125rem; } }
    @media (min-width: 768px) {
      .how-it__btn::before {
        height: 2.5rem; } }
    @media (min-width: 480px) {
      .how-it__btn::before {
        line-height: 3.125rem; } }
    @media (min-width: 768px) {
      .how-it__btn::before {
        line-height: 2.5rem; } }
    @media (min-width: 768px) {
      .how-it__btn::before {
        margin-right: 0.9375rem; } }
    @media (min-width: 992px) {
      .how-it__btn::before {
        margin-right: 1.5625rem; } }
  .how-it__btn::after {
    content: "";
    display: inline-block;
    color: #e55e43;
    margin-left: auto;
    flex-shrink: 0;
    font-size: 0.625rem;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    transform-origin: 15px 4px;
    transition: color 0.15s ease-in-out, transform 0.3s ease-in-out; }
    @media (min-width: 480px) {
      .how-it__btn::after {
        font-size: 0.9375rem; } }
    @media (min-width: 480px) {
      .how-it__btn::after {
        padding-right: 0.9375rem; } }
    @media (min-width: 768px) {
      .how-it__btn::after {
        padding-right: 1.5625rem; } }
    @media (min-width: 1200px) {
      .how-it__btn::after {
        padding-right: 1.25rem; } }
    @media (min-width: 768px) {
      .how-it__btn::after {
        padding-left: 0.9375rem; } }
    @media (min-width: 992px) {
      .how-it__btn::after {
        padding-left: 1.5625rem; } }
    @media (min-width: 480px) {
      .how-it__btn::after {
        transform-origin: 17.5px 6.5px; } }
    @media (min-width: 768px) {
      .how-it__btn::after {
        transform-origin: 22.5px 6.5px; } }
    @media (min-width: 992px) {
      .how-it__btn::after {
        transform-origin: 32.5px 6.5px; } }
    @media (prefers-reduced-motion: reduce) {
      .how-it__btn::after {
        transition: none; } }
  .how-it__btn:hover, .how-it__btn:focus, .how-it__btn:active {
    color: #fff !important; }
    .how-it__btn:hover::after, .how-it__btn:focus::after, .how-it__btn:active::after {
      color: #fff; }
  .how-it__btn:not(.collapsed) {
    background-color: #e55e43;
    color: #fff; }
    .how-it__btn:not(.collapsed)::after {
      color: #fff;
      transform: rotate(-180deg); }

.profit__title {
  margin-bottom: 1.25rem; }
  @media (min-width: 768px) {
    .profit__title {
      margin-bottom: 1.875rem; } }

.profit__list {
  margin-bottom: -15px; }
  @media (min-width: 768px) {
    .profit__list {
      margin-bottom: -30px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .profit__list-item:not(:nth-child(-n + 8)) {
      display: none !important; } }

.profit-card {
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 0.125rem 0.125rem 1.25rem rgba(11, 11, 11, 0.05);
  border-radius: 0.625rem;
  margin-bottom: 15px;
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out; }
  @media (min-width: 480px) {
    .profit-card {
      border-radius: 1.25rem; } }
  @media (min-width: 768px) {
    .profit-card {
      margin-bottom: 30px; } }
  @media (prefers-reduced-motion: reduce) {
    .profit-card {
      transition: none; } }
  .profit-card__header {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
    padding: 1.25rem 0.625rem; }
    @media (min-width: 480px) {
      .profit-card__header {
        font-size: 1.3125rem; } }
    @media (min-width: 992px) {
      .profit-card__header {
        font-size: 1.125rem; } }
    @media (min-width: 480px) {
      .profit-card__header {
        padding: 1.25rem 0.625rem 1rem; } }
    @media (min-width: 1200px) {
      .profit-card__header {
        padding: 1.375rem 1.5625rem 1rem; } }
    .profit-card__header .icon, .profit-card__header .slick-slider .slick-prev, .slick-slider .profit-card__header .slick-prev, .profit-card__header .slick-slider .slick-next, .slick-slider .profit-card__header .slick-next, .profit-card__header .retail-nuances__container::before, .profit-card__header .how-it__btn::after, .profit-card__header .retail-prices__list > li::before, .profit-card__header .lot-carousel .slick-prev, .lot-carousel .profit-card__header .slick-prev, .profit-card__header .report-carousel .slick-prev, .report-carousel .profit-card__header .slick-prev, .profit-card__header .lot-carousel .slick-next, .lot-carousel .profit-card__header .slick-next, .profit-card__header .report-carousel .slick-next, .report-carousel .profit-card__header .slick-next {
      flex-shrink: 0;
      color: #e55e43;
      font-size: 2.5rem;
      margin-right: 0.625rem; }
      @media (min-width: 480px) {
        .profit-card__header .icon, .profit-card__header .slick-slider .slick-prev, .slick-slider .profit-card__header .slick-prev, .profit-card__header .slick-slider .slick-next, .slick-slider .profit-card__header .slick-next, .profit-card__header .retail-nuances__container::before, .profit-card__header .how-it__btn::after, .profit-card__header .retail-prices__list > li::before, .profit-card__header .lot-carousel .slick-prev, .lot-carousel .profit-card__header .slick-prev, .profit-card__header .report-carousel .slick-prev, .report-carousel .profit-card__header .slick-prev, .profit-card__header .lot-carousel .slick-next, .lot-carousel .profit-card__header .slick-next, .profit-card__header .report-carousel .slick-next, .report-carousel .profit-card__header .slick-next {
          font-size: 3.125rem; } }
      @media (min-width: 480px) {
        .profit-card__header .icon, .profit-card__header .slick-slider .slick-prev, .slick-slider .profit-card__header .slick-prev, .profit-card__header .slick-slider .slick-next, .slick-slider .profit-card__header .slick-next, .profit-card__header .retail-nuances__container::before, .profit-card__header .how-it__btn::after, .profit-card__header .retail-prices__list > li::before, .profit-card__header .lot-carousel .slick-prev, .lot-carousel .profit-card__header .slick-prev, .profit-card__header .report-carousel .slick-prev, .report-carousel .profit-card__header .slick-prev, .profit-card__header .lot-carousel .slick-next, .lot-carousel .profit-card__header .slick-next, .profit-card__header .report-carousel .slick-next, .report-carousel .profit-card__header .slick-next {
          margin-right: 0.9375rem; } }
      @media (min-width: 768px) {
        .profit-card__header .icon, .profit-card__header .slick-slider .slick-prev, .slick-slider .profit-card__header .slick-prev, .profit-card__header .slick-slider .slick-next, .slick-slider .profit-card__header .slick-next, .profit-card__header .retail-nuances__container::before, .profit-card__header .how-it__btn::after, .profit-card__header .retail-prices__list > li::before, .profit-card__header .lot-carousel .slick-prev, .lot-carousel .profit-card__header .slick-prev, .profit-card__header .report-carousel .slick-prev, .report-carousel .profit-card__header .slick-prev, .profit-card__header .lot-carousel .slick-next, .lot-carousel .profit-card__header .slick-next, .profit-card__header .report-carousel .slick-next, .report-carousel .profit-card__header .slick-next {
          margin-right: 0.625rem; } }
      @media (min-width: 992px) {
        .profit-card__header .icon, .profit-card__header .slick-slider .slick-prev, .slick-slider .profit-card__header .slick-prev, .profit-card__header .slick-slider .slick-next, .slick-slider .profit-card__header .slick-next, .profit-card__header .retail-nuances__container::before, .profit-card__header .how-it__btn::after, .profit-card__header .retail-prices__list > li::before, .profit-card__header .lot-carousel .slick-prev, .lot-carousel .profit-card__header .slick-prev, .profit-card__header .report-carousel .slick-prev, .report-carousel .profit-card__header .slick-prev, .profit-card__header .lot-carousel .slick-next, .lot-carousel .profit-card__header .slick-next, .profit-card__header .report-carousel .slick-next, .report-carousel .profit-card__header .slick-next {
          margin-right: 0.9375rem; } }
      @media (min-width: 1200px) {
        .profit-card__header .icon, .profit-card__header .slick-slider .slick-prev, .slick-slider .profit-card__header .slick-prev, .profit-card__header .slick-slider .slick-next, .slick-slider .profit-card__header .slick-next, .profit-card__header .retail-nuances__container::before, .profit-card__header .how-it__btn::after, .profit-card__header .retail-prices__list > li::before, .profit-card__header .lot-carousel .slick-prev, .lot-carousel .profit-card__header .slick-prev, .profit-card__header .report-carousel .slick-prev, .report-carousel .profit-card__header .slick-prev, .profit-card__header .lot-carousel .slick-next, .lot-carousel .profit-card__header .slick-next, .profit-card__header .report-carousel .slick-next, .report-carousel .profit-card__header .slick-next {
          margin-right: 1.25rem; } }
    .profit-card__header span {
      position: relative;
      top: .25rem; }
  .profit-card__body {
    border-top: 0.0625rem solid #e5e5e5;
    font-size: 0.8125rem;
    padding: 0.625rem; }
    @media (min-width: 480px) {
      .profit-card__body {
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .profit-card__body {
        font-size: 1rem; } }
    @media (min-width: 480px) {
      .profit-card__body {
        padding: 0.9375rem 1.25rem 1.25rem; } }
    @media (min-width: 768px) {
      .profit-card__body {
        padding: 0.9375rem 0.625rem; } }
    @media (min-width: 992px) {
      .profit-card__body {
        padding: 0.9375rem 0.625rem 1.25rem; } }
    @media (min-width: 1200px) {
      .profit-card__body {
        padding: 0.9375rem 0.9375rem 1.5625rem; } }

@media (min-width: 1200px) {
  .profit__list-item:hover .profit-card {
    box-shadow: 0.125rem 0.125rem 1.25rem rgba(11, 11, 11, 0.2);
    transform: translateY(-0.625rem); } }

.retail-prices__title {
  margin-bottom: 1.25rem; }
  @media (min-width: 768px) {
    .retail-prices__title {
      margin-bottom: 1.875rem; } }

.retail-prices__items {
  margin-bottom: -30px; }

.retail-prices__item {
  margin-bottom: 30px; }

.retail-prices__card {
  border-radius: 1.25rem;
  box-shadow: 0.125rem 0.25rem 1.875rem rgba(0, 0, 0, 0.1);
  border: .125rem solid transparent; }
  @media (min-width: 992px) {
    .retail-prices__card--active {
      transform: translateY(-0.625rem); } }
  .retail-prices__card--active.retail-prices__card--green {
    border-color: #a0e255; }
  .retail-prices__card--active.retail-prices__card--orange {
    border-color: #ed735b; }
  .retail-prices__card--active.retail-prices__card--purple {
    border-color: #8378fc; }
  .retail-prices__card-title {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
    left: -.125rem; }
    @media (min-width: 480px) {
      .retail-prices__card-title {
        margin-top: 1.875rem; } }
    @media (min-width: 480px) {
      .retail-prices__card-title {
        margin-bottom: 1.875rem; } }

.retail-prices__description {
  padding: 0 8px;
  line-height: 1.4;
  font-size: 0.8125rem; }
  @media (min-width: 480px) {
    .retail-prices__description {
      font-size: 1.125rem; } }
  @media (min-width: 768px) {
    .retail-prices__description {
      font-size: 0.9375rem; } }
  @media (min-width: 992px) {
    .retail-prices__description {
      font-size: 1rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .retail-prices__description {
      padding: 0 .5625rem; } }
  @media (min-width: 480px) and (max-width: 767.98px) {
    .retail-prices__description {
      padding-right: 18px;
      padding-left: 18px; } }
  @media (min-width: 768px) {
    .retail-prices__description {
      margin-top: 1.25rem; } }

.retail-prices__list {
  line-height: 1.2;
  margin: 1rem 0;
  color: #5c5c5c;
  font-size: 0.75rem; }
  @media (min-width: 480px) {
    .retail-prices__list {
      font-size: 0.875rem; } }
  @media (min-width: 992px) {
    .retail-prices__list {
      font-size: 0.8125rem; } }
  @media (min-width: 768px) {
    .retail-prices__list {
      margin-bottom: 2rem; } }
  .retail-prices__list > li {
    display: flex;
    margin: 0.5rem 13px; }
    @media (min-width: 480px) {
      .retail-prices__list > li {
        margin: 0.5rem 60px; } }
    @media (min-width: 768px) {
      .retail-prices__list > li {
        margin: 0.5rem 13px; } }
    @media (min-width: 992px) {
      .retail-prices__list > li {
        margin: 0.5rem 8px; } }
    @media (min-width: 1200px) {
      .retail-prices__list > li {
        margin: 0.5rem 35px 0.5rem; } }
    .retail-prices__list > li::before {
      content: "";
      flex-shrink: 0;
      font-size: 0.9375rem;
      margin-right: 0.625rem; }
      @media (min-width: 480px) {
        .retail-prices__list > li::before {
          font-size: 1.25rem; } }
      @media (min-width: 480px) {
        .retail-prices__list > li::before {
          margin-right: 17px; } }
      @media (min-width: 768px) {
        .retail-prices__list > li::before {
          margin-right: 0.75rem; } }
      @media (min-width: 992px) {
        .retail-prices__list > li::before {
          margin-right: 0.625rem; } }
      @media (min-width: 1200px) {
        .retail-prices__list > li::before {
          margin-right: 1rem; } }

.retail-prices__card--green .retail-prices__list > li::before,
.retail-prices__card--green .retail-prices__card-colored-text {
  color: #a0e255; }

.retail-prices__card--orange .retail-prices__list > li::before,
.retail-prices__card--orange .retail-prices__card-colored-text {
  color: #ed735b; }

.retail-prices__card--purple .retail-prices__list > li::before,
.retail-prices__card--purple .retail-prices__card-colored-text {
  color: #8378fc; }

.retail-header__description {
  font-size: 1rem; }
  @media (min-width: 480px) {
    .retail-header__description {
      font-size: 1.3125rem; } }
  @media (min-width: 1200px) {
    .retail-header__description {
      font-size: 1.5rem; } }

.retail-header__image img {
  width: 100%;
  height: auto;
  display: block; }
  @media (min-width: 992px) {
    .retail-header__image img {
      max-width: 375px;
      margin-left: auto; } }
  @media (min-width: 1200px) {
    .retail-header__image img {
      max-width: 445px; } }

@media (min-width: 480px) and (max-width: 767.98px) {
  .retail-header .btn {
    font-size: .8125rem;
    padding: .8125rem .25rem; } }

@media (min-width: 992px) {
  .retail-header__title {
    margin-right: -30px; } }

@media (max-width: 767.98px) {
  .retail-header .retail-header__buttons,
  .retail-header .row .retail-header__buttons-col {
    padding-right: 10px;
    padding-left: 10px; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .retail-header__button {
    font-size: 13px;
    padding: 13px 10px; } }

.retail-unloading__title {
  margin-bottom: 1.25rem; }
  @media (min-width: 768px) {
    .retail-unloading__title {
      margin-bottom: 1.875rem; } }

.retail-unloading__list > div {
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .retail-unloading__list > div {
      margin-bottom: 30px; } }

.retail-faq__title {
  margin-bottom: 1.25rem; }
  @media (min-width: 768px) {
    .retail-faq__title {
      margin-bottom: 1.875rem; } }

.retail-faq__more {
  padding-top: 1.25rem; }
  @media (min-width: 480px) {
    .retail-faq__more {
      padding-top: 1.875rem; } }

.retail-faq .faq__list.expanded {
  transition: height 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .retail-faq .faq__list.expanded {
      transition: none; } }

.retail-faq .faq__list:not(.expanded) > *:not(:nth-of-type(-n + 5)) {
  display: none; }

.delivery-price__title {
  margin-bottom: 1.25rem; }
  @media (min-width: 768px) {
    .delivery-price__title {
      margin-bottom: 1.875rem; } }

.delivery-price__subtitle {
  font-weight: 500;
  margin-bottom: 0.625rem; }
  @media (min-width: 992px) {
    .delivery-price__subtitle {
      font-weight: 600; } }
  @media (min-width: 768px) {
    .delivery-price__subtitle {
      margin-bottom: 1.25rem; } }
  @media (min-width: 992px) {
    .delivery-price__subtitle {
      margin-bottom: 1.875rem; } }

.delivery-price__radio {
  font-size: 1rem;
  line-height: 1.7; }
  @media (min-width: 480px) {
    .delivery-price__radio {
      font-size: 1.3125rem; } }
  @media (min-width: 992px) {
    .delivery-price__radio {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    .delivery-price__radio {
      font-size: 1.125rem; } }
  @media (min-width: 480px) {
    .delivery-price__radio {
      line-height: 1.4; } }
  .delivery-price__radio:not(:last-of-type) {
    margin-bottom: 0.875rem; }
    @media (min-width: 480px) {
      .delivery-price__radio:not(:last-of-type) {
        margin-bottom: 1.25rem; } }
    @media (min-width: 768px) {
      .delivery-price__radio:not(:last-of-type) {
        margin-bottom: 1.875rem; } }
    @media (min-width: 992px) {
      .delivery-price__radio:not(:last-of-type) {
        margin-bottom: 0; } }
  @media (min-width: 992px) {
    .delivery-price__radio {
      font-weight: 500;
      padding: 0; } }
  .delivery-price__radio-icon {
    display: none;
    opacity: .4;
    filter: drop-shadow(0.125rem 0.125rem 0.3125rem rgba(0, 0, 0, 0.15));
    transition: opacity 0.15s ease-in-out, filter 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .delivery-price__radio-icon {
        transition: none; } }
    @media (min-width: 992px) {
      .delivery-price__radio-icon {
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;
        height: 8.75rem;
        margin-bottom: 1.125rem; } }
    @media (min-width: 1200px) {
      .delivery-price__radio-icon {
        height: 10rem; } }
    @media (min-width: 992px) {
      .delivery-price__radio-icon .svg-icon__maps--ua {
        width: 13.125rem;
        height: 8.75rem; } }
    @media (min-width: 1200px) {
      .delivery-price__radio-icon .svg-icon__maps--ua {
        width: 15rem;
        height: 10rem; } }
    @media (min-width: 992px) {
      .delivery-price__radio-icon .svg-icon__maps--de {
        width: 6.375rem;
        height: 8.75rem; } }
    @media (min-width: 1200px) {
      .delivery-price__radio-icon .svg-icon__maps--de {
        width: 7.3125rem;
        height: 10rem; } }
    @media (min-width: 992px) {
      .delivery-price__radio-icon .svg-icon__maps--ge {
        width: 13.75rem;
        height: 6.875rem; } }
    @media (min-width: 1200px) {
      .delivery-price__radio-icon .svg-icon__maps--ge {
        width: 16.25rem;
        height: 8.125rem; } }
    @media (min-width: 992px) {
      .delivery-price__radio-icon .svg-icon__maps--lt {
        width: 8.125rem;
        height: 6.25rem; } }
    @media (min-width: 1200px) {
      .delivery-price__radio-icon .svg-icon__maps--lt {
        width: 9.875rem;
        height: 7.5rem; } }
  .custom-control-input:checked ~ .custom-control-label .delivery-price__radio-icon {
    opacity: 1; }
  .custom-control-input:not(:checked) ~ .custom-control-label:hover .delivery-price__radio-icon {
    opacity: 1;
    filter: drop-shadow(0.125rem 0.125rem 0.3125rem rgba(0, 0, 0, 0.2)); }
  @media (min-width: 992px) {
    .delivery-price__radio .custom-control-input:not(:checked) ~ .custom-control-label:hover {
      text-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2); } }
  @media (max-width: 479.98px) {
    .delivery-price__radio-list--overland {
      column-count: 2;
      break-inside: avoid-column; } }
  @media (min-width: 480px) {
    .delivery-price__radio-list--overland {
      display: flex; } }
  @media (min-width: 480px) and (max-width: 767.98px) {
    .delivery-price__radio-list--overland {
      flex-wrap: wrap; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .delivery-price__radio-list--overland {
      justify-content: space-between; } }
  @media (max-width: 479.98px) {
    .delivery-price__radio--overland {
      transform: translateZ(0); } }
  @media (min-width: 480px) and (max-width: 767.98px) {
    .delivery-price__radio--overland {
      width: 33.33%; } }
  .delivery-price__radio--overland i {
    font-size: 0.8125rem;
    display: block;
    margin-top: -.5em; }
    @media (min-width: 480px) {
      .delivery-price__radio--overland i {
        font-size: 0.9375rem; } }
    @media (min-width: 992px) {
      .delivery-price__radio--overland i {
        font-size: 1rem; } }
  .delivery-price__radio--overland img {
    height: auto; }
    @media (min-width: 992px) {
      .delivery-price__radio--overland img.delivery-icon-copart {
        width: 7.5rem; } }
    @media (min-width: 1200px) {
      .delivery-price__radio--overland img.delivery-icon-copart {
        width: 10rem; } }
    @media (min-width: 992px) {
      .delivery-price__radio--overland img.delivery-icon-iaai {
        width: 5.125rem; } }
    @media (min-width: 1200px) {
      .delivery-price__radio--overland img.delivery-icon-iaai {
        width: 6.75rem; } }
    @media (min-width: 992px) {
      .delivery-price__radio--overland img.delivery-icon-manheim {
        width: 5.875rem; } }
    @media (min-width: 1200px) {
      .delivery-price__radio--overland img.delivery-icon-manheim {
        width: 7.875rem; } }
    @media (min-width: 992px) {
      .delivery-price__radio--overland img.delivery-icon-impact {
        width: 8.625rem; } }
    @media (min-width: 1200px) {
      .delivery-price__radio--overland img.delivery-icon-impact {
        width: 11.3125rem; } }
  @media (min-width: 992px) {
    .delivery-price__radio--overland .delivery-price__radio-icon {
      height: 3.125rem; } }
  @media (min-width: 1200px) {
    .delivery-price__radio--overland .delivery-price__radio-icon {
      height: 4.1875rem; } }
  .delivery-price__radio .custom-control-label:before,
  .delivery-price__radio .custom-control-label:after {
    width: 1.25rem;
    height: 1.25rem;
    top: 0.10938rem; }
    @media (min-width: 480px) {
      .delivery-price__radio .custom-control-label:before,
      .delivery-price__radio .custom-control-label:after {
        top: 0.25rem; } }
    @media (min-width: 992px) {
      .delivery-price__radio .custom-control-label:before,
      .delivery-price__radio .custom-control-label:after {
        display: none; } }
  .delivery-price__radio .custom-control-label:after {
    background-size: 1rem; }
  .delivery-price__radio .custom-control-input:not(:checked) ~ .custom-control-label {
    color: #9a9a9a; }
  @media (min-width: 992px) {
    .delivery-price__radio {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      flex-basis: 0; }
      .delivery-price__radio-list {
        display: flex; }
      .delivery-price__radio .custom-control-label {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        border-bottom: .0625rem solid #d1d1d1;
        padding-bottom: 1.25rem; }
      .delivery-price__radio .custom-control-input:checked ~ .custom-control-label {
        border-bottom: 0.125rem solid #e55e43; } }
  @media (min-width: 1200px) {
    .delivery-price__radio .custom-control-label {
      padding-bottom: 2.5rem; } }

@media (min-width: 992px) {
  .delivery-price__table:not(.row) {
    column-count: 2;
    column-gap: 30px;
    break-inside: avoid-column; } }

.delivery-price__table-row {
  background-color: #f9f9f9;
  display: inline-flex;
  width: 100%;
  align-items: center;
  line-height: 1.6rem;
  position: relative;
  font-size: 0.875rem; }
  @media (min-width: 480px) {
    .delivery-price__table-row {
      font-size: 1rem; } }
  @media (min-width: 768px) {
    .delivery-price__table-row {
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    .delivery-price__table-row {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    .delivery-price__table-row {
      font-size: 1.125rem; } }
  @media (min-width: 768px) {
    .delivery-price__table-row {
      font-weight: 500; } }
  @media (min-width: 992px) {
    .delivery-price__table-row {
      font-weight: 400; } }
  @media (min-width: 1200px) {
    .delivery-price__table-row {
      font-weight: 500; } }
  .delivery-price__table-row span {
    padding: 0.25rem 10px; }

.delivery-price__table-head {
  background-color: #3e3e3e;
  color: #fff;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  display: flex;
  font-size: 1rem; }
  @media (min-width: 768px) {
    .delivery-price__table-head {
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    .delivery-price__table-head {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    .delivery-price__table-head {
      font-size: 1.125rem; } }

.delivery-price__table-row:not(.delivery-price__table-head):nth-of-type(odd) {
  background-color: #ededed; }

@media (min-width: 992px) {
  .delivery-price__table-row:not(.delivery-price__table-head):nth-of-type(4n+1):last-of-type::after, .delivery-price__table-row:not(.delivery-price__table-head):nth-of-type(4n+2):last-of-type::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 100%; }
  .delivery-price__table-row:not(.delivery-price__table-head):nth-of-type(4n+1):last-of-type::after {
    height: 200%; } }

.delivery-price__table-from, .delivery-price__table-to {
  flex-basis: 0;
  flex-grow: 1; }

@media (max-width: 991.98px) {
  .delivery-price__table-from {
    flex-grow: 3; }
  .delivery-price__table-to {
    flex-grow: 2; } }

.delivery-price__table-price {
  width: 4em;
  text-align: right; }

.delivery-price__scroll-top {
  position: sticky;
  bottom: 1rem;
  padding-top: 1rem;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1;
  color: #e55e43;
  cursor: pointer;
  text-align: right;
  visibility: hidden;
  text-shadow: 0.0625rem 0.0625rem rgba(0, 0, 0, 0.25);
  font-size: 0.875rem; }
  @media (min-width: 480px) {
    .delivery-price__scroll-top {
      font-size: 1rem; } }
  @media (min-width: 768px) {
    .delivery-price__scroll-top {
      font-size: 1.125rem; } }
  .delivery-price__scroll-top > div {
    visibility: hidden;
    display: inline-flex;
    align-items: center;
    opacity: 0;
    transition: opacity .3s ease-in-out, visibility .3s 0s; }
  .delivery-price__scroll-top--visible > div {
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease-in-out, visibility 0s; }
  .delivery-price__scroll-top-button {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    border-radius: 50%;
    background-color: #e55e43;
    font-size: 1rem;
    color: #fff;
    margin-left: .625rem; }
    .delivery-price__scroll-top-button .icon, .delivery-price__scroll-top-button .slick-slider .slick-prev, .slick-slider .delivery-price__scroll-top-button .slick-prev, .delivery-price__scroll-top-button .slick-slider .slick-next, .slick-slider .delivery-price__scroll-top-button .slick-next, .delivery-price__scroll-top-button .retail-nuances__container::before, .delivery-price__scroll-top-button .how-it__btn::after, .delivery-price__scroll-top-button .retail-prices__list > li::before, .delivery-price__scroll-top-button .lot-carousel .slick-prev, .lot-carousel .delivery-price__scroll-top-button .slick-prev, .delivery-price__scroll-top-button .report-carousel .slick-prev, .report-carousel .delivery-price__scroll-top-button .slick-prev, .delivery-price__scroll-top-button .lot-carousel .slick-next, .lot-carousel .delivery-price__scroll-top-button .slick-next, .delivery-price__scroll-top-button .report-carousel .slick-next, .report-carousel .delivery-price__scroll-top-button .slick-next {
      display: inline-block;
      transform: rotate(90deg); }

@media (min-width: 480px) and (max-width: 767.98px) {
  .container_p20 .delivery-price__row_odessa {
    margin-right: -10px;
    margin-left: -10px; }
  .container_p20 .delivery-price__row_odessa .col,
  .container_p20 .delivery-price__row_odessa [class*=col-] {
    padding-right: 10px;
    padding-left: 10px; } }

.sea-delivery__table {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
  font-size: 1rem; }
  @media (min-width: 480px) {
    .sea-delivery__table {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    .sea-delivery__table {
      font-size: 1.3125rem; } }
  @media (max-width: 767.98px) {
    .sea-delivery__table {
      max-width: 25rem;
      margin: 0 auto; } }
  .sea-delivery__table-row {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    line-height: 1.6; }
    @media (min-width: 480px) {
      .sea-delivery__table-row {
        line-height: 1.4; } }
    .sea-delivery__table-row:nth-of-type(even) {
      background-color: #ededed; }
    .sea-delivery__table-row span {
      padding: 0.25rem 15px; }
  .sea-delivery__table-head {
    background-color: #3e3e3e;
    color: #fff;
    font-weight: 600; }
  @media (min-width: 992px) {
    .sea-delivery__table--adaptive {
      display: flex;
      background-color: #ededed; } }
  @media (min-width: 992px) {
    .sea-delivery__table--adaptive .sea-delivery__table-row {
      flex-direction: column;
      text-align: center;
      flex-grow: 1; } }
  @media (min-width: 992px) {
    .sea-delivery__table--adaptive .sea-delivery__table-row span {
      font-weight: 700;
      line-height: 1.45;
      padding-top: .4375rem;
      padding-bottom: .4375rem; }
      .sea-delivery__table--adaptive .sea-delivery__table-row span:first-of-type {
        background-color: #3e3e3e;
        color: #fff;
        font-weight: 600; } }
  @media (min-width: 1200px) {
    .sea-delivery__table--adaptive .sea-delivery__table-row span {
      padding-top: .3125rem;
      padding-bottom: .3125rem; } }
  @media (min-width: 992px) {
    .sea-delivery__table--adaptive .sea-delivery__table-head {
      display: none; } }

@media (max-width: 479.98px) {
  .sea-delivery__notice {
    max-width: 25rem;
    margin: 0 auto; } }

@media (min-width: 768px) {
  .sea-delivery__notice {
    font-size: 1rem; } }

@media (min-width: 992px) {
  .sea-delivery__notice {
    font-weight: 500; } }

@media (min-width: 1200px) {
  .sea-delivery__notice {
    font-size: 1.125rem; } }

.wholesale-profit__title {
  margin-bottom: 2.25rem; }
  @media (min-width: 480px) {
    .wholesale-profit__title {
      margin-bottom: 3rem; } }

.wholesale-profit__list {
  counter-reset: wholesale-profit; }
  .wholesale-profit__list-title {
    font-size: 1.3125rem; }
    @media (min-width: 480px) {
      .wholesale-profit__list-title {
        font-size: 1.75rem; } }
    @media (min-width: 768px) {
      .wholesale-profit__list-title {
        font-size: 2rem; } }
  .wholesale-profit__list-item {
    counter-increment: wholesale-profit;
    position: relative;
    padding: 2.25rem 0 3rem; }
    @media (min-width: 768px) {
      .wholesale-profit__list-item {
        padding: 2.5rem 0 3.25rem; } }
    @media (min-width: 992px) {
      .wholesale-profit__list-item {
        padding: 3.125rem 0; } }
    @media (min-width: 1200px) {
      .wholesale-profit__list-item {
        padding: 4rem 0; } }
    @media (min-width: 992px) {
      .wholesale-profit__list-item:not(:last-of-type) {
        margin-bottom: 6.125rem; } }
    @media (min-width: 1200px) {
      .wholesale-profit__list-item:not(:last-of-type) {
        margin-bottom: 8.75rem; } }
    .wholesale-profit__list-item::before {
      content: '';
      background-color: #f9f9f9;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 54vw;
      z-index: -1; }
      @media (min-width: 480px) {
        .wholesale-profit__list-item::before {
          bottom: 44vw; } }
      @media (min-width: 768px) {
        .wholesale-profit__list-item::before {
          bottom: 40vw; } }
      @media (min-width: 992px) {
        .wholesale-profit__list-item::before {
          bottom: 0;
          left: auto;
          width: 100vw; } }
    @media (min-width: 992px) {
      .wholesale-profit__list-item:nth-child(odd)::before {
        right: 43.22917%; } }
    @media (min-width: 1200px) {
      .wholesale-profit__list-item:nth-child(odd)::before {
        right: 51.31579%; } }
    @media (min-width: 992px) {
      .wholesale-profit__list-item:nth-child(even)::before {
        left: 43.22917%; } }
    @media (min-width: 1200px) {
      .wholesale-profit__list-item:nth-child(even)::before {
        left: 51.31579%; } }
    @media (min-width: 992px) {
      .wholesale-profit__list-item--image-right::before {
        right: auto;
        left: 50%; } }
    .wholesale-profit__list-item::after {
      content: counter(wholesale-profit);
      position: absolute;
      top: 0;
      left: 50%;
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-weight: 700;
      color: #fff;
      background-color: #e55e43;
      border-radius: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
      font-size: 1.125rem;
      width: 2.1875rem;
      height: 2.1875rem;
      line-height: 2.1875rem; }
      @media (min-width: 768px) {
        .wholesale-profit__list-item::after {
          font-size: 1.5625rem; } }
      @media (min-width: 992px) {
        .wholesale-profit__list-item::after {
          font-size: 1.125rem; } }
      @media (min-width: 1200px) {
        .wholesale-profit__list-item::after {
          font-size: 1.5625rem; } }
      @media (min-width: 768px) {
        .wholesale-profit__list-item::after {
          width: 2.5rem; } }
      @media (min-width: 992px) {
        .wholesale-profit__list-item::after {
          width: 2.1875rem; } }
      @media (min-width: 1200px) {
        .wholesale-profit__list-item::after {
          width: 2.5rem; } }
      @media (min-width: 768px) {
        .wholesale-profit__list-item::after {
          height: 2.5rem; } }
      @media (min-width: 992px) {
        .wholesale-profit__list-item::after {
          height: 2.1875rem; } }
      @media (min-width: 1200px) {
        .wholesale-profit__list-item::after {
          height: 2.5rem; } }
      @media (min-width: 768px) {
        .wholesale-profit__list-item::after {
          line-height: 2.5rem; } }
      @media (min-width: 992px) {
        .wholesale-profit__list-item::after {
          line-height: 2.1875rem; } }
      @media (min-width: 1200px) {
        .wholesale-profit__list-item::after {
          line-height: 2.5rem; } }
    @media (min-width: 992px) {
      .wholesale-profit__list-item:nth-child(odd)::after {
        left: 56.77083%; } }
    @media (min-width: 1200px) {
      .wholesale-profit__list-item:nth-child(odd)::after {
        left: 48.68421%; } }
    @media (min-width: 992px) {
      .wholesale-profit__list-item:nth-child(even)::after {
        left: 43.22917%; } }
    @media (min-width: 1200px) {
      .wholesale-profit__list-item:nth-child(even)::after {
        left: 51.31579%; } }
    .wholesale-profit__list-item-cell {
      margin-bottom: 1.25rem; }
      @media (min-width: 480px) {
        .wholesale-profit__list-item-cell {
          margin-bottom: 1.875rem; } }
      @media (min-width: 992px) {
        .wholesale-profit__list-item-cell {
          margin-bottom: 0; } }
  .wholesale-profit__list-image img {
    filter: drop-shadow(0 0.4375rem 1.25rem rgba(0, 0, 0, 0.15)); }
  @media (min-width: 1200px) {
    .wholesale-profit__list-image--pad {
      margin-left: 4.125rem; } }
  @media (min-width: 1200px) {
    .wholesale-profit__list-item--image-right .wholesale-profit__list-image--pad {
      margin-left: 0;
      margin-right: 4.125rem; } }

@media (min-width: 992px) {
  .wholesale-profit .wholesale-images__1 {
    right: auto;
    left: 0; }
  .wholesale-profit .wholesale-images__2 {
    right: 0;
    left: auto; } }

.wholesale-profit__list-image.wholesale-images {
  padding-bottom: 62.5%; }
  @media (min-width: 480px) {
    .wholesale-profit__list-image.wholesale-images {
      padding-bottom: 50%; } }
  @media (min-width: 768px) {
    .wholesale-profit__list-image.wholesale-images {
      padding-bottom: 52.17391%; } }
  @media (min-width: 992px) {
    .wholesale-profit__list-image.wholesale-images {
      padding-bottom: 58.49057%; } }
  @media (min-width: 1200px) {
    .wholesale-profit__list-image.wholesale-images {
      right: auto;
      padding-bottom: 51.36986%;
      margin-left: 65px; } }

img.wholesale-profit__img {
  width: 62.85714%; }
  @media (min-width: 480px) {
    img.wholesale-profit__img {
      width: 56.81818%; } }
  @media (min-width: 768px) {
    img.wholesale-profit__img {
      width: 56.52174%; } }
  @media (min-width: 992px) {
    img.wholesale-profit__img {
      width: 69.81132%; } }
  @media (min-width: 1200px) {
    img.wholesale-profit__img {
      width: 61.65414%; } }

.wholesale-header__description {
  font-size: 1rem; }
  @media (min-width: 480px) {
    .wholesale-header__description {
      font-size: 18px; } }
  @media (min-width: 768px) {
    .wholesale-header__description {
      font-size: 21px; } }
  @media (min-width: 1200px) {
    .wholesale-header__description {
      font-size: 1.5rem; } }

.wholesale-header__image img {
  width: 100%;
  height: auto;
  display: block; }
  @media (min-width: 1200px) {
    .wholesale-header__image img {
      width: auto; } }

.wholesale-prices .retail-prices__card-title {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-weight: 700; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .wholesale-prices .retail-prices__card-title {
      font-size: 1rem;
      line-height: 1.5; } }

.wholesale-prices .retail-prices__price {
  font-size: 24px; }
  @media (min-width: 480px) {
    .wholesale-prices .retail-prices__price {
      font-size: 28px; } }
  @media (min-width: 768px) {
    .wholesale-prices .retail-prices__price {
      font-size: 32px; } }
  @media (min-width: 1200px) {
    .wholesale-prices .retail-prices__price {
      font-size: 36px; } }

.wholesale-prices .retail-prices__description {
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  font-size: 16px;
  font-weight: 500; }
  @media (min-width: 480px) {
    .wholesale-prices .retail-prices__description {
      font-size: 18px; } }
  @media (min-width: 768px) {
    .wholesale-prices .retail-prices__description {
      font-size: 17px; } }
  @media (min-width: 992px) {
    .wholesale-prices .retail-prices__description {
      font-size: 16px; } }
  @media (min-width: 1200px) {
    .wholesale-prices .retail-prices__description {
      font-size: 21px; } }

@media (max-width: 479.98px) {
  .retail-prices__card-title_broker {
    padding-right: 20px; } }

.about-me__avatar {
  display: inline-block;
  text-align: center;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 0.875rem; }
  @media (min-width: 480px) {
    .about-me__avatar {
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    .about-me__avatar {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    .about-me__avatar {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    .about-me__avatar {
      margin-right: 3.125rem; } }
  .about-me__avatar img {
    border-radius: 50%;
    background-image: linear-gradient(61.71deg, #fb7b52 14.44%, #fba352 88.5%);
    padding: 4%;
    width: 12.5rem;
    height: 12.5rem;
    margin-bottom: 0.625rem; }
    @media (min-width: 480px) {
      .about-me__avatar img {
        width: 15.625rem; } }
    @media (min-width: 768px) {
      .about-me__avatar img {
        width: 16.875rem; } }
    @media (min-width: 992px) {
      .about-me__avatar img {
        width: 18.125rem; } }
    @media (min-width: 1200px) {
      .about-me__avatar img {
        width: 18.75rem; } }
    @media (min-width: 480px) {
      .about-me__avatar img {
        height: 15.625rem; } }
    @media (min-width: 768px) {
      .about-me__avatar img {
        height: 16.875rem; } }
    @media (min-width: 992px) {
      .about-me__avatar img {
        height: 18.125rem; } }
    @media (min-width: 1200px) {
      .about-me__avatar img {
        height: 18.75rem; } }
    @media (min-width: 1200px) {
      .about-me__avatar img {
        margin-bottom: 0.9375rem; } }

.about-me__name {
  color: #e55e43; }

.about-me__description {
  background-color: #fff;
  border-radius: .625rem;
  box-shadow: 0 0.1875rem 0.9375rem rgba(0, 0, 0, 0.05);
  padding: 15px;
  font-size: 0.875rem; }
  @media (min-width: 480px) {
    .about-me__description {
      padding: 1.25rem; } }
  @media (min-width: 992px) {
    .about-me__description {
      padding: 30px; } }
  @media (min-width: 480px) {
    .about-me__description {
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    .about-me__description {
      font-size: 1rem; } }
  @media (min-width: 1200px) {
    .about-me__description {
      font-size: 1.125rem; } }
  @media (min-width: 768px) {
    .about-me__description {
      border-radius: 1.25rem;
      box-shadow: 0 0.1875rem 1.3125rem rgba(0, 0, 0, 0.1); } }

.calc {
  padding-top: 2rem; }
  @media (min-width: 1200px) {
    .calc {
      padding-top: 2.5rem; } }
  .calc:not(:last-of-type) {
    margin-bottom: 3.125rem; }
    @media (min-width: 480px) {
      .calc:not(:last-of-type) {
        margin-bottom: 3.75rem; } }
    @media (min-width: 768px) {
      .calc:not(:last-of-type) {
        margin-bottom: 4.375rem; } }
    @media (min-width: 992px) {
      .calc:not(:last-of-type) {
        margin-bottom: 5rem; } }
    @media (min-width: 1200px) {
      .calc:not(:last-of-type) {
        margin-bottom: 7.5rem; } }
  .calc__section:not(:last-of-type) {
    margin-bottom: 3.125rem; }
    @media (min-width: 480px) {
      .calc__section:not(:last-of-type) {
        margin-bottom: 3.75rem; } }
    @media (min-width: 768px) {
      .calc__section:not(:last-of-type) {
        margin-bottom: 4.375rem; } }
    @media (min-width: 992px) {
      .calc__section:not(:last-of-type) {
        margin-bottom: 5rem; } }
    @media (min-width: 1200px) {
      .calc__section:not(:last-of-type) {
        margin-bottom: 7.5rem; } }
  @media (max-width: 479.98px) {
    .calc-full-block {
      margin-left: -15px;
      margin-right: -15px; } }
  .calc .custom-control-label {
    font-size: 0.875rem;
    line-height: 1.8; }
    @media (min-width: 480px) {
      .calc .custom-control-label {
        font-size: 1rem; } }
    @media (min-width: 768px) {
      .calc .custom-control-label {
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .calc .custom-control-label {
        font-size: 1rem; } }
    @media (min-width: 1200px) {
      .calc .custom-control-label {
        font-size: 1.125rem; } }
    @media (min-width: 480px) {
      .calc .custom-control-label {
        line-height: 1.6; } }
    @media (min-width: 768px) {
      .calc .custom-control-label {
        line-height: 1.5; } }
    @media (min-width: 992px) {
      .calc .custom-control-label {
        line-height: 1.6; } }
    @media (min-width: 1200px) {
      .calc .custom-control-label {
        line-height: 1.5; } }
    .calc .custom-control-label small {
      display: block;
      margin-top: -.25em;
      font-style: italic;
      color: #7b7b7b;
      font-size: 0.8125rem; }
      @media (min-width: 480px) {
        .calc .custom-control-label small {
          font-size: 0.875rem; } }
      @media (min-width: 768px) {
        .calc .custom-control-label small {
          font-size: 0.9375rem; } }
      @media (min-width: 992px) {
        .calc .custom-control-label small {
          font-size: 0.875rem; } }
      @media (min-width: 1200px) {
        .calc .custom-control-label small {
          font-size: 0.9375rem; } }
  @media (max-width: 479.98px) {
    .calc-auction__group > .form-control {
      margin-bottom: .5rem; } }
  @media (min-width: 480px) {
    .calc-auction__group {
      position: relative;
      display: flex;
      align-items: stretch;
      width: 100%; }
      .calc-auction__group > .form-control {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0; }
      .calc-auction__group > .form-control:focus {
        z-index: 3; }
      .calc-auction__group > .form-control:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .calc-auction__group > .form-control:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; } }
  @media (max-width: 479.98px) {
    .calc-auction__group-append .btn {
      display: block;
      width: 100%; } }
  @media (min-width: 480px) {
    .calc-auction__group-append {
      display: flex;
      margin-left: 0; }
      .calc-auction__group-append .btn {
        position: relative;
        z-index: 2;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .calc-auction__group-append .btn:focus {
          z-index: 3; } }
  @media (min-width: 480px) {
    .calc-holder {
      margin-bottom: 1.875rem; } }
  .calc-holder--shadow {
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.15); }
  .calc-holder__title {
    color: #fff;
    background-color: #3e3e3e;
    text-align: center;
    line-height: 1.35;
    margin: 0;
    padding: 0.5rem 0; }
    @media (min-width: 480px) {
      .calc-holder__title {
        padding: 0.6875rem 0; } }
    @media (min-width: 768px) {
      .calc-holder__title {
        padding: 0.46875rem 0; } }
    @media (min-width: 992px) {
      .calc-holder__title {
        padding: 0.6875rem 0; } }
    @media (min-width: 1200px) {
      .calc-holder__title {
        padding: 0.46875rem 0; } }
  .calc-holder__container {
    background-color: #f9f9f9;
    padding: 2rem 1.25rem .5rem; }
  .calc-holder__section {
    padding-bottom: 1.25rem; }
  .calc-holder__subtitle {
    display: inline-block; }
  .calc-holder .form-control {
    border-radius: .1875rem;
    border: 1px solid #c4c4c4;
    box-shadow: none;
    color: #1a1a1a;
    font-size: 1rem;
    height: 2.5rem; }
    @media (min-width: 480px) {
      .calc-holder .form-control {
        font-size: 1.125rem; } }
    @media (min-width: 768px) {
      .calc-holder .form-control {
        font-size: 1.3125rem; } }
    @media (min-width: 992px) {
      .calc-holder .form-control {
        font-size: 1.125rem; } }
    @media (min-width: 1200px) {
      .calc-holder .form-control {
        font-size: 1.3125rem; } }
    @media (min-width: 480px) {
      .calc-holder .form-control {
        height: 2.8125rem; } }
    .calc-holder .form-control:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .calc-holder .form-control.custom-select {
      padding-top: 0;
      padding-bottom: 0; }
  .calc-holder__bet-description {
    color: #9a9a9a;
    font-size: 0.875rem; }
    @media (min-width: 480px) {
      .calc-holder__bet-description {
        font-size: 1rem; } }
    @media (min-width: 768px) {
      .calc-holder__bet-description {
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .calc-holder__bet-description {
        font-size: 1rem; } }
    @media (min-width: 1200px) {
      .calc-holder__bet-description {
        font-size: 0.875rem; } }
  .calc-form-element {
    margin-bottom: .5rem; }
  .calc .question-btn__tooltip-inner, .calc .calc__service-price-description {
    font-size: 0.875rem;
    padding: 0.375rem; }
    @media (min-width: 480px) {
      .calc .question-btn__tooltip-inner, .calc .calc__service-price-description {
        font-size: 1rem; } }
    @media (min-width: 768px) {
      .calc .question-btn__tooltip-inner, .calc .calc__service-price-description {
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .calc .question-btn__tooltip-inner, .calc .calc__service-price-description {
        font-size: 1rem; } }
    @media (min-width: 480px) {
      .calc .question-btn__tooltip-inner, .calc .calc__service-price-description {
        padding: 0.625rem; } }
  .calc .question-btn {
    margin-left: .5rem; }
    .calc .question-btn__tooltip {
      color: #858585;
      font-family: "Roboto", Arial, Helvetica, sans-serif;
      text-align: left;
      position: absolute;
      background-color: #fff;
      border-radius: 0.3125rem;
      margin-top: -1.8em;
      pointer-events: none;
      left: 1.25rem;
      right: 1.25rem;
      filter: drop-shadow(0 0 2px #e55e43);
      transform: translateY(-130%);
      opacity: 0;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .calc .question-btn__tooltip {
          transition: none; } }
      @media (min-width: 480px) {
        .calc .question-btn__tooltip {
          left: calc(15px + 1.25rem);
          right: auto; } }
      @media (min-width: 992px) {
        .calc .question-btn__tooltip {
          right: calc(15px + 1.25rem); } }
      .calc .question-btn__tooltip-inner {
        display: block; }
      .calc .question-btn__tooltip::after {
        content: '';
        position: absolute;
        width: .875rem;
        height: .875rem;
        background-color: #fff;
        top: 100%;
        left: 11.25rem;
        margin-left: -.4375rem;
        margin-top: -.5rem;
        border-radius: .125rem;
        transform: perspective(10px) rotateX(10deg) rotateZ(45deg);
        left: 11.25rem; }
        @media (min-width: 480px) {
          .calc .question-btn__tooltip::after {
            left: 12.4375rem; } }
        @media (min-width: 768px) {
          .calc .question-btn__tooltip::after {
            left: 14.25rem; } }
        @media (min-width: 992px) {
          .calc .question-btn__tooltip::after {
            left: 12.5rem; } }
        @media (min-width: 1200px) {
          .calc .question-btn__tooltip::after {
            left: 16.125rem; } }
    .calc .question-btn:hover .question-btn__tooltip,
    .calc .question-btn:focus .question-btn__tooltip {
      opacity: 1;
      transform: translateY(-100%); }
  .calc__service-price-description {
    color: #858585;
    background-color: #fff;
    border-radius: 0.3125rem;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.05); }
  .calc__service-price-input .custom-control-input:checked ~ .custom-control-label > span:first-child {
    position: relative; }
    .calc__service-price-input .custom-control-input:checked ~ .custom-control-label > span:first-child::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: .875rem;
      height: .875rem;
      margin-left: -.4375rem;
      margin-top: .375rem;
      background-color: #fff;
      z-index: 1;
      transform: perspective(10px) rotateX(-10deg) rotateZ(45deg); }
  .calc-result {
    background-color: #fff;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.1);
    padding: 1.875rem 1.25rem; }
    @media (min-width: 480px) {
      .calc-result {
        padding-left: 1.875rem;
        padding-right: 1.875rem; } }
    .calc-result__row {
      padding: .4375rem 0;
      font-size: 1rem; }
      @media (min-width: 480px) {
        .calc-result__row {
          font-size: 1.125rem; } }
      @media (min-width: 768px) {
        .calc-result__row {
          font-size: 1.3125rem; } }
      @media (min-width: 992px) {
        .calc-result__row {
          font-size: 1.125rem; } }
      @media (min-width: 1200px) {
        .calc-result__row {
          font-size: 1.3125rem; } }
      .calc-result__row--total {
        border-top: 0.125rem solid #e55e43;
        font-size: 1.125rem; }
        @media (min-width: 480px) {
          .calc-result__row--total {
            font-size: 1.3125rem; } }
        @media (min-width: 768px) {
          .calc-result__row--total {
            font-size: 1.5rem; } }
        @media (min-width: 992px) {
          .calc-result__row--total {
            font-size: 1.3125rem; } }
        @media (min-width: 1200px) {
          .calc-result__row--total {
            font-size: 1.5rem; } }
    .calc-result__info {
      color: #a9a9a9;
      margin: 1.25rem 0 0;
      font-size: 0.875rem; }
      @media (min-width: 480px) {
        .calc-result__info {
          font-size: 1.125rem; } }
      @media (min-width: 1200px) {
        .calc-result__info {
          font-size: 1rem; } }
    .calc-result__logo .logo {
      width: 4.375rem;
      height: 1.3125rem; }
      @media (min-width: 480px) {
        .calc-result__logo .logo {
          width: 6.25rem; } }
      @media (min-width: 480px) {
        .calc-result__logo .logo {
          height: 1.875rem; } }
    .calc-result__preview-lot {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-weight: 500;
      font-size: 0.75rem; }
      @media (min-width: 480px) {
        .calc-result__preview-lot {
          font-size: 1rem; } }
      @media (min-width: 768px) {
        .calc-result__preview-lot {
          font-size: 1.125rem; } }
      @media (min-width: 992px) {
        .calc-result__preview-lot {
          font-size: 1rem; } }
      @media (min-width: 1200px) {
        .calc-result__preview-lot {
          font-size: 1.125rem; } }
      .calc-result__preview-lot strong {
        font-weight: 600; }
    .calc-result__preview-image {
      max-width: 8.125rem; }
      @media (min-width: 480px) {
        .calc-result__preview-image {
          max-width: 11.25rem; } }
      .calc-result__preview-image img {
        width: 100%;
        height: auto; }
  @media (min-width: 480px) {
    .calc [type="submit"] {
      line-height: 1.5;
      font-size: .9375rem; } }

.car-type-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (min-width: 480px) {
    .car-type-list {
      justify-content: start; } }
  .car-type-list__item {
    position: relative;
    display: inline-block; }
    @media (min-width: 480px) {
      .car-type-list__item + .car-type-list__item {
        margin-left: 1.875rem; } }
    @media (min-width: 768px) {
      .car-type-list__item + .car-type-list__item {
        margin-left: 2.5rem; } }
    @media (min-width: 992px) {
      .car-type-list__item + .car-type-list__item {
        margin-left: 1.875rem; } }
  .car-type-list__input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.0625rem;
    height: 1.0625rem;
    opacity: 0; }
  .car-type-list__label {
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8125rem; }
    @media (min-width: 480px) {
      .car-type-list__label {
        font-size: 0.9375rem; } }
  .car-type-list__icon {
    color: #9a9a9a;
    background-color: #fff;
    border-radius: .1875rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 2.75rem;
    width: 3.75rem;
    height: 3.75rem;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out; }
    @media (min-width: 480px) {
      .car-type-list__icon {
        font-size: 3.125rem; } }
    @media (min-width: 480px) {
      .car-type-list__icon {
        width: 4.375rem; } }
    @media (min-width: 480px) {
      .car-type-list__icon {
        height: 4.375rem; } }
    @media (prefers-reduced-motion: reduce) {
      .car-type-list__icon {
        transition: none; } }
  .car-type-list__input:checked ~ .car-type-list__label .car-type-list__icon {
    background-color: #e55e43;
    color: #fff; }

.need-consultation {
  border-radius: .625rem;
  box-shadow: 0.125rem 0.125rem 1.25rem rgba(0, 0, 0, 0.1);
  padding: 2rem 15px 0;
  margin-bottom: calc(15% + 1.875rem); }
  @media (min-width: 480px) {
    .need-consultation {
      margin-bottom: calc(11% + 1.875rem); } }
  @media (min-width: 768px) {
    .need-consultation {
      padding: 3.25rem 30px 0; } }
  @media (min-width: 992px) {
    .need-consultation {
      padding-top: 3.75rem;
      margin-bottom: calc(2.5rem + 1.875rem); } }
  @media (min-width: 1200px) {
    .need-consultation {
      padding-top: 4.5rem;
      padding-left: 5rem;
      margin-bottom: calc(5rem + 1.875rem); } }
  .need-consultation__image {
    pointer-events: none;
    margin: 0 -15px -15%; }
    @media (min-width: 480px) {
      .need-consultation__image {
        margin-top: 1rem;
        margin-bottom: -11%; } }
    @media (min-width: 768px) {
      .need-consultation__image {
        margin-right: -30px;
        margin-bottom: -11%; } }
    @media (min-width: 992px) {
      .need-consultation__image {
        margin-top: 15px;
        margin-bottom: -25%;
        margin-left: -40%;
        margin-right: -15px; } }
    @media (min-width: 1200px) {
      .need-consultation__image {
        margin-top: -15px;
        margin-bottom: -25%;
        margin-left: -30%; } }
    .need-consultation__image img {
      width: 100%;
      height: auto; }

@media (min-width: 768px) and (max-width: 991.98px) {
  .need-consultation__title {
    font-size: 32px; } }

@media (min-width: 1200px) {
  .need-consultation__title {
    font-size: 30px; } }

@media (min-width: 768px) {
  .need-consultation__text {
    width: 610px;
    font-size: 21px; } }

@media (min-width: 992px) {
  .need-consultation__text {
    font-size: 18px; } }

@media (min-width: 1200px) {
  .need-consultation__text {
    width: auto;
    font-size: 21px; } }

.lot__sale-info {
  font-size: 0.9375rem; }
  @media (min-width: 480px) {
    .lot__sale-info {
      font-size: 1rem; } }
  @media (min-width: 768px) {
    .lot__sale-info {
      font-size: 1.125rem; } }
  .lot__sale-info strong {
    font-weight: 500; }

.lot-carousel, .report-carousel {
  user-select: none;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 992px) {
    .lot-carousel, .report-carousel {
      margin-left: 0;
      margin-right: 0; } }
  @media (max-width: 479.98px) {
    .lot-carousel--calc {
      background-color: #f9f9f9;
      padding: 1.25rem 0 .5rem; } }
  .lot-carousel__item, .report-carousel__item {
    vertical-align: top;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 992px) {
      .lot-carousel__item, .report-carousel__item {
        padding-left: 0;
        padding-right: 0; } }
    .lot-carousel__item img, .report-carousel__item img {
      width: 100%;
      height: auto;
      display: block; }
      @media (min-width: 480px) {
        .lot-carousel__item img, .report-carousel__item img {
          border-radius: 0.3125rem; } }
  .lot-carousel__thumbs, .report-carousel__thumbs {
    display: flex;
    flex-wrap: wrap;
    margin: .3125rem -.3125rem;
    align-items: center; }
  .lot-carousel__thumb, .report-carousel__thumb {
    user-select: none;
    position: relative;
    width: 20%;
    padding: .3125rem; }
    .lot-carousel__thumb img, .report-carousel__thumb img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: .125rem; }
    .lot-carousel__thumb-link, .report-carousel__thumb-link {
      border: none;
      background: transparent;
      display: block;
      width: 100%;
      cursor: pointer;
      padding: 0; }
    .lot-carousel__thumb.active .lot-carousel__thumb-link::after, .active.report-carousel__thumb .lot-carousel__thumb-link::after, .lot-carousel__thumb.active .report-carousel__thumb-link::after, .active.report-carousel__thumb .report-carousel__thumb-link::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0.0625rem solid #e55e43;
      border-radius: .1875rem; }
  .lot-carousel .slick-prev, .report-carousel .slick-prev, .lot-carousel .slick-next, .report-carousel .slick-next {
    position: absolute;
    height: 2.5rem;
    width: 1.875rem;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
    color: #9a9a9a;
    transition: color 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .lot-carousel .slick-prev, .report-carousel .slick-prev, .lot-carousel .slick-next, .report-carousel .slick-next {
        transition: none; } }
    .lot-carousel .slick-prev:hover, .report-carousel .slick-prev:hover, .lot-carousel .slick-prev:focus, .report-carousel .slick-prev:focus, .lot-carousel .slick-next:hover, .report-carousel .slick-next:hover, .lot-carousel .slick-next:focus, .report-carousel .slick-next:focus {
      color: #fff; }
    .lot-carousel .slick-prev::before, .report-carousel .slick-prev::before, .lot-carousel .slick-next::before, .report-carousel .slick-next::before {
      font-size: 1.1875rem;
      vertical-align: middle; }
  .lot-carousel .slick-prev, .report-carousel .slick-prev {
    border-radius: 0 .1875rem .1875rem 0;
    left: 15px; }
    @media (min-width: 992px) {
      .lot-carousel .slick-prev, .report-carousel .slick-prev {
        left: 0; } }
    .lot-carousel .slick-prev::before, .report-carousel .slick-prev::before {
      margin-left: -.125em; }
  .lot-carousel .slick-next, .report-carousel .slick-next {
    border-radius: .1875rem 0 0 .1875rem;
    right: 15px; }
    @media (min-width: 992px) {
      .lot-carousel .slick-next, .report-carousel .slick-next {
        right: 0; } }
    .lot-carousel .slick-next::before, .report-carousel .slick-next::before {
      margin-right: -.125em; }

.lot__current-bet {
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #e55e43;
  line-height: 1.6; }
  .lot__current-bet span {
    font-weight: 700; }

.lot-table {
  background-color: #f9f9f9;
  padding: 1.25rem 15px;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 480px) {
    .lot-table {
      border: .0625rem solid #d8d8d8;
      border-radius: 0.3125rem;
      margin-left: 0;
      margin-right: 0;
      padding-left: 1.25rem;
      padding-right: 1.25rem; } }
  @media (min-width: 768px) {
    .lot-table {
      padding-left: 1.875rem;
      padding-right: 1.875rem; } }
  .lot-table__row {
    line-height: 1.8;
    padding: .25rem 0;
    font-size: 0.75rem;
    line-height: 1.7; }
    @media (min-width: 480px) {
      .lot-table__row {
        font-size: 1rem; } }
    @media (min-width: 768px) {
      .lot-table__row {
        font-size: 1.125rem; } }
    @media (min-width: 992px) {
      .lot-table__row {
        font-size: 1rem; } }
    @media (min-width: 1200px) {
      .lot-table__row {
        font-size: 1.125rem; } }
    @media (min-width: 480px) {
      .lot-table__row {
        line-height: 1.6; } }
    @media (min-width: 1200px) {
      .lot-table__row {
        line-height: 1.55; } }
    .lot-table__row:not(:last-child) {
      border-bottom: .0625rem solid #d8d8d8; }

.faq__list {
  margin: 0; }
  .faq__list dt {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    border-top: 0.0625rem solid #c5c5c5;
    margin-top: -.0625rem; }
  .faq__list dd {
    margin: 0;
    border-bottom: 0.0625rem solid #c5c5c5; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .faq__list dd {
        font-size: 21px; } }
    @media (min-width: 1200px) {
      .faq__list dd {
        max-width: 960px; } }

.faq__collapse-btn {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: .5;
  font-size: 1.875rem;
  margin-left: 24px;
  color: #e55e43;
  order: 30;
  margin-right: 0.375rem;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  @media (min-width: 480px) {
    .faq__collapse-btn {
      margin-right: 1.375rem; } }
  @media (min-width: 768px) {
    .faq__collapse-btn {
      margin-right: 1.625rem; } }
  @media (min-width: 1200px) {
    .faq__collapse-btn {
      margin-right: 0.375rem; } }
  @media (min-width: 480px) {
    .faq__collapse-btn {
      margin-top: 1.25rem; } }
  @media (min-width: 992px) {
    .faq__collapse-btn {
      margin-top: 0.875rem; } }
  @media (min-width: 1200px) {
    .faq__collapse-btn {
      margin-top: 1rem; } }
  @media (min-width: 480px) {
    .faq__collapse-btn {
      margin-bottom: 1.25rem; } }
  @media (min-width: 992px) {
    .faq__collapse-btn {
      margin-bottom: 0.875rem; } }
  @media (min-width: 1200px) {
    .faq__collapse-btn {
      margin-bottom: 1rem; } }
  .faq__collapse-btn .icon, .faq__collapse-btn .slick-slider .slick-prev, .slick-slider .faq__collapse-btn .slick-prev, .faq__collapse-btn .slick-slider .slick-next, .slick-slider .faq__collapse-btn .slick-next, .faq__collapse-btn .retail-nuances__container::before, .faq__collapse-btn .how-it__btn::after, .faq__collapse-btn .retail-prices__list > li::before, .faq__collapse-btn .lot-carousel .slick-prev, .lot-carousel .faq__collapse-btn .slick-prev, .faq__collapse-btn .report-carousel .slick-prev, .report-carousel .faq__collapse-btn .slick-prev, .faq__collapse-btn .lot-carousel .slick-next, .lot-carousel .faq__collapse-btn .slick-next, .faq__collapse-btn .report-carousel .slick-next, .report-carousel .faq__collapse-btn .slick-next {
    display: inline-block;
    transition: color 0.15s ease-in-out, transform 0.3s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .faq__collapse-btn .icon, .faq__collapse-btn .slick-slider .slick-prev, .slick-slider .faq__collapse-btn .slick-prev, .faq__collapse-btn .slick-slider .slick-next, .slick-slider .faq__collapse-btn .slick-next, .faq__collapse-btn .retail-nuances__container::before, .faq__collapse-btn .how-it__btn::after, .faq__collapse-btn .retail-prices__list > li::before, .faq__collapse-btn .lot-carousel .slick-prev, .lot-carousel .faq__collapse-btn .slick-prev, .faq__collapse-btn .report-carousel .slick-prev, .report-carousel .faq__collapse-btn .slick-prev, .faq__collapse-btn .lot-carousel .slick-next, .lot-carousel .faq__collapse-btn .slick-next, .faq__collapse-btn .report-carousel .slick-next, .report-carousel .faq__collapse-btn .slick-next {
        transition: none; } }
  .faq__collapse-btn.collapsed {
    color: #1a1a1a; }
    .faq__collapse-btn.collapsed:hover, .faq__collapse-btn.collapsed:active, .faq__collapse-btn.collapsed:focus,
    .faq__collapse-btn.collapsed:hover ~ .faq__item-title, .faq__collapse-btn.collapsed:active ~ .faq__item-title, .faq__collapse-btn.collapsed:focus ~ .faq__item-title {
      color: #e55e43; }
    .faq__collapse-btn.collapsed .icon, .faq__collapse-btn.collapsed .slick-slider .slick-prev, .slick-slider .faq__collapse-btn.collapsed .slick-prev, .faq__collapse-btn.collapsed .slick-slider .slick-next, .slick-slider .faq__collapse-btn.collapsed .slick-next, .faq__collapse-btn.collapsed .retail-nuances__container::before, .faq__collapse-btn.collapsed .how-it__btn::after, .faq__collapse-btn.collapsed .retail-prices__list > li::before, .faq__collapse-btn.collapsed .lot-carousel .slick-prev, .lot-carousel .faq__collapse-btn.collapsed .slick-prev, .faq__collapse-btn.collapsed .report-carousel .slick-prev, .report-carousel .faq__collapse-btn.collapsed .slick-prev, .faq__collapse-btn.collapsed .lot-carousel .slick-next, .lot-carousel .faq__collapse-btn.collapsed .slick-next, .faq__collapse-btn.collapsed .report-carousel .slick-next, .report-carousel .faq__collapse-btn.collapsed .slick-next {
      transform: rotate(135deg); }

.faq__item-title {
  display: inline-block;
  cursor: pointer;
  margin: -.5rem 0;
  font-size: 14px; }
  @media (min-width: 480px) {
    .faq__item-title {
      font-size: 18px; } }
  @media (min-width: 768px) {
    .faq__item-title {
      font-size: 21px; } }
  @media (min-width: 992px) {
    .faq__item-title {
      font-size: 18px; } }
  .faq__item-title:hover {
    color: #e55e43; }

.blog__image {
  display: block;
  position: relative;
  padding-bottom: 57.1428%;
  overflow: hidden;
  margin-bottom: .3125rem; }
  .blog__image img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @supports (object-fit: cover) {
    .blog__image img {
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

.blog-list {
  margin-bottom: -30px; }
  .blog-list__item {
    margin-bottom: 30px; }
  .blog-list__link {
    color: #1a1a1a; }
    .blog-list__link:hover {
      text-decoration: none;
      color: #e55e43; }
  .blog-list__title {
    display: block;
    font-weight: 700;
    line-height: 1.4;
    overflow: hidden;
    max-height: calc(4.2em + .5rem);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin: -.25rem;
    padding: .25rem;
    transition: color 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .blog-list__title {
        transition: none; } }
    @media (max-width: 479.98px) {
      .blog-list__title {
        font-size: 1.125rem; } }
    @media (min-width: 480px) and (max-width: 767.98px) {
      .blog-list__title {
        height: calc(2.8em + .5rem);
        -webkit-line-clamp: 2; } }
    @media (min-width: 768px) {
      .blog-list__title {
        height: calc(4.2em + .5rem); } }
  .blog-list__image:hover ~ .blog-list__title,
  .blog-list__image:hover ~ .blog-list__title .blog-list__link {
    color: #e55e43; }

.blog-article::after {
  display: block;
  clear: both;
  content: ""; }

@media (min-width: 992px) {
  .blog-article__image {
    width: 450px; } }

@media (min-width: 1200px) {
  .blog-article__image {
    width: 540px; } }

@media (min-width: 992px) {
  .blog-article__image {
    margin-right: 30px; } }

.g-map {
  width: 100%;
  height: calc((100vw - 30px) * .7142); }
  @media (min-width: 992px) {
    .g-map {
      width: 43.125rem; } }
  @media (min-width: 1200px) {
    .g-map {
      width: 51.5625rem; } }
  @media (min-width: 480px) {
    .g-map {
      height: calc((100vw - 30px) * .6818); } }
  @media (min-width: 768px) {
    .g-map {
      height: calc((66.66vw - 30px) * .5555); } }
  @media (min-width: 992px) {
    .g-map {
      height: 20.9375rem; } }
  @media (min-width: 1200px) {
    .g-map {
      height: 25rem; } }

.contacts address {
  font-size: 1rem; }
  @media (min-width: 480px) {
    .contacts address {
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    .contacts address {
      font-size: 1.3125rem; } }
  .contacts address a {
    color: #1a1a1a;
    text-decoration: none !important; }
    .contacts address a:hover {
      color: #e55e43; }

.contacts-list {
  text-align: center;
  margin-bottom: -30px; }
  .contacts-list__item {
    margin: 0 auto 30px;
    display: inline-block; }
  .contacts-list__image {
    border-radius: 50%;
    background-color: #c5c5c5;
    margin-bottom: .625rem;
    width: 11.25rem;
    height: 11.25rem; }
    @media (min-width: 768px) {
      .contacts-list__image {
        width: 12.5rem; } }
    @media (min-width: 1200px) {
      .contacts-list__image {
        width: 13.75rem; } }
    @media (min-width: 768px) {
      .contacts-list__image {
        height: 12.5rem; } }
    @media (min-width: 1200px) {
      .contacts-list__image {
        height: 13.75rem; } }
  .contacts-list__description {
    font-weight: 500;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 1rem; }
    @media (min-width: 480px) {
      .contacts-list__description {
        font-size: 0.875rem; } }
    @media (min-width: 768px) {
      .contacts-list__description {
        font-size: 1rem; } }
  .contacts-list__title {
    font-weight: 600;
    margin: 0;
    font-size: 1.125rem; }
    @media (min-width: 1200px) {
      .contacts-list__title {
        font-size: 1.3125rem; } }

.track-page .alert {
  font-size: .875rem; }
  @media (min-width: 480px) {
    .track-page .alert {
      font-size: 1rem; } }

.track-page .btn-download {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  height: 3.125rem;
  line-height: 1.3; }

.track-page__title {
  font-size: 1.125rem;
  margin-bottom: 1rem; }
  @media (min-width: 480px) {
    .track-page__title {
      font-size: 1.5rem; } }

@media (min-width: 1200px) {
  .report-carousel {
    margin-bottom: .625rem; } }

.report-carousel__item img {
  border-radius: 0; }

@media (min-width: 480px) {
  .report-carousel__thumb {
    width: 16.666%; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .report-carousel__thumb {
    width: 11.111%; } }

.track-table {
  margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .track-table {
      font-size: 1rem; } }
  @media (max-width: 479.98px) {
    .track-table {
      font-size: .8125rem; } }
  .track-table tr:nth-of-type(odd) {
    background-color: #f5f5f5; }
  .track-table td, .track-table th {
    border-color: #d0d0d0;
    padding-left: 15px !important;
    padding-right: 15px !important;
    line-height: 1.5;
    padding: 0.9375rem; }
    @media (min-width: 480px) {
      .track-table td, .track-table th {
        line-height: 1.45; } }
    @media (min-width: 768px) {
      .track-table td, .track-table th {
        line-height: 1.5; } }
    @media (min-width: 992px) {
      .track-table td, .track-table th {
        line-height: 1.45; } }
    @media (min-width: 1200px) {
      .track-table td, .track-table th {
        line-height: 1.5; } }
    @media (min-width: 480px) {
      .track-table td, .track-table th {
        padding: 0.8125rem; } }
    @media (min-width: 768px) {
      .track-table td, .track-table th {
        padding: 0.6875rem; } }
    @media (min-width: 992px) {
      .track-table td, .track-table th {
        padding: 0.8125rem; } }
    @media (min-width: 1200px) {
      .track-table td, .track-table th {
        padding: 0.6875rem; } }
  .track-table .icon, .track-table .slick-slider .slick-prev, .slick-slider .track-table .slick-prev, .track-table .slick-slider .slick-next, .slick-slider .track-table .slick-next, .track-table .retail-nuances__container::before, .track-table .how-it__btn::after, .track-table .retail-prices__list > li::before, .track-table .lot-carousel .slick-prev, .lot-carousel .track-table .slick-prev, .track-table .report-carousel .slick-prev, .report-carousel .track-table .slick-prev, .track-table .lot-carousel .slick-next, .lot-carousel .track-table .slick-next, .track-table .report-carousel .slick-next, .report-carousel .track-table .slick-next {
    line-height: 1;
    font-size: 0.75rem; }
    @media (min-width: 480px) {
      .track-table .icon, .track-table .slick-slider .slick-prev, .slick-slider .track-table .slick-prev, .track-table .slick-slider .slick-next, .slick-slider .track-table .slick-next, .track-table .retail-nuances__container::before, .track-table .how-it__btn::after, .track-table .retail-prices__list > li::before, .track-table .lot-carousel .slick-prev, .lot-carousel .track-table .slick-prev, .track-table .report-carousel .slick-prev, .report-carousel .track-table .slick-prev, .track-table .lot-carousel .slick-next, .lot-carousel .track-table .slick-next, .track-table .report-carousel .slick-next, .report-carousel .track-table .slick-next {
        font-size: 0.9375rem; } }
  @media (max-width: 479.98px) {
    .track-table-container {
      margin: 0 -15px; } }

@media (max-width: 991.98px) {
  .track-data {
    margin: 0 -15px;
    overflow-x: auto; } }

.track-data::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

.track-data::-webkit-scrollbar-track {
  background-color: #ebebeb; }

.track-data::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 10px; }

.track-data .track-table {
  min-width: 44rem; }

.report-tabs {
  font-size: 0.75rem;
  line-height: 1.45; }
  @media (min-width: 480px) {
    .report-tabs {
      font-size: 0.9375rem; } }
  @media (min-width: 480px) {
    .report-tabs {
      line-height: 1.15; } }
  @media (min-width: 992px) {
    .report-tabs {
      margin-top: -2.125rem; } }
