.pretty-title {
  display: inline-flex;

  margin-left: -.625rem;
  color: $white;
  box-shadow: .125rem .125rem .625rem rgba(0, 0, 0, .2);
  border-radius: .3125rem .3125rem .3125rem 0;
  position: relative;

  @include property-by-media(font-size, (default: 1rem, sm: 1.125rem));
  @include property-by-media(line-height, (default: 1.3, sm: 1.35));
  @include property-by-media(padding, (default: .3125rem 1.875rem .3125rem .625rem, sm: .5rem 1.875rem .5rem .625rem));

  &::before {
    content: '';
    display: block;
    width: .625rem;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 0;
    height: 0;
    border-bottom: .3125rem solid transparent;
    border-right: .625rem solid transparent;
  }

  &--green {
    background-image: linear-gradient(90deg, #a4e353 15.1%, #7dd65d 100%);

    &::after {
      border-right-color: #80b143;
    }
  }
  &--orange {
    background-image: linear-gradient(90deg, #fc7b52 0, #e55e43 100%);

    &::after {
      border-right-color: #d56846;
    }
  }
  &--purple {
    background-image: linear-gradient(90deg, #7a87fe 0, #9160f9 100%);

    &::after {
      border-right-color: #5659a4;
    }
  }
}
