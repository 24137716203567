@mixin delivery-table-head {
  background-color: $gray-800;
  color: $white;
}

.delivery-price {
  &__title {
    @include property-by-media(margin-bottom, (default: 1.25rem, md: 1.875rem));
  }
  &__subtitle {
    @include property-by-media(font-weight, (default: 500, lg: 600));
    @include property-by-media(margin-bottom, (default: .625rem, md: 1.25rem, lg: 1.875rem));
  }

  &__radio {
    @include property-by-media(font-size, (default: 1rem, sm: 1.3125rem, lg: 1rem, xl: 1.125rem));
    @include property-by-media(line-height, (default: 1.7, sm: 1.4));

    &:not(:last-of-type) {
      @include property-by-media(margin-bottom, (default: .875rem, sm: 1.25rem, md: 1.875rem, lg: 0));
    }

    @include media-breakpoint-up(lg) {
      font-weight: 500;
      padding: 0;
    }

    &-icon {
      display: none;
      opacity: .4;
      filter: drop-shadow(.125rem .125rem .3125rem rgba(0, 0, 0, .15));

      @include transition(opacity .15s ease-in-out, filter .15s ease-in-out);

      @include media-breakpoint-up(lg) {
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;

        height: 8.75rem;
        margin-bottom: 1.125rem;
      }

      @include media-breakpoint-up(xl) {
        height: 10rem;
      }

      .svg-icon {
        &__maps--ua {
          @include media-breakpoint-up(lg) {
            width: 13.125rem;
            height: 8.75rem;
          }
          @include media-breakpoint-up(xl) {
            width: 15rem;
            height: 10rem;
          }
        }
        &__maps--de {
          @include media-breakpoint-up(lg) {
            width: 6.375rem;
            height: 8.75rem;
          }
          @include media-breakpoint-up(xl) {
            width: 7.3125rem;
            height: 10rem;
          }
        }
        &__maps--ge {
          @include media-breakpoint-up(lg) {
            width: 13.75rem;
            height: 6.875rem;
          }
          @include media-breakpoint-up(xl) {
            width: 16.25rem;
            height: 8.125rem;
          }
        }
        &__maps--lt {
          @include media-breakpoint-up(lg) {
            width: 8.125rem;
            height: 6.25rem;
          }
          @include media-breakpoint-up(xl) {
            width: 9.875rem;
            height: 7.5rem;
          }
        }
      }
    }

    .custom-control-input:checked ~ .custom-control-label &-icon {
      opacity: 1;
    }
    .custom-control-input:not(:checked) ~ .custom-control-label:hover &-icon {
      opacity: 1;
      filter: drop-shadow(.125rem .125rem .3125rem rgba(0, 0, 0, .2));
    }
    .custom-control-input:not(:checked) ~ .custom-control-label:hover {
      @include media-breakpoint-up(lg) {
        text-shadow: 0 .625rem 1.25rem rgba(0, 0, 0, .2);
      }
    }

    &-list--overland {
      @include media-breakpoint-down(xs) {
        column-count: 2;
        break-inside: avoid-column;
      }

      @include media-breakpoint-up(sm) {
        display: flex;
      }
      @include media-breakpoint-only(sm) {
        flex-wrap: wrap;
      }
      @include media-breakpoint-only(md) {
        justify-content: space-between;
      }
    }

    &--overland {
      @include media-breakpoint-down(xs) {
        transform: translateZ(0);
      }
      @include media-breakpoint-only(sm) {
        width: 33.33%;
      }

      i {
        @include property-by-media(font-size, (default: .8125rem, sm: .9375rem, lg: 1rem));
        display: block;
        margin-top: -.5em;
      }

      img {
        height: auto;

        &.delivery-icon {
          &-copart {
            @include property-by-media(width, (lg: 7.5rem, xl: 10rem));
          }
          &-iaai {
            @include property-by-media(width, (lg: 5.125rem, xl: 6.75rem));
          }
          &-manheim {
            @include property-by-media(width, (lg: 5.875rem, xl: 7.875rem));
          }
          &-impact {
            @include property-by-media(width, (lg: 8.625rem, xl: 11.3125rem));
          }
        }
      }
    }

    &--overland &-icon {
      @include media-breakpoint-up(lg) {
        height: 3.125rem;
      }
      @include media-breakpoint-up(xl) {
        height: 4.1875rem;
      }
    }

    .custom-control-label:before,
    .custom-control-label:after {
      width: 1.25rem;
      height: 1.25rem;

      @include property-by-media(top, (default: .109375rem, sm: .25rem));

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
    .custom-control-label:after {
      background-size: 1rem;
    }

    .custom-control-input:not(:checked) ~ .custom-control-label {
      color: $gray-500;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      flex-basis: 0;

      &-list {
        display: flex;
      }

      .custom-control-label {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;

        border-bottom: .0625rem solid #d1d1d1;
        padding-bottom: 1.25rem;
      }

      .custom-control-input:checked ~ .custom-control-label {
        border-bottom: .125rem solid $submenu-link-hover-color;
      }
    }

    @include media-breakpoint-up(xl) {
      .custom-control-label {
        padding-bottom: 2.5rem;
      }
    }
  }

  &__table {
    &:not(.row) {
      @include media-breakpoint-up(lg) {
        column-count: 2;
        column-gap: $grid-gutter-width;
        break-inside: avoid-column;
      }
    }

    &-row {
      background-color: $gray-100;
      display: inline-flex;
      width: 100%;
      align-items: center;
      line-height: 1.6rem;
      position: relative;

      @include property-by-media(font-size, (default: .875rem, sm: 1rem, md: 1.125rem, lg: 1rem, xl: 1.125rem));
      @include property-by-media(font-weight, (md: 500, lg: 400, xl: 500));

      span {
        padding: .25rem $grid-gutter-width/3;
      }
    }
    &-head {
      @include delivery-table-head;

      font-family: $font-family-montserrat;
      font-weight: 600;
      display: flex;

      @include property-by-media(font-size, (default: 1rem, md: 1.125rem, lg: 1rem, xl: 1.125rem));
    }

    &-row:not(&-head):nth-of-type(odd) {
      background-color: #ededed;
    }

    @include media-breakpoint-up(lg) {
      &-row:not(&-head):nth-of-type(4n+1):last-of-type::after,
      &-row:not(&-head):nth-of-type(4n+2):last-of-type::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 100%;
      }
      &-row:not(&-head):nth-of-type(4n+1):last-of-type::after {
        height: 200%;
      }
    }

    &-from,
    &-to {
      flex-basis: 0;
      flex-grow: 1;

    }
    @include media-breakpoint-down(md) {
      &-from {
        flex-grow: 3;
      }
      &-to {
        flex-grow: 2;
      }
    }
    &-price {
      width: 4em;
      text-align: right;
    }
  }

  &__scroll-top {
    position: sticky;
    bottom: 1rem;
    padding-top: 1rem;

    font-family: $font-family-montserrat;
    font-weight: 600;
    line-height: 1;
    color: $submenu-link-hover-color;
    cursor: pointer;
    text-align: right;
    visibility: hidden;

    text-shadow: .0625rem .0625rem rgba(0, 0, 0, .25);

    @include property-by-media(font-size, (default: .875rem, sm: 1rem, md: 1.125rem));

    > div {
      visibility: hidden;
      display: inline-flex;
      align-items: center;
      opacity: 0;

      transition: opacity .3s ease-in-out, visibility .3s 0s;
    }

    &--visible > div {
      opacity: 1;
      visibility: visible;
      transition: opacity .3s ease-in-out, visibility 0s;
    }

    &-button {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      text-align: center;
      line-height: 2.5rem;
      border-radius: 50%;
      background-color: $submenu-link-hover-color;
      font-size: 1rem;
      color: $white;
      margin-left: .625rem;

      .icon {
        display: inline-block;
        transform: rotate(90deg);
      }
    }
  }
}

@include media-breakpoint-only(sm) {
  .container_p20 .delivery-price__row_odessa {
    margin-right: -10px;
    margin-left: -10px;
  }

  .container_p20 .delivery-price__row_odessa .col,
  .container_p20 .delivery-price__row_odessa [class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.sea-delivery {
  &__table {
    font-family: $font-family-montserrat;
    background-color: $gray-100;

    @include property-by-media(font-size, (default: 1rem, sm: 1.125rem, xl: 1.3125rem));

    @include media-breakpoint-down(sm) {
      max-width: 25rem;
      margin: 0 auto;
    }

    &-row {
      display: flex;
      justify-content: space-between;

      font-weight: 500;
      line-height: 1.6;

      @include media-breakpoint-up(sm) {
        line-height: 1.4;
      }

      &:nth-of-type(even) {
        background-color: #ededed;
      }

      span {
        padding: .25rem $grid-gutter-width/2;
      }
    }
    &-head {
      @include delivery-table-head;
      font-weight: 600;
    }

    &--adaptive {
      @include media-breakpoint-up(lg) {
        display: flex;
        background-color: #ededed;
      }
    }
    &--adaptive &-row {
      @include media-breakpoint-up(lg) {
        flex-direction: column;
        text-align: center;
        flex-grow: 1;
      }

      span {
        @include media-breakpoint-up(lg) {
          font-weight: 700;
          line-height: 1.45;
          padding: {
            top: .4375rem;
            bottom: .4375rem;
          }

          &:first-of-type {
            @include delivery-table-head;
            font-weight: 600;
          }
        }
        @include media-breakpoint-up(xl) {
          padding: {
            top: .3125rem;
            bottom: .3125rem;
          }
        }
      }
    }
    &--adaptive &-head {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__notice {
    @include media-breakpoint-down(xs) {
      max-width: 25rem;
      margin: 0 auto;
    }
    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(lg) {
      font-weight: 500;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.125rem;
    }
  }
}
