.search-page {
  padding: {
    top: 2rem;
    bottom: 2rem;
  }

  @include media-breakpoint-up(lg) {
    padding: {
      top: 4rem;
      bottom: 4rem;
    }
  }

  &__title {
    font-size: 1.3125rem;
    font-weight: 500;
  }

  &__applied-filters {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.375rem;
  }

  &__filter-tag {
    @include clear-button;

    background-color: #efefef;
    line-height: 1.6;
    padding: 0 .5rem;
    margin: .25rem .375rem .5rem;

    .icon {
      font-size: .8125rem;
      margin-left: .25rem;
      text-shadow: 0 0 1px $black;
      vertical-align: baseline;
    }
  }
  &__filter-title {
    padding: .25rem 0;

    .icon {
      font-size: .9375rem;
    }
  }
  &__filter-toggle {
    margin: .75rem 0 1rem;
    font-weight: 500;
    font-size: 1rem;
    text-transform: none;

    .icon {
      font-size: 1.375rem;
    }
  }
}

@import "table";
@import "filters";
