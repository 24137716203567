body {
  &.slide-panel-opened {
    @include media-breakpoint-down(lg) {
      overflow-y: hidden;
    }
  }
  &.modal-opened {
    overflow-y: hidden;
  }
}

a {
  text-decoration: none;
  transition: color .15s ease-in-out;
}
a:focus,
button:focus {
  outline: 2px solid rgba(233, 79, 44, .5);
}

.nav-tabs .nav-link {
  outline: none;
}

.text-orange {
  color: $submenu-link-hover-color;
}

.page-main {
  @include media-breakpoint-down(md) {
    padding-top: $header-mobile-height;
  }
}

.main-section {
  padding: 3rem 0;
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    padding: 5rem 0;
  }
}

.minor-section {
  @include property-by-media(padding, (default: 3.125rem 0, sm: 3.75rem 0, md: 4.375rem 0, lg: 5rem 0, xl: 7.5rem 0));

  &__title {
    margin-bottom: 1.875rem;
  }
}

.minor-section_profit {
  overflow-x: hidden;
}

.blog-section {
  @include property-by-media(padding, (default: 3.125rem 0, sm: 3.75rem 0, lg: 4.375rem 0, xl: 5rem 0));
}

.section-secondary {
  background-color: $gray-100;
}

.link-directed {
  font-family: $font-family-montserrat;
  font-weight: 600;

  text-decoration: none !important;

  > span {
    text-decoration: underline;
  }
  .icon:not(:only-child) {
    margin: 0 .5em;
  }
  .icon:first-child {
    margin-left: 0;
  }
  .icon:last-child {
    margin-right: 0;
  }

  @at-root p & .icon {
    @include property-by-media(font-size, (default: .625rem, sm: .9375rem, md: .875rem));
  }
}

.responsive-image {
  @include responsive-image;
}

.container_p20 {
  @include media-breakpoint-down(sm) {
    padding-right: 20px;
    padding-left: 20px;

    .row {
      margin-right: -20px;
      margin-left: -20px;

      .col,
      [class*="col-"] {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }

  @include media-breakpoint-only(md) {
    padding-right: 39px;
    padding-left: 39px;

    //.row {
    //  margin-right: -39px;
    //  margin-left: -39px;
    //
    //  .col,
    //  [class*="col-"] {
    //    padding-right: 39px;
    //    padding-left: 39px;
    //  }
    //}
  }

  @include media-breakpoint-only(lg) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

a svg path {
  fill: currentColor;
}
