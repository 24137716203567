.pagination {
  .page-item {
    background-color: $pagination-bg;
    padding: .25rem;
    font-weight: 500;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.active {
      font-weight: $font-weight-normal;
    }

    .page-link {
      line-height: 1.4;
      border-radius: .1875rem;
      padding: .125rem .5rem;

      @include transition(background-color .15s ease-in-out, color .15s ease-in-out);
    }

    .icon {
      font-size: .75rem;
    }
  }
  .page-next, .page-prev {
    .page-link {
      padding: {
        left: .375rem;
        right: .375rem;
      }
      &:hover {
        background-color: #f47e66;
        color: $white;
      }
    }
  }
}