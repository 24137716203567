.need-consultation {
  border-radius: .625rem;
  box-shadow: .125rem .125rem 1.25rem rgba(0, 0, 0, .1);
  padding: 2rem $grid-gutter-width/2 0;

  margin-bottom: calc(15% + 1.875rem);

  @include media-breakpoint-up(sm) {
    margin-bottom: calc(11% + 1.875rem);
  }

  @include media-breakpoint-up(md) {
    padding: 3.25rem $grid-gutter-width 0;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 3.75rem;
    margin-bottom: calc(2.5rem + 1.875rem);
  }

  @include media-breakpoint-up(xl) {
    padding: {
      top: 4.5rem;
      left: 5rem;
    }
    margin-bottom: calc(5rem + 1.875rem);
  }

  &__image {
    pointer-events: none;
    margin: 0 (-$grid-gutter-width/2) -15%;

    @include media-breakpoint-up(sm) {
      margin: {
        top: 1rem;
        bottom: -11%;
      }
    }

    @include media-breakpoint-up(md) {
      margin: {
        right: -$grid-gutter-width;
        bottom: -11%;
      }
    }

    @include media-breakpoint-up(lg) {
      margin: {
        top: $grid-gutter-width/2;
        bottom: -25%;
        left: -40%;
        right: -$grid-gutter-width/2;
      }
    }

    @include media-breakpoint-up(xl) {
      margin: {
        top: -$grid-gutter-width/2;
        bottom: -25%;
        left: -30%;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.need-consultation__title {
  @include media-breakpoint-only(md) {
    font-size: 32px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 30px;
  }
}

.need-consultation__text {
  @include media-breakpoint-up(md) {
    width: 610px;
    font-size: 21px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }

  @include media-breakpoint-up(xl) {
    width: auto;
    font-size: 21px;
  }
}
