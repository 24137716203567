.faq {
  &__list {
    margin: 0;

    dt {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      border-top: .0625rem solid $gray-400;
      margin-top: -.0625rem;
    }

    dd {
      margin: 0;
      border-bottom: .0625rem solid $gray-400;

      @include media-breakpoint-only(md) {
        font-size: 21px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 960px;
      }
    }
  }
  &__collapse-btn {
    @include clear-button;

    line-height: .5;
    font-size: 1.875rem;
    margin-left: 24px;
    color: $submenu-link-hover-color;
    order: 30;

    @include property-by-media(margin-right, (default: .375rem, sm: 1.375rem, md: 1.625rem, xl: .375rem));
    @include property-by-media(margin-top, (default: 1rem, sm: 1.25rem, lg: .875rem, xl: 1rem));
    @include property-by-media(margin-bottom, (default: 1rem, sm: 1.25rem, lg: .875rem, xl: 1rem));

    .icon {
      display: inline-block;
      @include transition(color .15s ease-in-out, transform .3s ease-in-out);
    }

    &.collapsed {
      color: $body-color;

      &:hover, &:active, &:focus,
      &:hover ~ .faq__item-title, &:active ~ .faq__item-title, &:focus ~ .faq__item-title {
        color: $submenu-link-hover-color;
      }

      .icon {
        transform: rotate(135deg);
      }
    }
  }
  &__item-title {
    display: inline-block;
    cursor: pointer;
    margin: -.5rem 0;

    @include property-by-media(font-size, (default: 14px, sm: 18px, md: 21px, lg: 18px));

    &:hover {
      color: $submenu-link-hover-color;
    }
  }
}
