@mixin slide-panel($breakpoint: lg) {
  $panel: &;

  @include media-breakpoint-down($breakpoint) {
    position: absolute;
    top: $header-mobile-height;
    left: 0;
    width: 0;
    height: calc(100vh - #{$header-mobile-height});
    overflow-x: hidden;
    overflow-y: auto;
    transition: width 0s .3s;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    & {
      overflow: -moz-scrollbars-none;
    }

    & {
      -ms-overflow-style: none;
    }

    &--right {
      left: auto;
      right: 0;
    }

    &::before {
      content: '';
      position: fixed;
      top: $header-mobile-height;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,.5);
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s .3s, opacity .3s ease-in-out;
    }

    &.opened,
    &:focus-within {
      width: 100%;
      transition: width 0s;

      &::before {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s, opacity .3s ease-in-out;
      }
    }

    .slide-panel__inner {
      background-color: $white;
      min-height: 100%;
      position: relative;
      padding: {
        top: 1.5rem;
        bottom: 1.5rem;
      }
      margin-left: 0;

      width: 100vw;
      max-width: 20rem;

      transform: translateX(-100%);
      transition: transform .3s ease-in-out;

      @at-root #{$panel}.opened .slide-panel__inner,
      &:focus-within {
        transform: translateX(0);
      }
    }

    &--right .slide-panel__inner {
      margin-right: 0;
      margin-left: auto;
      transform: translateX(100%);
    }
    &--right.opened .slide-panel__inner,
    &--right .slide-panel__inner:focus-within {
      transform: translateX(0);
    }
  }
}

.slide-panel {
  @include slide-panel();
}
