.search-filters {
  @include media-breakpoint-up(xl) {
    width: 14rem;
    padding-right: $grid-gutter-width;
    flex-shrink: 0;
  }

  @include media-breakpoint-up(xxl) {
    width: 19.5rem;
  }

  &.slide-panel {
    @include slide-panel(lg);
    @include media-breakpoint-down(lg) {
      top: 0;
      height: 100vh;
      z-index: 5;
      position: fixed;

      &::before {
        top: 0;
      }
    }
  }
  &.slide-panel .slide-panel__inner {
    @include media-breakpoint-down(lg) {
      padding: {
        left: $grid-gutter-width/2;
        right: $grid-gutter-width/2;
        top: 0;
        bottom: 0;
      }
    }
  }

  &__list {
    border-bottom: .0625rem solid $gray-200;

    @include media-breakpoint-up(xl) {
      font-size: .875rem;
      border-style: solid;
      border-color: $gray-200;
      border-width: .0625rem .0625rem .0625rem 0;
    }
  }

  &__section {
    padding: .5rem 0;
    border-top: 1px solid $gray-200;

    @include media-breakpoint-up(xl) {
      &:first-child {
        border-top: none;
      }
    }
  }

  &__title {
    font-family: $font-family-montserrat;
    font-weight: 500;
    font-size: 1rem;

    @include media-breakpoint-up(xl) {
      font-family: $font-family-roboto;
      font-weight: $font-weight-normal;
    }
  }

  &__collapse {
    @include clear-button;

    display: flex;
    width: 100%;
    margin: .125rem 0;

    .icon {
      font-size: .875rem;
      display: inline-block;

      @include transition(transform .30s ease-in-out);
    }

    &.collapsed .icon {
      transform: rotateX(180deg);
    }
  }

  &__collapse-icon {
    margin-left: auto;
    align-self: center;
    padding: 0 .5rem;
  }

  &__container {
    overflow-y: auto;
    overflow-x: hidden;
    color: rgba(26, 26, 26, 0);
    max-height: 16.75rem;

    @include media-breakpoint-down(lg) {
      margin: 0 -.5rem;
      padding: 0 .5rem;
    }

    @include media-breakpoint-up(xl) {
      margin-left: -.5rem;
      padding-left: .5rem;
    }

    &:hover {
      color: rgba(26, 26, 26, .5);
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      width: 12px;
      border-radius: 12px;
      background-clip: padding-box;
      border: 4px solid transparent;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 10px;
    }
  }

  &__inner {
    margin: .25rem 0;
    color: $body-color;
  }

  &__item {
    padding: .5rem 0;

    @include media-breakpoint-up(xl) {
      padding: .125rem 0;
    }
  }

  &__submit {
    padding: 1rem 0;

    .btn {
      font-family: $font-family-roboto;
      font-weight: 500;
      font-size: 1rem;
      padding: {
        top: .5rem;
        bottom: .5rem;
      }

      @include media-breakpoint-up(xl) {
        border-radius: 0;
      }
    }
  }

  &__hide-panel-btn {
    @include clear-button;

    font-size: 1.125rem;
    font-family: $font-family-montserrat;
    font-weight: 500;

    .icon {
      font-size: .9375rem;
      margin-right: .5rem;
      vertical-align: baseline;
    }
  }

  &__reset-btn {
    @include clear-button;

    color: $gray-500;
    border: .0625rem solid $gray-500;
    border-radius: 1rem;
    font-family: $font-family-montserrat;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.8;
    padding: 0 .5rem;
  }

  .custom-control {
    min-height: 1.25rem;
  }
  .custom-control-label {
    padding-left: .5rem;
    padding-top: .25rem;
    line-height: 1.3;

    @include media-breakpoint-up(xl) {
      padding-left: .25rem;
      padding-top: .125rem;
    }

    &::after,
    &::before {
      @include media-breakpoint-down(lg) {
        width: 1.875rem;
        height: 1.875rem;
      }
    }
  }
}