.social-menu {
  a {
    display: inline-block;
    text-decoration: none;
    @include gutter-padding;

    &:hover {
      color: $submenu-link-hover-color;
    }
  }

  .icon {
    font-size: 1.875rem;
    vertical-align: middle;
  }
}