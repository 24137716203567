//$review-carousel--gutter-width: 40px;

/*.review-carousel {
  margin: {
    right: -$review-carousel--gutter-width/2;
    left: -$review-carousel--gutter-width/2;
  }
  //padding: {
  //  right: $review-carousel--gutter-width/4;
  //  left: $review-carousel--gutter-width/4;
  //}
  overflow-x: hidden;

  --items-per-slide: 1;

  @include media-breakpoint-up(sm) {
    --items-per-slide: 2;
  }

  @include media-breakpoint-up(lg) {
    --items-per-slide: 4;
  }

  .carousel-inner {
    //@extend .row;
    padding: {
      right: $review-carousel--gutter-width/4;
      left: $review-carousel--gutter-width/4;
    }
    margin: 0 auto;
    overflow: visible;
  }

  .carousel-item {
    @extend .col-12, .col-sm-6, .col-lg-3;

    padding: 0;
  }

  @include media-breakpoint-up(sm) {
    .carousel-item {
      margin-right: 0;
    }

    .carousel-inner .active + .carousel-item {
      display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
      transition: none !important;
    }
    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
      transform: translateX(0);
      visibility: visible;
    }
  }

  @include media-breakpoint-between(sm, md) {
    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-next + .carousel-item,
    .carousel-inner .carousel-item-prev,
    .carousel-inner .carousel-item-prev + .carousel-item {
      position: absolute;
      top: 0;
      z-index: -1;
      display: block;
      visibility: hidden;
    }

    .carousel-inner .carousel-item-next {
      right: -50%;
    }
    .carousel-inner .carousel-item-next + .carousel-item {
      right: -100%;
    }
    .carousel-inner .carousel-item-prev {
      left: -100%;
    }
    .carousel-inner .carousel-item-prev + .carousel-item {
      left: -50%;
    }

    .active.carousel-item-left,
    .active.carousel-item-left + .carousel-item,
    .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item {
      transform: translateX(-200%);
      visibility: visible;
    }
    .active.carousel-item-right,
    .active.carousel-item-right + .carousel-item,
    .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item {
      transform: translateX(200%);
      visibility: visible;
    }
  }

  @include media-breakpoint-up(lg) {
    .carousel-item {
      @include transition(opacity $carousel-transition-duration ease-in-out, $carousel-transition);
    }

    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-next + .carousel-item,
    .carousel-inner .carousel-item-next + .carousel-item + .carousel-item,
    .carousel-inner .carousel-item-next + .carousel-item + .carousel-item + .carousel-item,
    .carousel-inner .carousel-item-prev,
    .carousel-inner .carousel-item-prev + .carousel-item,
    .carousel-inner .carousel-item-prev + .carousel-item + .carousel-item,
    .carousel-inner .carousel-item-prev + .carousel-item + .carousel-item + .carousel-item {
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: -1;
      display: block;
      visibility: hidden;
    }

    .carousel-inner .carousel-item-next {
      right: -25%;
    }
    .carousel-inner .carousel-item-next + .carousel-item {
      right: -50%;
    }
    .carousel-inner .carousel-item-next + .carousel-item + .carousel-item {
      right: -75%;
    }
    .carousel-inner .carousel-item-next + .carousel-item + .carousel-item + .carousel-item {
      right: -100%;
    }

    .carousel-inner .carousel-item-prev {
      left: -100%;
    }
    .carousel-inner .carousel-item-prev + .carousel-item {
      left: -75%;
    }
    .carousel-inner .carousel-item-prev + .carousel-item + .carousel-item {
      left: -50%;
    }
    .carousel-inner .carousel-item-prev + .carousel-item + .carousel-item + .carousel-item {
      left: -25%;
    }

    .carousel-inner .active + .carousel-item + .carousel-item,
    .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
      display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item,
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
      transition: none !important;
    }

    .active.carousel-item-left,
    .active.carousel-item-left + .carousel-item,
    .active.carousel-item-left + .carousel-item + .carousel-item,
    .active.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
    .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
      opacity: 1;
      transform: translateX(-400%);
      visibility: visible;
    }

    .active.carousel-item-right,
    .active.carousel-item-right + .carousel-item,
    .active.carousel-item-right + .carousel-item + .carousel-item,
    .active.carousel-item-right + .carousel-item + .carousel-item + .carousel-item,
    .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
      opacity: 1;
      transform: translateX(400%);
      visibility: visible;
    }

    .active.carousel-item-left,
    .active.carousel-item-left + .carousel-item,
    .active.carousel-item-left + .carousel-item + .carousel-item,
    .active.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
    .active.carousel-item-right,
    .active.carousel-item-right + .carousel-item,
    .active.carousel-item-right + .carousel-item + .carousel-item,
    .active.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
      opacity: 0;
    }
  }

  @include carousel-indicators;

  .carousel-indicators {
    flex-wrap: wrap;

    li {
      &:nth-child(n + 9) {
      }
      @include media-breakpoint-up(sm) {
        &:nth-child(2n) {
          display: none;
        }
      }
      @include media-breakpoint-up(lg) {
        &:nth-child(4n+3) {
          display: none;
        }
      }
    }
  }

  .carousel-prev, .carousel-next {
    display: none;

    @include media-breakpoint-up(lg) {
      display: inline-flex;
      color: $black;
      width: 2.3125rem;
      height: 2.3125rem;
      border-radius: 50%;
      margin: 0 1rem;
      align-items: center;
      justify-content: center;

      @include transition(color .3s ease-in-out, background-color .3s ease-in-out);

      &:hover {
        color: $white;
        background-color: #555;
      }

      .icon {
        font-size: 1.375rem;
        position: relative;
        right: -.125rem;
        top: .0625rem;
      }
      .icon-chevron-left {
        right: auto;
        left: -.125rem;
      }
    }
  }

  &__bottom {
    @include media-breakpoint-up(lg) {
      padding-top: 1.875rem;
    }
  }
}*/

/*
.review-carousel__item {
  padding: {
    left: $review-carousel--gutter-width/4;
    right: $review-carousel--gutter-width/4;
  }
}
*/

.review-main {
  position: relative;
  z-index: 1;

  .container-fluid {
    max-width: 1110px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      padding-right: 25px;
      padding-left: 25px;
    }
  }

  &__item:not(.slick-slide) {
    display: none;
  }

  &__img {
    @include responsive-image;

    border-radius: .9375rem;
  }
}

.review-main__carousel {
  padding-top: 20px;
  margin: -20px -20px 0;
  overflow: hidden;

  .slick-list {
    padding: 0 10px;
    overflow: visible;

    @include media-breakpoint-up(md) {
      padding: 0 15px 10px;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 20px;
    }
  }

  .slick-slide {
    padding: 0 10px;

    @include media-breakpoint-up(md) {
      padding: 0 15px;
    }
  }

  .slick-active .review-main__img {
    box-shadow: .125rem .25rem 1.25rem rgba(50, 69, 90, .2);

    @include media-breakpoint-up(lg) {
      box-shadow: .25rem .375rem 1.5625rem rgba(50, 69, 90, .5);
    }
  }
}
