.calc {
  @include property-by-media(padding-top, (default: 2rem, xl: 2.5rem));

  &:not(:last-of-type) {
    @include property-by-media(margin-bottom, (default: 3.125rem, sm: 3.75rem, md: 4.375rem, lg: 5rem, xl: 7.5rem));
  }

  &__section {
    &:not(:last-of-type) {
      @include property-by-media(margin-bottom, (default: 3.125rem, sm: 3.75rem, md: 4.375rem, lg: 5rem, xl: 7.5rem));
    }
  }

  &-full-block {
    @include media-breakpoint-down(xs) {
      margin: {
        left: -$grid-gutter-width/2;
        right: -$grid-gutter-width/2;
      }
    }
  }

  .custom-control-label {
    @include property-by-media(font-size, (default: .875rem, sm: 1rem, md: 1.125rem, lg: 1rem, xl: 1.125rem));
    @include property-by-media(line-height, (default: 1.8, sm: 1.6, md: 1.5, lg: 1.6, xl: 1.5));

    small {
      display: block;
      margin-top: -.25em;
      font-style: italic;
      color: #7b7b7b;

      @include property-by-media(font-size, (default: .8125rem, sm: .875rem, md: .9375rem, lg: .875rem, xl: .9375rem));
    }
  }

  &-auction {
    &__group {
      @include media-breakpoint-down(xs) {
        > .form-control {
          margin-bottom: .5rem;
        }
      }
      @include media-breakpoint-up(sm) {
        position: relative;
        display: flex;
        align-items: stretch;
        width: 100%;

        > .form-control {
          position: relative;
          flex: 1 1 auto;
          width: 1%;
          min-width: 0;
          margin-bottom: 0;
        }

        > .form-control:focus {
          z-index: 3;
        }

        > .form-control {
          &:not(:last-child) { @include border-right-radius(0); }
          &:not(:first-child) { @include border-left-radius(0); }
        }
      }

      &-append {
        @include media-breakpoint-down(xs) {
          .btn {
            display: block;
            width: 100%;
          }
        }
        @include media-breakpoint-up(sm) {
          display: flex;
          margin-left: -$input-border-width;

          .btn {
            position: relative;
            z-index: 2;

            @include border-left-radius(0);

            &:focus {
              z-index: 3;
            }
          }
        }
      }
    }
  }

  &-holder {
    @include media-breakpoint-up(sm) {
      margin-bottom: 1.875rem;
    }

    &--shadow {
      box-shadow: 0 0 1.25rem rgba(0, 0, 0, .15);
    }

    &__title {
      color: $white;
      background-color: $gray-800;
      text-align: center;
      line-height: 1.35;

      margin: 0;

      @include property-by-media(padding, (default: .5rem 0, sm: .6875rem 0, md: .46875rem 0, lg: .6875rem 0, xl: .46875rem 0));
    }

    &__container {
      background-color: $gray-100;
      padding: 2rem 1.25rem .5rem;
    }

    &__section {
      padding-bottom: 1.25rem;
    }

    &__subtitle {
      display: inline-block;
    }

    .form-control {
      border-radius: .1875rem;
      border: 1px solid #c4c4c4;
      box-shadow: none;
      color: $body-color;

      @include property-by-media(font-size, (default: 1rem, sm: 1.125rem, md: 1.3125rem, lg: 1.125rem, xl: 1.3125rem));
      @include property-by-media(height, (default: 2.5rem, sm: 2.8125rem));

      &:focus {
        box-shadow: $input-btn-focus-box-shadow;
      }

      &.custom-select {
        padding: {
          top: 0;
          bottom: 0;
        };
      }
    }

    &__bet-description {
      color: $gray-500;

      @include property-by-media(font-size, (default: .875rem, sm: 1rem, md: 1.125rem, lg: 1rem, xl: .875rem));
    }
  }

  &-form-element {
    margin-bottom: .5rem;
  }

  %tooltip-box {
    @include property-by-media(font-size, (default: .875rem, sm: 1rem, md: 1.125rem, lg: 1rem));
    @include property-by-media(padding, (default: .375rem, sm: .625rem));
  }

  .question-btn {
    margin-left: .5rem;

    &__tooltip {
      color: $text-muted;
      font-family: $font-family-roboto;
      text-align: left;
      position: absolute;
      background-color: $white;
      border-radius: $border-radius;
      margin-top: -1.8em;
      pointer-events: none;

      left: 1.25rem;
      right: 1.25rem;

      filter: drop-shadow(0 0 2px $submenu-link-hover-color);

      transform: translateY(-130%);
      opacity: 0;

      @include transition(opacity .3s ease-in-out, transform .3s ease-in-out);

      @include media-breakpoint-up(sm) {
        left: calc(#{$grid-gutter-width/2} + 1.25rem);
        right: auto;
      }

      @include media-breakpoint-up(lg) {
        right: calc(#{$grid-gutter-width/2} + 1.25rem);
      }

      &-inner {
        display: block;

        @extend %tooltip-box;
      }

      &::after {
        content: '';
        position: absolute;
        width: .875rem;
        height: .875rem;
        background-color: $white;
        top: 100%;
        left: 11.25rem;
        margin-left: -.4375rem;
        margin-top: -.5rem;
        border-radius: .125rem;
        transform: perspective(10px) rotateX(10deg) rotateZ(45deg);

        @include property-by-media(left, (default: 11.25rem, sm: 12.4375rem, md: 14.25rem, lg: 12.5rem, xl: 16.125rem));
      }
    }

    &:hover .question-btn__tooltip,
    &:focus .question-btn__tooltip {
      opacity: 1;
      transform: translateY(-100%);
    }
  }

  &__service-price-description {
    @extend %tooltip-box;

    color: #858585;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: 0 0 .625rem rgba(0, 0, 0, .05);
  }

  &__service-price-input {
    .custom-control-input:checked ~ .custom-control-label > span:first-child {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: .875rem;
        height: .875rem;
        margin-left: -.4375rem;
        margin-top: .375rem;
        background-color: $white;
        z-index: 1;
        transform: perspective(10px) rotateX(-10deg) rotateZ(45deg);
      }
    }
  }

  &-result {
    background-color: $white;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, .1);

    padding: 1.875rem 1.25rem;

    @include media-breakpoint-up(sm) {
      padding: {
        left: 1.875rem;
        right: 1.875rem;
      }
    }

    &__row {
      padding: .4375rem 0;
      @include property-by-media(font-size, (default: 1rem, sm: 1.125rem, md: 1.3125rem, lg: 1.125rem, xl: 1.3125rem));

      &--total {
        border-top: .125rem solid $submenu-link-hover-color;
        @include property-by-media(font-size, (default: 1.125rem, sm: 1.3125rem, md: 1.5rem, lg: 1.3125rem, xl: 1.5rem));
      }
    }

    &__info {
      color: #a9a9a9;
      margin: 1.25rem 0 0;
      @include property-by-media(font-size, (default: .875rem, sm: 1.125rem, xl: 1rem));
    }

    &__logo .logo {
      @include property-by-media(width, (default: 4.375rem, sm: 6.25rem));
      @include property-by-media(height, (default: 1.3125rem, sm: 1.875rem));
    }

    &__preview {
      &-lot {
        font-family: $font-family-montserrat;
        font-weight: 500;

        @include property-by-media(font-size, (default: .75rem, sm: 1rem, md: 1.125rem, lg: 1rem, xl: 1.125rem));

        strong {
          font-weight: 600;
        }
      }
      &-image {
        max-width: 8.125rem;

        @include media-breakpoint-up(sm) {
          max-width: 11.25rem;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  [type="submit"] {
    @include media-breakpoint-up(sm) {
      line-height: 1.5;
      font-size: .9375rem;
    }
  }
}

.car-type-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    justify-content: start;
  }

  &__item {
    position: relative;
    display: inline-block;

    & + & {
      @include property-by-media(margin-left, (sm: 1.875rem, md: 2.5rem, lg: 1.875rem));
    }
  }

  &__input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.0625rem;
    height: 1.0625rem;
    opacity: 0;
  }

  &__label {
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    @include property-by-media(font-size, (default: .8125rem, sm: .9375rem));
  }

  &__icon {
    color: $gray-500;
    background-color: $white;
    border-radius: .1875rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include property-by-media(font-size, (default: 2.75rem, sm: 3.125rem));
    @include property-by-media(width, (default: 3.75rem, sm: 4.375rem));
    @include property-by-media(height, (default: 3.75rem, sm: 4.375rem));

    @include transition(color .3s ease-in-out, background-color .3s ease-in-out);
  }

  &__input:checked ~ &__label &__icon {
    background-color: $submenu-link-hover-color;
    color: $white;
  }
}
