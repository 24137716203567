.modal {
  &-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 400px;
    }

    @include media-breakpoint-up(md) {
      max-width: 580px;
    }
  }
  &__close {
    @include clear-button;
    padding: .625rem;
    font-size: .9375rem;
    line-height: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      color: $gray-500;

      @include transition(color .15s ease-in-out);

      &:hover {
        color: $body-color;
      }
    }

    @include media-breakpoint-up(md) {
      color: $white;
      background-color: $gray-500;

      @include transition(background-color .15s ease-in-out);

      &:hover {
        background-color: #ff6565;
      }
    }
  }

  &__body {
    @include property-by-media(padding, (default: 2.25rem $grid-gutter-width/2, sm: 3.125rem 1.25rem, md: 2.875rem 3.75rem, lg: 3.75rem));
  }

  &__title {
    line-height: 1.4;
    @include property-by-media(font-size, (default: 1.125rem, md: 2rem, xl: 2.25rem));
  }
}

.callback-success {
  &__icon {
    color: #abe576;
    margin-bottom: 1rem;
    @include property-by-media(font-size, (default: 3.75rem, md: 6.25rem));
  }
  &__message {
    color: $gray-500;
    @include property-by-media(font-size, (default: 1.125rem, sm: 1rem, md: 1.125rem, xl: 1.3125rem));
  }
}

.selection-success {
  padding-bottom: 0;
  @include property-by-media(padding-left, (default: $grid-gutter-width/2, md: $grid-gutter-width));
  @include property-by-media(padding-right, (default: $grid-gutter-width/2, md: $grid-gutter-width));

  &__title {
    @include property-by-media(font-size, (default: 1.125rem, md: 1.5rem));
  }

  &__message {
    color: $gray-500;
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
  }

  &__image {
    @include property-by-media(margin, (default: 0 (-$grid-gutter-width/2), md: 0 (-$grid-gutter-width/3)));

    img {
      @include responsive-image;
    }
  }
}
