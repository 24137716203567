.form-control:focus::placeholder {
  color: transparent;
}
.form-control-gray {
  background-color: $gray-100;
}
.custom-control-label {
  cursor: pointer;

  &:hover::before {
    border-color: $link-hover-color;
  }
}
.custom-checkbox {
  .custom-control-label {
    &::before, &::after {
      width: 1.25rem;
      height: 1.25rem;
      top: 0;
    }
    &::after {
      background-size: 50%;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $submenu-link-hover-color;
  }
}

.custom-radio {
  .custom-control-label {
    @include media-breakpoint-down(xs) {
      &::before, &::after {
        width: 1.25rem;
        height: 1.25rem;
        top: .109375rem;
      }
      &::after {
        background-size: 1rem;
      }
    }
  }
}

textarea {
  &.form-control {
    line-height: 1.2;
  }
}
