@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hguecg');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hguecg#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?hguecg') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?hguecg') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?hguecg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-boat {
  &:before {
    content: $icon-boat;
  }
}
.icon-bus {
  &:before {
    content: $icon-bus;
  }
}
.icon-car {
  &:before {
    content: $icon-car;
  }
}
.icon-caravan {
  &:before {
    content: $icon-caravan;
  }
}
.icon-electric-car {
  &:before {
    content: $icon-electric-car;
  }
}
.icon-lorry {
  &:before {
    content: $icon-lorry;
  }
}
.icon-motorcycle {
  &:before {
    content: $icon-motorcycle;
  }
}
.icon-trailer {
  &:before {
    content: $icon-trailer;
  }
}
.icon-odometer {
  &:before {
    content: $icon-odometer;
  }
}
.icon-odometer-bold {
  &:before {
    content: $icon-odometer-bold;
  }
}
.icon-engine {
  &:before {
    content: $icon-engine;
  }
}
.icon-sensors {
  &:before {
    content: $icon-sensors;
  }
}
.icon-gas {
  &:before {
    content: $icon-gas;
  }
}
.icon-arrow-long-right {
  &:before {
    content: $icon-arrow-long-right;
  }
}
.icon-arrow-long-left {
  &:before {
    content: $icon-arrow-long-left;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-angle-left {
  &:before {
    content: $icon-angle-left;
  }
}
.icon-angle-right {
  &:before {
    content: $icon-angle-right;
  }
}
.icon-angle-up {
  &:before {
    content: $icon-angle-up;
  }
}
.icon-angle-down {
  &:before {
    content: $icon-angle-down;
  }
}
.icon-close-circle {
  &:before {
    content: $icon-close-circle;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-telegram {
  &:before {
    content: $icon-telegram;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-track-car {
  &:before {
    content: $icon-track-car;
  }
}
.icon-track-container {
  &:before {
    content: $icon-track-container;
  }
}
.icon-calc-shipping {
  &:before {
    content: $icon-calc-shipping;
  }
}
.icon-calc-custom {
  &:before {
    content: $icon-calc-custom;
  }
}
.icon-support {
  &:before {
    content: $icon-support;
  }
}
.icon-rocket {
  &:before {
    content: $icon-rocket;
  }
}
.icon-quality {
  &:before {
    content: $icon-quality;
  }
}
.icon-insurance {
  &:before {
    content: $icon-insurance;
  }
}
.icon-contract {
  &:before {
    content: $icon-contract;
  }
}
.icon-no-worries {
  &:before {
    content: $icon-no-worries;
  }
}
.icon-dealer {
  &:before {
    content: $icon-dealer;
  }
}
.icon-no-fines {
  &:before {
    content: $icon-no-fines;
  }
}
.icon-shipping {
  &:before {
    content: $icon-shipping;
  }
}
.icon-report {
  &:before {
    content: $icon-report;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-question-circle {
  &:before {
    content: $icon-question-circle;
  }
}
.icon-check-round {
  &:before {
    content: $icon-check-round;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
