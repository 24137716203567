@mixin carousel-indicators {
  .carousel-indicators {
    position: static;
    margin: 0;
    padding: .5rem 20px;

    @include media-breakpoint-up(sm) {
      padding: 1rem 0 .75rem;
    }

    li {
      width: 1.25rem;
      margin: 0 7.5px;
      background-color: #c4c4c4;
      opacity: 1;
      transition: background-color .3s ease-in-out;

      @include media-breakpoint-up(sm) {
        width: 1.875rem;
        margin: 0 5px;
      }

      @include media-breakpoint-up(sm) {
        margin: 0 10px;
      }

      @include media-breakpoint-up(lg) {
        margin: 0 .5rem;
      }

      &.active {
        background-color: #000;
      }
    }
  }
}
