.search-toggle {
  @include clear-button;
  @include gutter-padding;

  color: $white;

  margin-right: -$grid-gutter-width / 3;

  @include media-breakpoint-up(xl) {
    display: none;
  }

  &__icon {
    @include menu-toggle-icon;
  }

  &__bar {
    width: 75%;
    height: 75%;
    border: 2px solid $white;
    border-radius: 50%;
    display: block;
    transition: all .3s ease-in-out, border .3s ease-in-out 0s;

    &::before,
    &::after {
      @include menu-toggle-bar;
      left: auto;
    }

    &::before {
      width: 0;
      right: .1875rem;
      top: .1875rem;
      transform: rotate(-45deg);
    }

    &::after {
      right: -.0625rem;
      bottom: .25rem;
      width: .8125rem;
      transform: rotate(45deg);
    }
  }

  &.opened &__bar {
    width: 0;
    height: 0;
    border-color: transparent;
    transition: all .3s ease-in-out, border .1s ease-in-out .2s;

    &::before {
      width: 1.75rem;
      right: -.0625rem;
      top: .75rem;
    }

    &::after {
      width: 1.75rem;
      right: -.0625rem;
      bottom: .75rem;
    }
  }
}
.top-search {

  @include media-breakpoint-up(xl) {
    background-color: $gray-100;
    height: $header-search-height;
  }

  &__container {
    @include media-breakpoint-down(lg) {
      background-color: $gray-100 !important;
      max-width: none !important;;
    }
    @include media-breakpoint-up(xl) {
      padding: {
        top: .125rem;
        bottom: .125rem;
      }
    }
  }

  &__radio {
    flex-wrap: wrap;

    &-label {
      white-space: nowrap;

      font-weight: $font-weight-bold;
      font-size: 1.125rem;

      @include media-breakpoint-up(xl) {
        font-size: .875rem;
        font-weight: 500;

        &:after, &:before {
          top: .0625rem;
        }
      }
    }
  }

  &__radio-tooltip {
    line-height: 1.3;
    color: $gray-500;
    width: 100%;
    margin-left: -1.5625rem;
    padding-top: .25rem;

    @include property-by-media(font-size, (default: .875rem, sm: 1rem, xl: .875rem));

    @include media-breakpoint-up(xl) {
      position: absolute;
      background-color: $white;
      color: $gray-500 !important;
      padding: .75rem 1rem;
      margin-left: 0;
      border-radius: $border-radius;
      box-shadow: $box-shadow-default;
      width: min-content;
      min-width: 300px;
      max-width: 400px;
      top: 100%;
      left: 0;
      margin-top: 50px;
      pointer-events: none;
      opacity: 0;
      transition: opacity .3s ease-in-out, margin-top .3s ease-in-out;

      &::after {
        content: '';
        position: absolute;
        top: -9px;
        left: 50px;
        width: 18px;
        height: 18px;
        background-color: $white;
        transform: perspective(10px) rotateX(-10deg) rotateZ(45deg);
      }
    }
  }

  &__radio-label:hover ~ &__radio-tooltip,
  &__radio input:focus ~ &__radio-tooltip {
    @include media-breakpoint-up(xl) {
      opacity: 1;
      margin-top: 20px;
    }
  }

  &__radio-label:hover ~ &__radio-tooltip {
    @include media-breakpoint-up(xl) {
      z-index: 1;
    }
  }

  &__radio input:checked ~ &__radio-tooltip {
    color: $body-color;
  }
}
