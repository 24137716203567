$client-cars-left-side-width: 350px;
$client-cars-slide-width: (
        md: 43.125rem,
        lg: 48.125rem,
        xl: 45.625rem,
);

@mixin client-cars-left {
  @include media-breakpoint-up(md) {
    float: left;
    clear: left;
    width: $client-cars-left-side-width;
  }
}

@mixin client-cars-right {
  @include media-breakpoint-up(md) {
    overflow: hidden;
  }
}

.client-cars {
  &__title {
    padding: 0 20px;
    @include property-by-media(margin, (default: 0 0 1.25rem, sm: .75rem 0 1.875rem, md: 1.375rem 0 2.875rem, lg: 0 0 1.875rem));
  }
  &__item {
    padding: 0 20px 1.625rem;

    @include media-breakpoint-up(sm) {
      padding-bottom: .75rem;
    }

    &-inner {
      background-color: $white;
      box-shadow: 0 -.0625rem .9375rem rgba(0, 0, 0, .1);
      position: relative;

      @each $breakpoint, $value in $client-cars-slide-width {
        @include media-breakpoint-up($breakpoint) {
          width: $value;
        }
      }
    }
  }

  &__main-image {
    @include media-breakpoint-up(md) {
      @include client-cars-left;
    }

    img {
      @include responsive-image;
    }
  }

  &__model {
    text-align: center;
    font-family: $font-family-montserrat;

    @include property-by-media(font-size, (default: .825rem, sm: 1.3125rem, md: 1.125rem));
    @include property-by-media(padding, (default: .625rem 0 .3125rem, sm: 1.25rem 0 .625rem, md: .6875rem 0));
    @include property-by-media(line-height, (md: 1.6));

    @include media-breakpoint-up(md) {
      @include client-cars-left;
      background-color: #dfdfdf;
    }
  }

  &__secondary_images {
    display: none;

    @include media-breakpoint-up(md) {
      @include client-cars-left;

      display: flex;
      flex-wrap: wrap;

      img {
        display: block;
        width: 50%;
        height: auto;
      }
    }
  }

  &__short-info {
    @include client-cars-right;

    text-align: center;
    color: $black;

    @include property-by-media(font-size, (default: .8125rem, sm: 1rem, md: .8125rem));
    @include property-by-media(line-height, (default: 1.6, sm: 1.5));
    @include property-by-media(padding, (default: .375rem 0 .25rem, sm: .375rem 0 .25rem, md: 1rem 0 .875rem));

    @include media-breakpoint-down(sm) {
      background-color: #e8e8e8;
    }

    .icon {
      display: block;
      color: #474747;

      @include property-by-media(font-size, (default: 1.25rem, sm: 1.625rem, md: 1.375rem));
    }
  }
  &__short-info &__col-inner {
    justify-content: space-evenly!important;
  }

  &__info {
    @include client-cars-right;

    &-inner {
      @include property-by-media(padding, (default: 0 .9375rem, sm: 0 1.875rem, md: 0 1.25rem, lg: 0 2.5rem, xl: 0 2.125rem));
    }
  }

  &__prices {
    @include property-by-media(font-size, (default: .8125rem, sm: 1.125rem, md: .875rem));

    > div {
      padding: .0625rem 0;

      @include media-breakpoint-only(sm) {
        padding: 0;
      }
    }
  }

  &__buttons {
    @include media-breakpoint-up(md) {
      margin: 0 (-$grid-gutter-width/3);
    }
    > div {
      @include property-by-media(margin, (default: 1.125rem 0 -1.5625rem, sm: .9375rem 0 1.875rem, md: 1.125rem 0 1rem));

      @include media-breakpoint-up(md) {
        padding: 0 $grid-gutter-width/3;
      }
    }

    .btn {
      @include media-breakpoint-up(md) {
        font-size: .75rem;
        font-weight: 600;

        &.btn-orange {
          line-height: 1.7;
          padding: .5rem;
        }
      }
    }

    .btn-youtube {
      font-size: .75rem;
      width: 180px;

      &[href=""] {
        display: none !important;
      }

      br {
        display: inline-block;
      }

      @include media-breakpoint-up(md) {
        width: 140px;
        padding: .25rem;

        .icon {
          font-size: 1.5rem;
        }
      }

      @include media-breakpoint-up(lg) {
        width: 150px;
      }
    }
  }

  $slideSpeed: 300;

  &__carousel {
    --speed: #{$slideSpeed};

    &.slick-slider {
      @include media-breakpoint-up(xl) {
        user-select: auto;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .slick-slide {
      transform: scale(.8);

      @include transition(transform #{$slideSpeed}ms ease);

      .client-cars__item-inner {
        @each $breakpoint, $value in $client-cars-slide-width {
          @include media-breakpoint-up($breakpoint) {
            margin: 0 (-$value * .05);
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: -2px;
          background-color: rgba(249, 249, 249, 0.6);
          z-index: 1;
          pointer-events: none;

          @include transition(background-color #{$slideSpeed}ms ease);
        }
      }

      &.slick-center {
        transform: scale(1);
        .client-cars__item-inner::before {
          background-color: transparent;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      .slick-prev, .slick-next {
        position: absolute;
        z-index: 2;
        top: 39%;

        &::before {
          font-size: 3.125rem;
        }

      }
      .slick-prev {
        left: 50%;
        margin-left: calc(-55.625rem/2);
        transform: translateX(-50%);
      }
      .slick-next {
        right: 50%;
        margin-right: calc(-55.625rem/2);
        transform: translateX(50%);
      }
    }
  }
}

.client-cars__button {
  @include property-by-media(width, (default: 200px, sm: 180px, md: 140px, lg: 150px));
}
