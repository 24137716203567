.privilege {
  .icon {
    font-size: 2.5rem;
    margin-right: 1rem;
    color: $menu-link-hover-color;

    @include media-breakpoint-up(sm) {
      font-size: 3.75rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 3.125rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 4.0625rem;
      margin-right: 0;
      margin-bottom: .5rem;
    }
  }

  &__item {
    font-size: .875rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.3125rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: .9375rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1rem;
    }
  }

  &__title {
    font-family: $font-family-montserrat;
    font-size: 1.125rem;
    line-height: 1.3;
    display: block;

    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
      width: 50%;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.3125rem;
      width: 68%;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
      width: 100%;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.3125rem;
    }
  }
}