.nearest-lots {
  &__item {
    line-height: 1.6;

    @include media-breakpoint-down(xs) {
      padding-top: .75rem;
      padding-bottom: .75rem;

      &:nth-child(odd) {
        background-color: $gray-100;
      }
    }

    @include media-breakpoint-up(sm) {
      padding-top: $grid-gutter-width/2;
      padding-bottom: $grid-gutter-width/2;

      display: flex;
    }

    @include media-breakpoint-down(md) {
      &:not(:nth-child(-n + 6)) {
        display: none;
      }
    }
  }

  &__link {
    color: $body-color !important;
    text-decoration: none !important;

    font-size: .875rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.125rem;

      box-shadow: $box-shadow-default;
      flex-grow: 1;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: .9375rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.0625rem;
    }
  }


  &__image {
    @include media-breakpoint-down(xs) {
      width: 50%;
      padding-right: 10px;
    }
    @include media-breakpoint-up(sm) {
      transition: opacity .3s ease-in-out;
    }
  }

  &__link:hover &__image {
    @include media-breakpoint-up(sm) {
      opacity: .5;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(xs) {
      width: 50%;
      padding-left: 10px;
    }

    @include media-breakpoint-up(sm) {
      position: relative;
      padding: .75rem 1rem;
      flex-grow: 1;
    }
  }

  &__title {
    margin-bottom: auto;
    font-family: $font-family-montserrat;
    font-weight: 600;
    font-size: .8125rem;
    text-transform: uppercase;
    transition: color .3s ease-in-out;

    @include media-breakpoint-up(sm) {
      display: block;
      font-weight: 700;
      font-size: inherit;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: inherit;
    }
  }

  &__link:hover &__title {
    @include media-breakpoint-up(sm) {
      color: $menu-link-hover-color;
    }
  }

  &__distance {
    font-size: .8125rem;
    color: $gray-700;
    display: inline-block;
    margin-bottom: .25rem;

    @include media-breakpoint-up(sm) {
      display: block;
      font-size: inherit;
      margin-top: 1rem;
      margin-bottom: .5rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.1875rem;
      margin-top: .5rem;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      font-size: inherit;
    }

    .icon {
      margin-right: .25rem;
      font-size: .625rem;
      vertical-align: baseline;

      &::before {
        content: $icon-odometer-bold;
      }

      @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
        vertical-align: text-top;

        &::before {
          content: $icon-odometer;
        }
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.0625rem;
      }
    }
  }

  &__price {
    font-family: $font-family-montserrat;
    font-weight: 600;

    @include media-breakpoint-down(xs) {
      display: block;
    }

    @include media-breakpoint-up(sm) {
      font-family: $font-family-roboto;
      font-size: 1.3125rem;
      margin-left: 1rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.3125rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.3125rem;
    }
  }

  &__view {
    display: none;

    @include media-breakpoint-up(sm) {
      border: 2px solid currentColor;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;

      @include transition(all .3s ease-in-out);

      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 1.25rem;
      right: 1rem;

      font-size: 1rem;

      color: $menu-link-hover-color;
      border-color: $menu-link-hover-color !important;
    }

    @include media-breakpoint-up(lg) {
      width: 1.875rem;
      height: 1.875rem;
      font-size: .875rem;
    }

    @include media-breakpoint-up(xl) {
      width: 2.1875rem;
      height: 2.1875rem;
    }
  }

  &__link:hover &__view {
    color: $white;
    background-color: $menu-link-hover-color;
  }
}

.nearest-lots__text {
  @include media-breakpoint-up(lg) {
    width: 770px;
  }

  @include media-breakpoint-up(xl) {
    width: 1070px;
  }
}
