.icon {
  vertical-align: middle;

  &-search {
    font-size: 1.25rem;
  }

  &-user {
    font-size: .8125rem;
  }
}