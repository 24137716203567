@import "delivery-price";
@import "profit";

.wholesale-header {
  &__description {
    @include property-by-media(font-size, (default: 1rem, sm: 18px, md: 21px, xl: 1.5rem));
  }
  &__image {
    img {
      @include responsive-image;

      @include media-breakpoint-up(xl) {
        width: auto;
      }
    }
  }
}

.wholesale-prices {
  .retail-prices__card-title {
    margin: {
      top: 1.25rem;
      bottom: 1.25rem;
    }
    //min-width: 55%;
    font-weight: 700;

    @include media-breakpoint-only(lg) {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
  .retail-prices__price {
    font-size: 24px;

    @include media-breakpoint-up(sm) {
      font-size: 28px;
    }

    @include media-breakpoint-up(md) {
      font-size: 32px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 36px;
    }
  }
  .retail-prices__description {
    margin: {
      top: 1rem;
      bottom: 1.25rem;
    }
    font-size: 16px;
    font-weight: 500;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }

    @include media-breakpoint-up(md) {
      font-size: 17px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 21px;
    }
  }
}

.retail-prices__card-title_broker {
  @include media-breakpoint-only(xs) {
    padding-right: 20px;
  }
}

.about-me {
  &__avatar {
    display: inline-block;
    text-align: center;
    font-family: $font-family-montserrat;
    font-weight: 600;

    @include property-by-media(font-size, (default: .875rem, sm: 1.125rem, lg: 1rem, xl: 1.125rem));

    @include media-breakpoint-up(xl) {
      margin-right: 3.125rem;
    }

    img {
      border-radius: 50%;
      background-image: linear-gradient(61.71deg, #fb7b52 14.44%, #fba352 88.5%);
      padding: 4%;

      @include property-by-media(width, (default: 12.5rem, sm: 15.625rem, md: 16.875rem, lg: 18.125rem, xl: 18.75rem));
      @include property-by-media(height, (default: 12.5rem, sm: 15.625rem, md: 16.875rem, lg: 18.125rem, xl: 18.75rem));
      @include property-by-media(margin-bottom, (default: .625rem, xl: .9375rem));
    }
  }

  &__name {
    color: $submenu-link-hover-color;
  }

  &__description {
    background-color: $white;
    border-radius: .625rem;
    box-shadow: 0 .1875rem .9375rem rgba(0, 0, 0, .05);

    @include property-by-media(padding, (default: $grid-gutter-width/2, sm: 1.25rem, lg: $grid-gutter-width));
    @include property-by-media(font-size, (default: .875rem, sm: 1.125rem, lg: 1rem, xl: 1.125rem));

    @include media-breakpoint-up(md) {
      border-radius: 1.25rem;
      box-shadow: 0 .1875rem 1.3125rem rgba(0, 0, 0, .1);
    }
  }
}
