.top-order {
  @include property-by-media(margin-top, (default: 3.125rem, sm: 3.75rem, md: 4.375rem, lg: 5rem, xl: 7.5rem));

  &__carousel {
    margin: 0 -20px;

    &.slick-slider {
      .slick-list {
        padding-bottom: 1.5rem;
        margin-bottom: -1.5rem;
      }

      .slick-prev, .slick-next {
        color: #c4c4c4;
        @include transition(color .3s ease-in-out);

        &:hover {
          color: #000;
        }

        @include media-breakpoint-up(xl) {
          margin-top: 1px;
          &::before {
            font-size: 1.5625rem;
          }
        }
      }
      .slick-prev {
        @extend .icon-arrow-long-left;
        margin-right: 1rem;
      }
      .slick-next {
        @extend .icon-arrow-long-right;
        margin-left: 1rem;
      }

      .slick-dots {
        @include property-by-media(margin, (default: .125rem 0, sm: .75rem 0, lg: 1rem 0));

        > li {
          @include media-breakpoint-only(xs) {
            margin: {
              left: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
  &__item {
    padding: 0 20px;


    &:not(.slick-slide) {
      display: none;
    }
  }
  &__image {
    margin-bottom: -15%;

    img {
      @include responsive-image;
    }
  }
  &__description {
    border-radius: 0 0 .9375rem .9375rem;
    box-shadow: .25rem .3125rem .9375rem rgba(0, 0, 0, .1);

    @include property-by-media(padding, (default: calc(15% + 1rem) .625rem 1.25rem, sm: calc(15% + 1rem) .9375rem 1.25rem, md: calc(15% + 1rem) 1.875rem 1.25rem, lg: calc(15% + 1rem) .625rem 1.25rem, xl: calc(15% + 1rem) .75rem 1.25rem));

    &-row {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__model {
    font-family: $font-family-montserrat;
    font-weight: 500;
    min-width: 0;
    padding-right: .25rem;

    @include property-by-media(font-size, (default: .8125rem, sm: .875rem, md: 1.125rem, lg: .875rem));

    strong {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include property-by-media(font-size, (default: .8125rem, sm: 1rem, md: 1.5rem, lg: 1rem));
    }
  }
  &__link {
    flex-shrink: 0;
    border-width: .0625rem;
    font-weight: 600;

    @include property-by-media(font-size, (default: .6875rem, sm: .8125rem, md: .875rem, lg: .625rem));
    @include property-by-media(line-height, (default: 1.6, lg: 1.7));

    @include property-by-media(padding, (default: .5rem, sm: .5625rem 1rem, md: .75rem 1.5rem, lg: .5rem 5px));
    @include property-by-media(width, (sm: 200px, md: 240px, lg: 130px, xl: 146px));

    @include media-breakpoint-only(xs) {
      text-transform: none;
    }

    @include media-breakpoint-only(md) {
      border-width: .125rem;
      font-weight: 700;
    }
  }
  &__price {
    font-family: $font-family-montserrat;
    @include property-by-media(font-size, (default: .875rem, sm: 1.3125rem, md: 1.5rem, lg: 1.125rem, xl: 1.25rem));

    &-box {
      font-weight: 500;
      text-align: center;
      padding: 1.25rem .25rem 0;

      @include property-by-media(font-size, (default: .875rem, sm: 1.125rem, lg: .875rem));

      @include media-breakpoint-up(sm) {
        padding: {
          left: .5rem;
          right: .5rem;
        }
      }

      @include media-breakpoint-only(md) {
        width: 240px;
      }

      @include media-breakpoint-up(lg) {
        padding: {
          left: 0;
          right: 0;
        }
      }

      @include media-breakpoint-up(xl) {
        padding: {
          left: 8px;
          right: 8px;
        }
      }
    }
  }
  &__title {
    @include media-breakpoint-down(xs) {
      font-size: 1.5rem;
    }
    @include property-by-media(margin-bottom, (default: 1.25rem, sm: 1.875rem, lg: 2.375rem, xl: 3rem));
  }
  &__annotation {
    @include property-by-media(font-size, (default: .8125rem, sm: 1rem, md: 1.125rem, lg: 1rem));
  }
}
