.main-services {
  @include media-breakpoint-only(sm) {
    .container_p20 & { // additional selectors to overwrite .container_p20 rules
      margin-right: -10px;
      margin-left: -10px;

      .col,
      [class*="col-"] {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
  //@include media-breakpoint-up(xl) {
  //  margin: {
  //    left: -$grid-gutter-width;
  //    right: -$grid-gutter-width;
  //  }
  //
  //  [class*="col"] {
  //    padding: {
  //      left: $grid-gutter-width;
  //      right: $grid-gutter-width;
  //    }
  //  }
  //}
  .icon {
    font-size: 3.4375rem;
    color: $submenu-link-hover-color;
  }

  .btn {
    font-family: $font-family-roboto;
  }

  &__item {
    margin: {
      left: auto;
      right: auto;
    }

    @include media-breakpoint-only(md) {
      //width: 17rem;
      padding: 0 30px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 15px;
    }
  }

  &__title {
    font: {
      family: $font-family-montserrat;
      size: 1.125rem;
    }
    line-height: 1.3;

    display: block;

    @include media-breakpoint-only(md) {
      font-size: 1.3125rem;
    }

    @include media-breakpoint-only(xl) {
      font-size: 1.4375rem;
    }

  }

  &__description {
    padding-right: 10px;
    padding-left: 10px;
    font-size: .875rem;

    /*@include media-breakpoint-down(sm) {
      margin: {
        left: 5%;
        right: 5%;
      }
    }*/

    @include media-breakpoint-up(sm) {
      padding-right: 0;
      padding-left: 0;
    }

    @include media-breakpoint-only(md) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: .9375rem;
    }
  }
}
