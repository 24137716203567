@mixin menu-toggle-icon {
  position: relative;
  width: 1.625rem;
  height: 1.625rem;
  display: block;
}

@mixin menu-toggle-bar {
  background-color: $white;
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  content: '';
  transition: all .3s ease-in-out;
}