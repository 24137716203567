@import "top-order";
@import "nuances";
@import "client-cars";
@import "how-it";
@import "profit";
@import "prices";

.retail-header {
  &__description {
    @include property-by-media(font-size, (default: 1rem, sm: 1.3125rem, xl: 1.5rem));
  }
  &__image {
    img {
      @include responsive-image;

      @include media-breakpoint-up(lg) {
        max-width: 375px;
        margin-left: auto;
      }

      @include media-breakpoint-up(xl) {
        max-width: 445px;
      }
    }
  }
  .btn {
    @include media-breakpoint-only(sm) {
      font-size: .8125rem;
      padding: .8125rem .25rem;
    }
  }
}

.retail-header__title {
  @include media-breakpoint-up(lg) {
    margin-right: -30px;
  }
}

.retail-header__buttons {}
@include media-breakpoint-down(sm) {
  .retail-header .retail-header__buttons,
  .retail-header .row .retail-header__buttons-col { // additional selectors to overwrite .container_p20 rules
    padding-right: 10px;
    padding-left: 10px;
  }
}

.retail-header__button {
  @include media-breakpoint-only(lg) {
    font-size: 13px;
    padding: 13px 10px;
  }
}

.retail-unloading {
  &__title {
    //@include property-by-media(margin, (default: 0 0 1.25rem, sm: 0 0 1.25rem, md: 0 10% 1.875rem, lg: 0 20% 1.875rem, xl: 0 20% 1.875rem));
    @include property-by-media(margin-bottom, (default: 1.25rem, md: 1.875rem));
  }
  &__list {
    > div {
      @include property-by-media(margin-bottom, (default: $grid-gutter-width/2, md: $grid-gutter-width));
    }
  }
}

.retail-faq {
  &__title {
    //@include property-by-media(margin, (default: 0 0 1.25rem, sm: 0 0 1.25rem, md: 0 10% 1.875rem, lg: 0 20% 1.875rem, xl: 0 20% 1.875rem));
    @include property-by-media(margin-bottom, (default: 1.25rem, md: 1.875rem));
  }

  &__more {
    @include property-by-media(padding-top, (default: 1.25rem, sm: 1.875rem));
  }

  .faq__list.expanded {
    @include transition(height .3s ease-in-out);
  }
  .faq__list:not(.expanded) > *:not(:nth-of-type(-n + 5)) {
    display: none;
  }
}
