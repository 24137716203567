.footer {
  background-color: $body-color;
  color: $white;
  line-height: 1.8;

  font-size: .75rem;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: .9375rem;
  }

  a {
    color: $white;
    text-decoration: none !important;

    &:hover {
      color: $menu-link-hover-color;
    }
  }

  &__logo {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(sm) {
      font-size: 1.3125rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.625rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
  }

  .social-menu {
    margin-left: -$grid-gutter-width / 6;
    margin-right: -$grid-gutter-width / 6;

    .list-inline-item:not(:last-child) {
      margin-right: 1.5rem;

      @include media-breakpoint-up(lg) {
        margin-right: 13px;
      }
    }

    a {
      padding: $grid-gutter-width / 6;
    }

    .icon {
      font-size: 1.25rem;
      @include media-breakpoint-between(sm, md) {
        font-size: 1.875rem;
      }
    }
  }

  &__copyright {
    color: $gray-500;
    font-size: .8125rem;

    @include media-breakpoint-between(sm, md) {
      font-size: .875rem;
    }
  }
}

.address-block {
  .icon {
    font-size: 1.125rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
  }

  &__phone {
    font-weight: bold;

    @include media-breakpoint-up(sm) {
      font-size: 1.0625rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
      font-weight: 500;
    }
  }
}
