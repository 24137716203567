$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-boat: "\e91a";
$icon-bus: "\e91b";
$icon-car: "\e91c";
$icon-caravan: "\e91d";
$icon-electric-car: "\e91e";
$icon-lorry: "\e91f";
$icon-motorcycle: "\e920";
$icon-trailer: "\e921";
$icon-odometer: "\e916";
$icon-odometer-bold: "\e917";
$icon-engine: "\e922";
$icon-sensors: "\e923";
$icon-gas: "\e924";
$icon-arrow-long-right: "\e925";
$icon-arrow-long-left: "\e926";
$icon-arrow-left: "\e90e";
$icon-arrow-right: "\e90f";
$icon-chevron-left: "\e910";
$icon-chevron-right: "\e911";
$icon-chevron-up: "\e912";
$icon-chevron-down: "\e913";
$icon-angle-left: "\e927";
$icon-angle-right: "\e928";
$icon-angle-up: "\e929";
$icon-angle-down: "\e92a";
$icon-close-circle: "\e92b";
$icon-close: "\e92c";
$icon-plus: "\e900";
$icon-minus: "\e901";
$icon-facebook: "\e902";
$icon-instagram: "\e903";
$icon-telegram: "\e904";
$icon-youtube: "\e905";
$icon-track-car: "\e906";
$icon-track-container: "\e907";
$icon-calc-shipping: "\e908";
$icon-calc-custom: "\e909";
$icon-support: "\e92d";
$icon-rocket: "\e92e";
$icon-quality: "\e92f";
$icon-insurance: "\e930";
$icon-contract: "\e931";
$icon-no-worries: "\e932";
$icon-dealer: "\e90a";
$icon-no-fines: "\e90b";
$icon-shipping: "\e90c";
$icon-report: "\e90d";
$icon-check: "\e933";
$icon-question-circle: "\e934";
$icon-check-round: "\e935";
$icon-filter: "\e914";
$icon-location: "\e915";
$icon-search: "\e918";
$icon-user: "\e919";
