.header {
  box-shadow: $box-shadow-default;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;

  @include media-breakpoint-up(xl) {
    position: sticky;
    top: -$header-height - $header-search-height;
    z-index: 100;

    @include transition(top .4s ease-in-out);

    &--shown {
      top: 0;
    }
  }

  &__top {
    @include media-breakpoint-up(xl) {
      position: relative;
      z-index: 1;
    }
  }
  &__top-inner {
    @include header-size;

    background-color: $body-color;
    color: $white;
  }

  &__logo {
    font-size: 1.125rem;
    color: $white !important;
    padding: $grid-gutter-width / 3;

    @include media-breakpoint-up(xl) {
      padding: $grid-gutter-width / 2;
    }

    svg {
      width: 80px;
      height: 24px;

      @include media-breakpoint-up(xl) {
        width: 100px;
        height: 30px;
      }
    }
  }
}

@import "slide-panel";
@import "nav-top";
@import "search-top";
