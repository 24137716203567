.ports {}

.ports__content {}

.ports__subtitle {
  width: 245px;

  line-height: 27px;

  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

.ports__map {}
