.profit {
  &__title {
    //@include property-by-media(margin, (default: 0 0 1.25rem, sm: 0 8% 1.25rem, md: 0 20% 1.875rem, lg: 0 20% 1.875rem, xl: 0 20% 1.875rem));
    @include property-by-media(margin-bottom, (default: 1.25rem, md: 1.875rem));
  }

  &__list {
    @include property-by-media(margin-bottom, (default: -$grid-gutter-width/2, md: -$grid-gutter-width));

    &-item {
      @include media-breakpoint-only(md) {
        &:not(:nth-child(-n + 8)) {
          display: none !important;
        }
      }
    }
  }

  &-card {
    background-color: $white;
    margin-bottom: $grid-gutter-width;
    box-shadow: .125rem .125rem 1.25rem rgba(11, 11, 11, .05);

    @include property-by-media(border-radius, (default: .625rem, sm: 1.25rem));
    @include property-by-media(margin-bottom, (default: $grid-gutter-width/2, md: $grid-gutter-width));

    @include transition(box-shadow .15s ease-in-out, transform .15s ease-in-out);

    &__header {
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;
      @include property-by-media(font-size, (default: 1rem, sm: 1.3125rem, lg: 1.125rem));
      @include property-by-media(padding, (default: 1.25rem .625rem, sm: 1.25rem .625rem 1rem, xl: 1.375rem 1.5625rem 1rem));

      .icon {
        flex-shrink: 0;
        color: $submenu-link-hover-color;
        @include property-by-media(font-size, (default: 2.5rem, sm: 3.125rem));
        @include property-by-media(margin-right, (default: .625rem, sm: .9375rem, md: .625rem, lg: .9375rem, xl: 1.25rem));
      }

      span {
        position: relative;
        top: .25rem;
      }
    }
    &__body {
      border-top: .0625rem solid $gray-200;
      @include property-by-media(font-size, (default: .8125rem, sm: 1.125rem, lg: 1rem));
      @include property-by-media(padding, (default: .625rem, sm: .9375rem 1.25rem 1.25rem, md: .9375rem .625rem, lg: .9375rem .625rem 1.25rem, xl: .9375rem .9375rem 1.5625rem));
    }
  }

  @include media-breakpoint-up(xl) {
    &__list-item:hover &-card {
      box-shadow: .125rem .125rem 1.25rem rgba(11, 11, 11, .2);
      transform: translateY(-.625rem);
    }
  }
}
