@mixin property-by-media($prop-name, $breakpoints-value, $dir: 'up') {
  @if $prop-name != null and type-of($breakpoints-value) == 'map' {
    @each $breakpoint, $value in $breakpoints-value {
      @if $value != null {
        @if $breakpoint == default {
          #{$prop-name}: $value;
        } @else if map-has-key($grid-breakpoints, $breakpoint) {
          @if $dir == 'up' {
            @include media-breakpoint-up($breakpoint) {
              #{$prop-name}: $value;
            }
          } @else if $dir == 'down' {
            @include media-breakpoint-down($breakpoint) {
              #{$prop-name}: $value;
            }
          } @else {
            @include media-breakpoint-only($breakpoint) {
              #{$prop-name}: $value;
            }
          }
        }
      }
    }
  }
}

@import "images";
@import "buttons";
@import "menu-toggle";
@import "header";
@import "main-page";
@import "carousel";
