.wholesale-main {
  @include media-breakpoint-down(md) {
    background-color: $gray-100;
  }

  @include media-breakpoint-between(sm,md) {
    margin-bottom: 1.3125rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 5rem;

    $container-width: map-get($container-max-widths, lg);

    position: relative;

    &::after {
      content: '';
      position: absolute;
      background-color: $gray-100;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1;

      @include main-section-half-bg($container-width, .25, left);
    }
  }

  @include media-breakpoint-up(xl) {
    $container-width: map-get($container-max-widths, xl);

    &::after {
      @include main-section-half-bg($container-width, .25, left);
    }
  }

  .btn {
    @include media-breakpoint-down(sm) {
      padding: {
        left: .5rem;
        right: .5rem;
      }
    }

    @include media-breakpoint-only(sm) {
      font-size: .8125rem;
      padding: .8125rem .5rem;
    }
  }
}

.wholesale-platforms {
  max-width: 30rem;

  &__item:not(:last-child) {
    margin-right: 9px;

    @include media-breakpoint-up(sm) {
      margin-right: 30px;
    }
  }
}

$wholesale-images--width-xl: 635px;
$wholesale-images--height-xl: 450px;

.wholesale-images {
  position: relative;
  padding-bottom: 56.25%;
  z-index: 1;

  @include media-breakpoint-up(xl) {
    right: fluid-value(0px, 95px, 1200px, 1920px);
    padding-bottom: percentage($wholesale-images--height-xl / $wholesale-images--width-xl);
  }

  img {
    position: absolute;
    width: 62%;
    height: auto;

    @include media-breakpoint-up(lg) {
      box-shadow: .25rem .25rem 1.5625rem rgba(0,0,0,.2);
    }

    @include media-breakpoint-up(xl) {
      width: percentage($wholesale-images--height-xl / $wholesale-images--width-xl);
    }
  }
  &__1 {
    top: 0;
    right: 0;
  }
  &__2 {
    left: 0;
    top: 17%;
    z-index: -1;

    @include media-breakpoint-up(xl) {
      top: percentage(39px / $wholesale-images--height-xl);
    }
  }
  &__3 {
    bottom: 0;
    left: 19%;

    @include media-breakpoint-up(xl) {
      left: percentage(95px / $wholesale-images--width-xl);
    }
  }
}
