$intro__content--width: 570px;

.intro {
  @include media-breakpoint-only(lg) {
    padding-top: 50px;
  }

  &__image {
    max-width: 130%;
    height: auto;

    @include media-breakpoint-up(md) {
      max-width: none;
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: auto;
      max-width: percentage(500/450);
      margin-left: percentage(90/450);
    }

    @include media-breakpoint-up(xl) {
      max-width: percentage(676px / (map-get($container-max-widths, xl) - $intro__content--width - 30px));
    }
  }

  &__description {
    font-size: .875rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-up(xl) {
      margin-right: -30px;
    }
  }

  &__title {
    max-width: 280px;

    @include media-breakpoint-up(sm) {
      max-width: 400px;
    }

    @include media-breakpoint-up(md) {
      max-width: 550px;
    }

    @include media-breakpoint-up(lg) {
      max-width: none;
      margin-right: -30px;
    }
  }

  .btn {
    @include media-breakpoint-only(sm) {
      font-size: .8125rem;
      padding: .8125rem .25rem;
    }
  }
}

.intro__content {
  @include media-breakpoint-up(xl) {
    flex: 0 0 percentage($intro__content--width / map-get($container-max-widths, xl));
    max-width: percentage($intro__content--width / map-get($container-max-widths, xl));
    padding-top: 40px;
  }
}

.intro__container {
  @include media-breakpoint-up(xl) {
    flex: 0 0 percentage((map-get($container-max-widths, xl) - $intro__content--width)/map-get($container-max-widths, xl));
    max-width: percentage((map-get($container-max-widths, xl) - $intro__content--width)/map-get($container-max-widths, xl));
  }
}

@include media-breakpoint-down(sm) {
  .intro .intro__buttons,
  .intro .row .intro__buttons-col { // additional selectors to overwrite .container_p20 rules
    padding-right: 10px;
    padding-left: 10px;
  }
}

.intro__button {
  @include media-breakpoint-only(lg) {
    font-size: 13px;
    padding: 13px 10px;
  }
}

//@include media-breakpoint-up(md) {
//  .intro .intro__buttons {
//    padding-right: 24px;
//    padding-left: 24px;
//  }
//
//  .intro .row .intro__buttons-col {
//    padding-right: 15px;
//    padding-left: 15px;
//  }
//}
